import { TextField, Typography } from '@mui/material';
import styled from 'styled-components';

export const FeedbackContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 768px) {
    flex-direction: column;
  }
`;

export const FeedbackContent = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 16px;
  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

export const Requirements = styled(TextField)`
  max-height: 250px;
  max-width: 700px;
  overflow-y: auto;

  @media screen and (max-width: 1100px) {
    max-width: 100%;
  }
`;

export const StyledPreview = styled.pre`
  text-align: left;
  white-space: pre-wrap;
`;


export const ModuleScore = styled(Typography)`
  &.MuiTypography-root {
    margin-right: 16px;
    margin-left: 32px;
  }
  @media screen and (min-width: 560px) {
    &.MuiTypography-root {
      margin-left: 0px;
    }
  }
`;
