export const PROVINCES: { [key: string]: string } = {
  DOLNOSLASKIE: 'Dolnośląskie',
  KUJAWSKO_POMORSKIE: 'Kujawsko-Pomorskie',
  LUBELSKIE: 'Lubelskie',
  LUBUSKIE: 'Lubuskie',
  LODZKIE: 'Łódzkie',
  MALOPOLSKIE: 'Małopolskie',
  MAZOWIECKIE: 'Mazowieckie',
  OPOLSKIE: 'Opolskie',
  PODKARPACKIE: 'Podkarpackie',
  PODLASKIE: 'Podlaskie',
  POMORSKIE: 'Pomorskie',
  SLASKIE: 'Śląskie',
  SWIETOKRZYSKIE: 'Świętokrzyskie',
  WARMINSKO_MAZURSKIE: 'Warmińsko-Mazurskie',
  WIELKOPOLSKIE: 'Wielkopolskie',
  ZACHODNIO_POMORSKIE: 'Zachodniopomorskie',
};

export const mapProvinceToFullName = (province: string | null): string | null => {
  if (!province) return null;
  return PROVINCES[province];
};

export const mapFullNameToProvince = (fullName: string | null): string | null => {
  if (!fullName) return null;
  const provinceEntry = Object.entries(PROVINCES).find(([key, value]) => value === fullName);
  
  return provinceEntry ? provinceEntry[0] : null;
};
