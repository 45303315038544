import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Box, Typography } from '@mui/material';
import { useUserContext } from 'context';
import { Button } from 'styles.global';
import { StyledHeader } from 'components/StyledHeader/StyledHeader.css';
import { TemplateModuleProps } from './TemplateModule.type';
import * as A from '../../../../../../components/Accordion/Accordion.css';
import * as S from './TemplateModule.css';
import { groupModuleRequirementsBySeniorityLevel } from '../../utils/utils';

export const TemplateModule = ({
  module,
  moduleReadOnly,
  onModuleEdit,
  onModuleDelete,
}: TemplateModuleProps) => {
  const { user } = useUserContext();

  const handleDelete = () => {
    if (onModuleDelete) {
      if (module.id) {
        onModuleDelete(module.id);
      }
      if (module.newId) {
        onModuleDelete(module.newId);
      }
    }
  };

  const groupedRequirements = groupModuleRequirementsBySeniorityLevel(
    module.requirements,
  );

  return (
    <A.Accordion defaultExpanded>
      <A.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <A.AccordionHeader>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: 'large' }}>
            {module.name}
          </Typography>
        </A.AccordionHeader>

        <Typography variant="body1">
          Module weight: <span style={{ fontWeight: 'bold' }}>{module.weight}</span>
        </Typography>
      </A.AccordionSummary>
      <A.AccordionBodyContent>
        <Box display="flex" flexDirection="column" flex={1}>
          <StyledHeader
            marginLeft="0"
            fontSize="20px"
            marginTop="32px"
            marginBottom="16px"
            variant="body1"
          >
            Requirements
          </StyledHeader>
          {groupedRequirements.map((req, index) => (
            <Box key={`mod-level-${index}`}>
              {req.level}
              <ul>
                {req.requirements.map((reqs, reqIndex) => (
                  <li key={`mod-req-${reqIndex}`}>
                    <S.StyledPreview>{reqs}</S.StyledPreview>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Box>
      </A.AccordionBodyContent>
      <AccordionDetails
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          paddingRight: '32px',
          gap: '16px',
        }}
      >
        <Button onClick={() => onModuleEdit(module)}>
          {user?.hasFullAccess ? 'EDIT' : 'VIEW'}
        </Button>
        {!moduleReadOnly && user?.hasFullAccess && (
          <S.DeleteButton onClick={handleDelete}>DELETE</S.DeleteButton>
        )}
      </AccordionDetails>
    </A.Accordion>
  );
};
