import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Box } from '@mui/material';
import { useGetLoggedUser } from 'api/users';
import Exit from 'assets/exit-icon.svg';
import Logo from 'assets/jit-logo.svg';
import { useUserContext } from 'context';
import * as URL from 'router/url';
import * as S from './Navbar.css';

const Navbar = () => {
  const { pathname } = useLocation();
  const { setUser } = useUserContext();
  const { loggedUser } = useGetLoggedUser();
  const { user } = useUserContext();

  useEffect(() => {
    if (loggedUser && setUser) {
      setUser(loggedUser);
    }
  }, [loggedUser, setUser]);

  const { instance, accounts } = useMsal();
  const isActive = (path: string) => pathname === path;

  const handleLogout = () => {
    const logoutRequest = {
      account: accounts[0],
    };
    instance.logoutRedirect(logoutRequest).catch((e) => {
      console.error(e);
    });
    localStorage.removeItem('token');
  };

  if (pathname.match(/^\/unauthorized*/)) {
    return (
      <S.NavbarContainer>
        <Box display="flex" alignItems="center">
          <S.Logo src={Logo} alt="logo" />
          <S.NavbarTitle>Sphinx - Recruitment Application</S.NavbarTitle>
        </Box>
        <S.LogoutButton onClick={handleLogout}>
          <Box display="flex" alignItems="center">
            <p> Logout </p>
            <S.ExitIcon src={Exit} alt="exit" />
          </Box>
        </S.LogoutButton>
      </S.NavbarContainer>
    );
  }

  return (
    <S.NavbarContainer>
      <S.Navbar>
        <Box display="flex" alignItems="center">
          <Link
            to={{
              pathname: URL.RECRUITMENTS,
              search: new URLSearchParams({
                ...(user?.technicalRecruiterId && {
                  technicalRecruiterId: user.technicalRecruiterId,
                }),
              }).toString(),
            }}
            data-device="pc"
            reloadDocument={pathname.startsWith('/recruitments')}
          >
            <S.Logo src={Logo} alt="logo" />
          </Link>
        </Box>
        <Box display="flex" alignItems="center">
          <S.StyledLink
            to={{
              pathname: URL.RECRUITMENTS,
              search: new URLSearchParams({
                ...(user?.technicalRecruiterId && {
                  technicalRecruiterId: user.technicalRecruiterId,
                }),
              }).toString(),
            }}
            $isActive={isActive(URL.RECRUITMENTS)}
            data-device="pc"
            reloadDocument={pathname.startsWith('/recruitments')}
          >
            Recruitments
          </S.StyledLink>
          <S.StyledLink
            to={{
              pathname: URL.TEMPLATES,
            }}
            $isActive={isActive(URL.TEMPLATES)}
            data-device="pc"
          >
            Templates
          </S.StyledLink>

          <S.StyledLink
            to={{
              pathname: URL.REPORTS,
            }}
            $isActive={isActive(URL.REPORTS)}
            data-device="pc"
          >
            Reports
          </S.StyledLink>
          <S.StyledLink
            to={{
              pathname: URL.RECRUITERS,
            }}
            $isActive={isActive(URL.RECRUITERS)}
            data-device="pc"
          >
            Recruiters
          </S.StyledLink>
          <S.LogoutButton onClick={handleLogout} type="button">
            Logout
            <ExitToAppIcon style={{ fontSize: 20, paddingLeft: 5 }} />
          </S.LogoutButton>
        </Box>
      </S.Navbar>
    </S.NavbarContainer>
  );
};

export default Navbar;
