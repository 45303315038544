import { SuggestedRecruiter } from 'api/types';
import { SelectOption } from 'components/SelectAutocomplete/SelectAutocomplete.types';

export const mapRecruitersOptions = (
  recruiters: SuggestedRecruiter[],
  groupName: 'Suggested recruiters' | 'Rest',
): SelectOption[] =>
  recruiters.map((rec) => ({
    id: rec.id,
    name: `${rec.name} ${rec.surname}`,
    group: groupName,
  }));
