import { Delete } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { TechnicalRecruiterRecruitmentPositionCategoriesDto } from 'api/types';
import MultiSelectAutocomplete from 'components/MultiSelectAutoComplete/MultiSelectAutoComplete';
import { useSeniorityDictionaryValues } from 'context/DictionaryContext';
import { AddCategoryFilterMenu } from './AddCategoryFilterMenu';

interface Props {
  categories: TechnicalRecruiterRecruitmentPositionCategoriesDto[];
  onUpdate: (categories: TechnicalRecruiterRecruitmentPositionCategoriesDto[]) => void;
}

const EditRecruiterModalCategories = ({ categories, onUpdate }: Props) => {
  const seniorityOptions = useSeniorityDictionaryValues();

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
        <Typography variant="h6" gutterBottom>
          Position categories
        </Typography>
        <AddCategoryFilterMenu
          usedPositions={categories.map((cat) => cat.positionCategoryName)}
          onSelectPosition={(selectedOption) => {
            const newCategory = {
              positionCategoryId: selectedOption.id,
              positionCategoryName: selectedOption.name,
              supportedLevels: [],
            };
            onUpdate([...categories, newCategory]);
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={2} marginTop={1}>
        {categories.map((category) => (
          <Box key={category.positionCategoryId}>
            <Box gap={1} display="flex" flexDirection="column">
              <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold' }}>
                {category.positionCategoryName}
              </Typography>
              <Box display="flex" gap={1} justifyContent="space-between">
                <MultiSelectAutocomplete
                  options={seniorityOptions.map((option) => option.name)}
                  selectedOptions={category.supportedLevels}
                  onOptionsChange={(val) => {
                    const updatedCategories = categories.map((cat) => {
                      if (cat.positionCategoryId === category.positionCategoryId) {
                        return {
                          ...cat,
                          supportedLevels: val,
                        };
                      }
                      return cat;
                    });
                    onUpdate(updatedCategories);
                  }}
                  id="levels-select"
                  label="Levels"
                />
                <IconButton
                  onClick={() => {
                    const updatedCategories = categories.filter(
                      (cat) => cat.positionCategoryId !== category.positionCategoryId,
                    );
                    onUpdate(updatedCategories);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
export default EditRecruiterModalCategories;
