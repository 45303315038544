import { TemplateModule } from 'api/types';

export interface Seniority {
  scoreValue: string;
  sortIndex: number;
}

export enum RecruitmentState {
  Created = 'CREATED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export type FeedbackApproved = boolean;

export interface RecruitmentModule extends Omit<TemplateModule, 'isNew' | 'newId'> {
  id: number;
  candidateStrengths: string | null;
  candidateWeaknesses: string | null;
  score: string | null;
  comment: string | null;
}
