import { Errors, RecruitmentForm } from '../RecruitmentForm.types';

export const defaultRecruitmentForm: RecruitmentForm = {
  candidateName: '',
  noteFromHrToRecruiter: null,
  hr: null,
  recruitmentDate: null,
  seniorityLevel: null,
  template: null,
  technicalRecruiter: null,
  remuneratedTechRecruiter: null,
  cvFile: null,
  location: null,
  tags: []
};

export const defaultRecruitmentFormErrors: Errors = {
  candidateName: false,
  hr: false,
  recruitmentDate: false,
  noteFromHrToRecruiter: false,
  seniorityLevel: false,
  template: false,
  technicalRecruiter: false,
  remuneratedTechRecruiter: false,
  cvFile: false,
  location: false,
  tags: false
};
