export enum NotScoredLevel {
  dictionaryValue = 'NOT_SCORED',
  selectableValue = 'Not scored',
}

export const mapNotScoredLevelValue = (level: string): string | NotScoredLevel => {
  if (level === NotScoredLevel.dictionaryValue) {
    return NotScoredLevel.selectableValue;
  }
  if (level === NotScoredLevel.selectableValue) {
    return NotScoredLevel.dictionaryValue;
  }
  return level;
};
