import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import { Typography } from '@mui/material';
import { ModuleService, RecruitmentService } from 'api';
import { ModuleResponse, RecruitmentFeedback } from 'api/types';
import { debounce } from 'lodash';
import { Button, Loader, LoaderContainer } from 'styles.global';
import { RecruitmentModule as TRecruitmentModule, RecruitmentState } from 'types/recruitment';
import { AccordionHeader } from 'components/Accordion/Accordion.css';
import { SEARCHBAR_DEBOUNCE_DELAY } from 'components/SearchBar/SearchBar.const';
import { StyledHeader } from 'components/StyledHeader/StyledHeader.css';
import { handleException } from 'utils/errorHandlingUtils';
import { RecruitmentInfo } from '../RecruitmentInfo';
import { CustomFeedback } from './components/RecruitmentFeedback/components/CustomFeedback';
import { OverallFeedback } from './components/RecruitmentFeedback/components/OverallFeedback';
import { defaultFeedbackCriteria } from './components/RecruitmentFeedback/defaultFeedback';
import { RecruitmentModule } from './components/RecruitmentModule';
import { RecruitmentResult } from './components/RecruitmentResult';
import {
  useGetRecruitmentInProgressData,
} from './hooks/useGetRecruitmentInProgressData/useGetRecruitmentInProgressData';
import { ScrollPosition, useNavigateToSection } from './hooks/useNavigateToSection/useNavigateToSection';
import { useRecruitmentInProgressForm } from './hooks/useRecruitmentInProgressForm/useRecruitmentInProgressForm';
import { getRecruitmentModulesSortedBySortOrder, isRecFeedbackValid } from './utils/recruitmentValidation';
import * as G from '../../../Templates/components/TemplateForm/components/TemplateModuleGroup/TemplateModuleGroup.css';
import { BottomStripe } from '../BottomStripe/BottomStripe.css';
import { FeedbackContainer, FeedbackSectionContainer } from './components/RecruitmentFeedback/RecruitmentFeedback.css';
import * as S from './Recruitment.css';
import { mapNotScoredLevelValue, NotScoredLevel } from './utils/mapNotScoredLevel';

const debouncedPatchRecruitment = debounce((fn : Function) => fn(), SEARCHBAR_DEBOUNCE_DELAY);

export const Recruitment = () => {
  const navigate = useNavigate();
  const { patchRecruitment } = RecruitmentService.usePatchRecruitment();
  const { patchRecruitmentModule } = ModuleService.usePatchModule();

  const {
    recruitmentFormData,
    recruitmentInfo,
    recruitmentResult,
    recId,
    getRecruitment,
    setRecruitmentResult,
  } = useGetRecruitmentInProgressData();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    recruitmentForm,
    handleUpdateFeedback,
    handleUpdateModuleFeedback,
    handleUpdateRecruitmentState,
    handleUpdateTags
  } = useRecruitmentInProgressForm(recruitmentFormData);

  useNavigateToSection(
    [recruitmentForm?.recruitmentState],
    'recruitment-result',
    ScrollPosition.center,
  );

  const onModuleFeedbackChange = async (
    module: TRecruitmentModule,
    fieldName: keyof TRecruitmentModule,
    value: string,
  ) => {
    const updatedModule = { ...module };

    if (fieldName === 'score' && value === 'Not scored') {
      updatedModule.candidateWeaknesses = null;
      handleUpdateModuleFeedback(module, 'candidateWeaknesses', null);
      updatedModule.candidateStrengths = null;
      handleUpdateModuleFeedback(module, 'candidateStrengths', null);
    } else if (fieldName === 'score' && value !== 'Not scored') {
      updatedModule.comment = null;
      handleUpdateModuleFeedback(module, 'comment', null);
    }

    handleUpdateModuleFeedback(module, fieldName, value);
    if (
      fieldName === 'score' &&
      recruitmentForm?.recruitmentState === RecruitmentState.Completed
    ) {
      await patchRecruitmentModule(recId, {
        ...updatedModule,
        [fieldName]: mapNotScoredLevelValue(value),
      });
      const response = await getRecruitment(recId);
      setRecruitmentResult({
        matchedLevel: response.data.matchResult.matchedLevel,
        differenceInPercents: response.data.matchResult.differenceInPercents,
        comparisonDescription: response.data.matchResult.comparisonDescription,
      });
    } else {
      debouncedPatchRecruitment(async () => {
        await patchRecruitmentModule(recId, {
          ...updatedModule,
          [fieldName]: value,
        });
      });
    }
  };

  const onFeedbackChange = async (
    value: string | boolean,
    fieldName: keyof RecruitmentFeedback,
  ) => {
    handleUpdateFeedback(value, fieldName);
    if (
      fieldName.includes('Reason') ||
      fieldName === 'feedbackForCandidate' ||
      fieldName === 'feedbackForHr' ||
      fieldName === 'additionalHrFeedbackFromCandidate' ||
      fieldName === 'additionalHrFeedbackFromClient'
    ) {
      debouncedPatchRecruitment(async () => {
        await patchRecruitment(recId, {
          feedbackUpdate: {
            ...recruitmentForm?.feedbackUpdate,
            [fieldName]: value,
          },
        });
      });
    } else {
      await patchRecruitment(recId, {
        feedbackUpdate: {
          ...recruitmentForm?.feedbackUpdate,
          [fieldName]: value,
        },
      });
    }
  };

  const onTagsChange = async (tags: number[]) => {
    handleUpdateTags(tags);
    debouncedPatchRecruitment(async () => {
      await patchRecruitment(recId, {
        tagIds: tags,
      });
    });
  };

  const onRecruitmentCompleted = async () => {
    try {
      if (recruitmentForm) {
        setIsLoading(true);
        await patchRecruitment(recId, recruitmentForm);
        await Promise.all(
          recruitmentForm.modules.map((mod) => patchRecruitmentModule(recId, mod)),
        );
        await patchRecruitment(recId, {
          recruitmentState: RecruitmentState.Completed,
        });
        const response = await getRecruitment(recId);
        handleUpdateRecruitmentState(RecruitmentState.Completed);
        setRecruitmentResult({
          matchedLevel: response.data.matchResult.matchedLevel,
          differenceInPercents: response.data.matchResult.differenceInPercents,
          comparisonDescription: response.data.matchResult.comparisonDescription,
        });
        setIsLoading(false);
      }
    } catch (e) {
      handleException(e);
    }
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const engMods: ModuleResponse[] = getRecruitmentModulesSortedBySortOrder('ENGINEERING', recruitmentForm?.modules || []);

  const techMods: ModuleResponse[] = getRecruitmentModulesSortedBySortOrder('TECHNICAL', recruitmentForm?.modules || []);

  const invalidModulesFeedback =
    recruitmentForm?.modules.filter((mod) =>
      mod?.score !== NotScoredLevel.selectableValue && (!mod.candidateStrengths || !mod.candidateWeaknesses)
    ) || [];

  const invalidFeedbackMods = isRecFeedbackValid(recruitmentForm);

  const isValidCustomFeedback: boolean =
    !!recruitmentForm?.feedbackUpdate.feedbackForCandidate;

  const isFormValid: boolean =
    invalidModulesFeedback.length === 0 && invalidFeedbackMods && isValidCustomFeedback;

  const isMarkAsCompletedButtonActive: boolean = isFormValid;

  const isFormActive: boolean =
    recruitmentForm?.recruitmentState === RecruitmentState.InProgress ||
    recruitmentForm?.recruitmentState === RecruitmentState.Completed ||
    false;

  return !isLoading && recruitmentForm !== undefined ? (
    <div>
      <S.RecruitmentContentContainer
        $isNarrow={recruitmentForm.recruitmentState !== RecruitmentState.InProgress}
      >
        <S.RecruitmentContent>
          {recruitmentInfo && <RecruitmentInfo recruitment={recruitmentInfo} recruitmentForm={recruitmentForm} updateTagsInFormCallback={onTagsChange}/>}
          {engMods.length > 0 && (
            <G.ModuleGroupContainer>
              <G.ModuleGroupHeader>
                <GroupIcon />
                <Typography>Engineering modules</Typography>
              </G.ModuleGroupHeader>
              {engMods.map((mod) => (
                <RecruitmentModule
                  readOnly={!isFormActive}
                  key={mod.id}
                  recruitmentModule={mod}
                  handleUpdateModuleFeedback={onModuleFeedbackChange}
                />
              ))}
            </G.ModuleGroupContainer>
          )}

          {techMods.length > 0 && (
            <G.ModuleGroupContainer>
              <G.ModuleGroupHeader>
                <BuildIcon />
                <Typography>Technical modules</Typography>
              </G.ModuleGroupHeader>
              {techMods.map((mod) => (
                <RecruitmentModule
                  readOnly={!isFormActive}
                  key={mod.id}
                  recruitmentModule={mod}
                  handleUpdateModuleFeedback={onModuleFeedbackChange}
                />
              ))}
            </G.ModuleGroupContainer>
          )}

          {recruitmentForm.recruitmentState === RecruitmentState.Completed &&
            recruitmentResult && <RecruitmentResult matchResult={recruitmentResult} />}

          <FeedbackContainer>
            <AccordionHeader>Feedback</AccordionHeader>
            <FeedbackSectionContainer>
              <StyledHeader
                marginLeft="0"
                fontSize="20px"
                marginTop="32px"
                marginBottom="0px"
                variant="body1"
              >
                Soft module
              </StyledHeader>
              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.personality.title}
                description={defaultFeedbackCriteria.personality.description}
                criteria={defaultFeedbackCriteria.personality.criteria}
                approved={{
                  value: recruitmentForm.feedbackUpdate.personality,
                  fieldName: 'personality',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.personalityReason
                    ? recruitmentForm.feedbackUpdate.personalityReason
                    : '',
                  fieldName: 'personalityReason',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.culture.title}
                description={defaultFeedbackCriteria.culture.description}
                criteria={defaultFeedbackCriteria.culture.criteria}
                approved={{
                  value: recruitmentForm.feedbackUpdate.highPersonalCulture,
                  fieldName: 'highPersonalCulture',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.highPersonalCultureReason
                    ? recruitmentForm.feedbackUpdate.highPersonalCultureReason
                    : '',
                  fieldName: 'highPersonalCultureReason',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.professionalism.title}
                description={defaultFeedbackCriteria.professionalism.description}
                criteria={defaultFeedbackCriteria.professionalism.criteria}
                approved={{
                  value: recruitmentForm.feedbackUpdate.professionalism,
                  fieldName: 'professionalism',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.professionalismReason
                    ? recruitmentForm.feedbackUpdate.professionalismReason
                    : '',
                  fieldName: 'professionalismReason',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.learn.title}
                description={defaultFeedbackCriteria.learn.description}
                criteria={defaultFeedbackCriteria.learn.criteria}
                approved={{
                  value: recruitmentForm.feedbackUpdate.willingnessToGrowAndLearn,
                  fieldName: 'willingnessToGrowAndLearn',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.willingnessToGrowAndLearnReason
                    ? recruitmentForm.feedbackUpdate.willingnessToGrowAndLearnReason
                    : '',
                  fieldName: 'willingnessToGrowAndLearnReason',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.relationships.title}
                description={defaultFeedbackCriteria.relationships.description}
                criteria={defaultFeedbackCriteria.relationships.criteria}
                approved={{
                  value: recruitmentForm.feedbackUpdate.buildingRelationships,
                  fieldName: 'buildingRelationships',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.buildingRelationshipsReason
                    ? recruitmentForm.feedbackUpdate.buildingRelationshipsReason
                    : '',
                  fieldName: 'buildingRelationshipsReason',
                }}
                onEditFeedback={onFeedbackChange}
              />
            </FeedbackSectionContainer>
            <FeedbackSectionContainer>
              <StyledHeader
                marginLeft="0"
                fontSize="20px"
                marginTop="32px"
                marginBottom="0px"
                variant="body1"
              >
                Overall Feedback
              </StyledHeader>
              <OverallFeedback
                readOnly={!isFormActive}
                title={defaultFeedbackCriteria.recommendation.title}
                description={defaultFeedbackCriteria.recommendation.description}
                criteria={[]}
                approved={{
                  value: recruitmentForm.feedbackUpdate.recommendation,
                  fieldName: 'recommendation',
                }}
                notApprovedReasons={{
                  value: recruitmentForm.feedbackUpdate.recommendationReason
                    ? recruitmentForm.feedbackUpdate.recommendationReason
                    : '',
                  fieldName: 'recommendationReason',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <CustomFeedback
                readOnly={!isFormActive}
                required
                title="Feedback for candidate"
                placeholder="Feedback from recruiter about the candidate"
                feedbackField={{
                  value: recruitmentForm.feedbackUpdate.feedbackForCandidate
                    ? recruitmentForm.feedbackUpdate.feedbackForCandidate
                    : '',
                  fieldName: 'feedbackForCandidate',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <CustomFeedback
                readOnly={!isFormActive}
                title="Feedback for HR"
                placeholder="Feedback from recruiter about candidate sent to HR"
                feedbackField={{
                  value: recruitmentForm.feedbackUpdate.feedbackForHr
                    ? recruitmentForm.feedbackUpdate.feedbackForHr
                    : '',
                  fieldName: 'feedbackForHr',
                }}
                onEditFeedback={onFeedbackChange}
              />
            </FeedbackSectionContainer>

            <FeedbackSectionContainer>
              <StyledHeader
                marginLeft="0"
                fontSize="20px"
                marginTop="32px"
                marginBottom="16px"
                variant="body1"
              >
                Post-recruitment feedback
              </StyledHeader>
              <CustomFeedback
                readOnly={false}
                title="Additional HR feedback from candidate"
                placeholder="After recruitment feedback from candidate to HR"
                feedbackField={{
                  value: recruitmentForm.feedbackUpdate.additionalHrFeedbackFromCandidate
                    ? recruitmentForm.feedbackUpdate.additionalHrFeedbackFromCandidate
                    : '',
                  fieldName: 'additionalHrFeedbackFromCandidate',
                }}
                onEditFeedback={onFeedbackChange}
              />

              <CustomFeedback
                readOnly={false}
                title="Additional HR feedback from client"
                placeholder="Feedback about candidate from client HR"
                feedbackField={{
                  value: recruitmentForm.feedbackUpdate.additionalHrFeedbackFromClient
                    ? recruitmentForm.feedbackUpdate.additionalHrFeedbackFromClient
                    : '',
                  fieldName: 'additionalHrFeedbackFromClient',
                }}
                onEditFeedback={onFeedbackChange}
              />
            </FeedbackSectionContainer>
          </FeedbackContainer>
        </S.RecruitmentContent>

        <BottomStripe>
          <Button variant="contained" onClick={onGoBack}>
            Go back
          </Button>
          {recruitmentForm.recruitmentState === RecruitmentState.InProgress && (
            <S.Tooltip
              enterDelay={200}
              enterNextDelay={200}
              title={`${isFormValid ? '' : 'Fill in all required fields'}`}
              placement="top"
            >
              <div>
                <Button
                  $secondary
                  onClick={onRecruitmentCompleted}
                  disabled={!isMarkAsCompletedButtonActive}
                  variant="contained"
                  startIcon={<S.CheckIcon $disabled={!isMarkAsCompletedButtonActive} />}
                >
                  Save &amp; mark as completed
                </Button>
              </div>
            </S.Tooltip>
          )}
        </BottomStripe>
      </S.RecruitmentContentContainer>
    </div>
  ) : (
    <LoaderContainer data-testid="loaderContainer">
      <Loader size="100px" />
    </LoaderContainer>
  );
};
