import { AddCircle } from '@mui/icons-material';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Position } from 'api/types';
import { usePositionCategories } from 'context/DictionaryContext';
import { MouseEvent, useState } from 'react';
import { Button } from 'styles.global';

interface Props {
  usedPositions: string[];
  onSelectPosition: (selectedOption: Position) => void;
}

export const AddCategoryFilterMenu = ({ usedPositions, onSelectPosition }: Props) => {
  const positions = usePositionCategories();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (selectedOption: Position) => {
    handleClose();
    onSelectPosition(selectedOption);
  };

  const availablePositions = positions.filter(
    (position) => !usedPositions.includes(position.name),
  );

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<AddCircle />}
      >
        Add category
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {availablePositions.length === 0 ? (
          <MenuItem disabled>
            <Typography variant="body2">No additional filters available</Typography>
          </MenuItem>
        ) : (
          availablePositions.map((opt) => (
            <MenuItem key={opt.id} onClick={() => onSelect(opt)}>
              {opt.name}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
};
