import { useCallback } from 'react';
import { TemplatesPage } from 'types/template';
import { useAxios } from './axios';
import { ExistingTemplate, TemplateModule } from './types';

export const useCreateTemplate = () => {
  const [{ loading: isCreatingTemplate }, createTemplateData] = useAxios(
    { url: '/templates', method: 'POST' },
    { manual: true },
  );
  const createTemplate = (
    name: string,
    positionCategoryId: number,
    modules: TemplateModule[],
  ) => createTemplateData({ data: { name, positionCategoryId, modules } });
  return { isCreatingTemplate, createTemplate, createTemplateData };
};

export const usePutTemplate = (tId: number) => {
  const [{ data, loading: isPuttingTemplate }, putTemplateData] =
    useAxios<ExistingTemplate>(
      { method: 'PUT', url: `templates/${tId}` },
      { manual: true },
    );

  const putTemplate = (
    name: string,
    positionCategoryId: number,
    modules: TemplateModule[],
  ) => putTemplateData({ data: { name, positionCategoryId, modules } });

  return {
    templateData: data,
    isPuttingTemplate,
    putTemplate,
  };
};

export const useGetTemplate = () => {
  const [{ data, loading: isLoadingTemplate }, getTemplateData] =
    useAxios<ExistingTemplate>({ method: 'GET' }, { manual: true });
  const getTemplate = useCallback(
    (templateId: number) => getTemplateData({ url: `/templates/${templateId}` }),
    [getTemplateData],
  );
  return {
    templateData: data,
    isLoadingTemplate,
    getTemplate,
  };
};

export const useGetTemplates = () => {
  const [{ loading: isLoadingTemplates, data: templatesData }, getTemplatesData] =
    useAxios<TemplatesPage>({ url: '/templates', method: 'GET' }, { manual: true });
  const getTemplates = useCallback(() => getTemplatesData(), [getTemplatesData]);
  return { getTemplates, isLoadingTemplates, templatesData };
};

export const useDeleteTemplate = () => {
  const [{ loading: isDeletingTemplate }, deleteTemplateData] = useAxios(
    { method: 'DELETE' },
    { manual: true },
  );
  const deleteTemplate = (id: number) => deleteTemplateData({ url: `/templates/${id}` });
  return { isDeletingTemplate, deleteTemplate };
};

export const useGetTemplateDocx = () => {
  const [{ loading: isLoadingDocxDownload }, getDocx] = useAxios(
    {
      method: 'GET',
      responseType: 'blob',
    },
    { manual: true },
  );

  const getDocxDocument = useCallback(
    async (id: number) => {
      const url = `/documents/template/${id}`;
      return getDocx({ url });
    },
    [getDocx],
  );

  return { isLoadingDocxDownload, getDocxDocument };
};
