import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SeniorityServices, TemplateService, UserService } from 'api';
import { Person } from '../types/common';
import { Seniority } from '../types/recruitment';
import { TemplateRecord } from '../types/template';
import { GlobalTimerProviderProps } from './Context.types';
import { useGetTemplateModules } from '../api/dictionaries';
import { useGetPositions } from 'api/position';
import { Position } from 'api/types';
import { mapNotScoredLevelValue } from 'pages/Recruitment/components/Recruitment/utils/mapNotScoredLevel';

type Dictionary = {
  seniorityLevels: Seniority[];
  technicalRecruitersOptions: Person[];
  hrContacts: Person[];
  templates: TemplateRecord[];
  distinctModuleNames: string[];
  positionCategories: Position[];
};

interface DictionaryContextType {
  dictionary: Dictionary;
  reFetchTemplates: () => Promise<void>;
}

const DictionaryContext = createContext<DictionaryContextType>({
  dictionary: {
    seniorityLevels: [],
    technicalRecruitersOptions: [],
    hrContacts: [],
    templates: [],
    distinctModuleNames: [],
    positionCategories: [],
  },
  reFetchTemplates: async () => {},
});

export const DictionaryContextProvider = ({ children }: GlobalTimerProviderProps) => {
  const [dictionaryFields, setDictionaryFields] = useState<Dictionary>({
    seniorityLevels: [],
    technicalRecruitersOptions: [],
    hrContacts: [],
    templates: [],
    distinctModuleNames: [],
    positionCategories: [],
  });
  const { getSeniorityLevelsData } = SeniorityServices.useGetSeniorityLevels();
  const { getTechnicalRecruitersData } = UserService.useGetTechnicalRecruiters();
  const { getHrsData } = UserService.useGetHrs();
  const { getTemplates } = TemplateService.useGetTemplates();
  const { getModuleNames } = useGetTemplateModules();
  const { getPositions } = useGetPositions();
  useEffect(() => {
    Promise.all([
      getSeniorityLevelsData(),
      getTechnicalRecruitersData(),
      getHrsData(),
      getTemplates(),
      getModuleNames(),
      getPositions(),
    ]).then(
      ([
        response,
        recruitersResponse,
        hrsResponse,
        templatesResponse,
        moduleNamesResponse,
        positionsResponse,
      ]) => {
        setDictionaryFields({
          seniorityLevels: response.data.scores,
          technicalRecruitersOptions: recruitersResponse.data.technicalRecruiters,
          hrContacts: hrsResponse.data.hrs,
          templates: templatesResponse.data.templates,
          distinctModuleNames: moduleNamesResponse.data.moduleNames,
          positionCategories: positionsResponse.data.positionCategories,
        });
      },
    );
  }, []);

  const reFetchTemplates = useCallback(async () => {
    const response = await getTemplates();
    setDictionaryFields((prevState) => ({
      ...prevState,
      templates: response.data.templates,
    }));
  }, []);

  const dictionary: DictionaryContextType = useMemo(
    () => ({
      dictionary: dictionaryFields,
      reFetchTemplates,
    }),
    [dictionaryFields, reFetchTemplates],
  );

  return (
    <DictionaryContext.Provider value={dictionary}>{children}</DictionaryContext.Provider>
  );
};

export const useDictionaryContext = () => useContext(DictionaryContext);
export const useSeniorityDictionaryValues = () =>
  useDictionaryContext().dictionary.seniorityLevels.map((it) => ({
    name: mapNotScoredLevelValue(it.scoreValue),
  }));

function mapPersonToSelectable(person: Person) {
  return {
    id: person.id,
    name: `${person.name} ${person.surname}`,
  };
}

function mapTemplateToSelectable(templateRecord: TemplateRecord) {
  return {
    id: templateRecord.id,
    name: templateRecord.name,
  };
}

export const useTemplateDictionary = () =>
  useDictionaryContext().dictionary.templates.map((recruiter) =>
    mapTemplateToSelectable(recruiter),
  );

export const useTechnicalRecruitersOptionsDictionary = () =>
  useDictionaryContext().dictionary.technicalRecruitersOptions.map((recruiter) =>
    mapPersonToSelectable(recruiter),
  );

export const useHRContactsDictionary = () =>
  useDictionaryContext().dictionary.hrContacts.map((hr) => mapPersonToSelectable(hr));

export const useDistinctModuleNamesDictionary = () =>
  useDictionaryContext().dictionary.distinctModuleNames;

export const usePositionCategories = () =>
  useDictionaryContext().dictionary.positionCategories;
