import { FilterList } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { RecruitersFilter, RecruitersFilterInUse } from '../RecruitersFilters';

interface FiltersMenuProps {
  options: RecruitersFilter[];
  onOptionSelect: (filters: RecruitersFilterInUse) => void;
}

export const FiltersMenu = ({ options, onOptionSelect }: FiltersMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (selectedOption: RecruitersFilterInUse) => {
    handleClose();
    onOptionSelect(selectedOption);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FilterList />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.length === 0 ? (
          <MenuItem disabled>
            <Typography variant="body2">No additional filters available</Typography>
          </MenuItem>
        ) : (
          options.map((opt) => (
            <MenuItem
              key={opt.accessor}
              onClick={() => onSelect({ accessor: opt.accessor, value: '' })}
            >
              {opt.name}
            </MenuItem>
          ))
        )}
      </Menu>
    </div>
  );
};
