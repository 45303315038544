import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledHeader = styled(Typography)<{
  fontSize?: string;
  marginBottom?: string;
  marginTop?: string;
  marginRight?: string;
  marginLeft?: string;
}>`
  &.MuiTypography-root {
    font-size: ${({ fontSize }) => fontSize || '24px'};
    width: fit-content;
    margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
    margin-top: ${({ marginTop }) => marginTop || '16px'};
    margin-right: ${({ marginRight }) => marginRight || '16px'};
    margin-left: ${({ marginLeft }) => marginLeft || '16px'};
    border-bottom: 2px solid #ffd242;
    text-underline-offset: 4px;
  }
`;
