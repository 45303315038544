import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import styled, { css } from 'styled-components';
import theme from 'theme';
import { OptionsIconProps, PopoverOptionProps } from './Popover.types';

export const OptionsIcon = styled(MoreVertIcon)<OptionsIconProps>`
  ${(props) =>
    props.disabled &&
    css`
      color: lightgray;
    `}
  border-radius: 2px;
  padding: 2px;
  height: 30px;
  vertical-align: middle;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background-color: rgb(230, 230, 230);
        cursor: pointer;
      `}
  }
` as typeof Button;

export const PopoverOptions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
`;

export const PopoverOption = styled.div<PopoverOptionProps>`
  padding: 10px;
  font-weight: bold;

  ${(props) =>
    props.danger &&
    css`
      color: ${theme.palette.error.main};
    `}

  &:hover {
    background-color: rgb(230, 230, 230);
    cursor: pointer;
  }
`;
