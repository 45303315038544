import { useAxios } from './axios';
import { useCallback } from 'react';

export interface SearchTagsRequestParams {
  name?: string;
}

export interface Tag {
  id: number;
  name: string;
}

export type SearchTagsResponse = Tag[] | undefined;

export const useGetTags = () => {
  const [{ data, loading: isLoadingTags }, getTags] = useAxios<SearchTagsResponse>(
    { method: 'GET' },
    { manual: true },
  );

  const searchTags = useCallback(
    (name: string) =>
      getTags({
        url: '/tags',
        params: {
          name,
        },
      }),
    [getTags],
  );

  return { isLoadingTags, data, searchTags };
};

export const useCreateTag = () => {
  const [{ data, loading: isCreatingTag }, postCreateTag] = useAxios<Tag>(
    { method: 'POST' },
    { manual: true },
  );
  const createTag = useCallback(
    (name: string) =>
      postCreateTag({
        url: '/tags',
        data: { name },
      }),
    [postCreateTag],
  );
  return { isCreatingTag, data, createTag };
};
