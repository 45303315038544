import { useState } from 'react';
import { useGetCustomReport } from 'api/reports';
import { subDays } from 'date-fns';
import DateInput from 'components/DateInput';
import { handleException } from 'utils/errorHandlingUtils';
import { getDate } from 'utils/formatters';
import { exportFile } from 'utils/helpers';
import * as S from './Report.css';

const Reports = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFromValue, setDateFromValue] = useState<Date | null>(subDays(new Date(), 1));
  const [dateToValue, setDateToValue] = useState<Date | null>(new Date());

  const { getCustomReport } = useGetCustomReport();

  const handleDownloadCustomReport = async () => {
    if (dateFromValue && dateToValue) {
      setIsLoading(true);
      try {
        const response = await getCustomReport(
          getDate(dateFromValue, 'yyyy-MM-dd'),
          getDate(dateToValue, 'yyyy-MM-dd'),
        );
        exportFile(response);
      } catch (e) {
        handleException(e);
      }
      setIsLoading(false);
    }
  };

  return (
    <S.ReportPageContainer>
      <S.MainContainer>
        <h2>Remuneration report</h2>
        <S.Field>
          <DateInput
            name="dateFrom"
            label="From date"
            onChange={(e) => setDateFromValue(e)}
            value={dateFromValue}
            maxDate={subDays(new Date(), 1)}
            invalidDateMessage="Enter valid date"
          />
        </S.Field>
        <S.Field>
          <DateInput
            name="dateTo"
            label="To date"
            onChange={(e) => setDateToValue(e)}
            value={dateToValue}
            minDate={dateFromValue || undefined}
            maxDate={new Date()}
            invalidDateMessage="Enter valid date"
          />
        </S.Field>
        <S.DownloadButton
          startIcon={isLoading ? <S.CircularProgress size="20px" /> : <S.DocumentIcon />}
          $primary
          disabled={isLoading}
          onClick={handleDownloadCustomReport}
        >
          Download
        </S.DownloadButton>
      </S.MainContainer>
    </S.ReportPageContainer>
  );
};

export default Reports;
