import { useCallback, useEffect } from 'react';
import {
  useGetTechnicalRecruiters,
  mapFilteringQueryParamsToRequest,
} from 'api/recruiters';
import { handleException } from 'utils/errorHandlingUtils';
import RecruitersTable from './components/RecruitersTable/RecruitersTable';
import RecruitersActionBar from './components/RecruitersActionBar/RecruitersActionBar';
import { useRecruitersTableQuery } from './hooks/useRecruitersTableQuery';
import { RecruitmentsPageContainer } from '../Recruitments/RecruitmentsPage.css';

export enum FilterType {
  optional = 'optional',
  default = 'default',
}

const RecruitersPage = () => {
  const {
    getTechnicalRecruiters,
    isLoadingTechnicalRecruitersData,
    technicalRecruitersData,
  } = useGetTechnicalRecruiters();
  const { tableQuery, onUpdateTableFilters } = useRecruitersTableQuery();

  const fetchRecruiters = useCallback(async () => {
    const requestParams = mapFilteringQueryParamsToRequest(tableQuery);
    try {
      await getTechnicalRecruiters(requestParams);
    } catch (e) {
      handleException(e);
    }
  }, [getTechnicalRecruiters, tableQuery]);

  useEffect(() => {
    fetchRecruiters();
  }, [fetchRecruiters]);

  return (
    <RecruitmentsPageContainer>
        <RecruitersActionBar query={tableQuery} onUpdateFilters={onUpdateTableFilters} />
        <RecruitersTable
          technicalRecruiters={technicalRecruitersData?.technicalRecruiters ?? []}
          isLoading={isLoadingTechnicalRecruitersData}
          fetchRecruiters={fetchRecruiters}
        />
    </RecruitmentsPageContainer>
  );
};

export default RecruitersPage;
