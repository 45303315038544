import { createTheme } from '@mui/material';

const palette = {
  primary: {
    main: '#ffd242',
  },
  secondary: {
    dark: '#1D1D1D',
    main: '#f4f4f4',
    light: '#fafafa',
  },
  error: {
    main: '#FF0808',
  },
  success: {
    main: '#10b228',
  },
};
export default createTheme({
  palette,
  components: {
    MuiButton: {
      color: palette.secondary.dark
    },
    MuiAccordion: {
      root: {
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.08)',
        border: '0.5px #ededed solid',
        backgroundColor: 'white',
      },
      rounded: {
        borderRadius: '0px',
        '&:last-child': {
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
        },
        '&:first-child': {
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: `${palette.primary.main}`,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: `${palette.primary.main}`,
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        whiteSpace: 'pre-wrap',
        maxWidth: '300px',
        overflowY: 'auto',
        maxHeight: '60vh',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: `${palette.secondary.dark}`,
      },
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        color: 'white',
      },
      toolbarTxt: {
        color: 'white',
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: `${palette.secondary.dark}`,
      },
    },
    MuiTab: {
      textColorInherit: {
        color: `${palette.primary.main}`,
      },
    },
    PrivateTabIndicator: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: `${palette.primary.main}`,
        },
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '40px',
        lineHeight: '38px',
        color: '#1D1D1D',
        letterSpacing: '1px',
        fontWeight: 700,
      },
      h2: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '30px',
        lineHeight: '35px',
        color: '#1D1D1D',
        letterSpacing: '1px',
        fontWeight: 700,
      },
      h3: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '20px',
        lineHeight: '24px',
        color: '#1D1D1D',
        letterSpacing: '1px',
        fontWeight: 700,
      },
      h4: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '20px',
        lineHeight: '23px',
        color: '#1D1D1D',
        letterSpacing: '1px',
        fontWeight: 700,
        textTransform: 'capitalize',
      },
      h5: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '17px',
        lineHeight: '20px',
        color: '#1D1D1D',
        fontWeight: 700,
      },
      h6: {
        fontFamily: "'Moderat JIT', sans-serif",
        fontSize: '17px',
        lineHeight: '25px',
        color: '#1D1D1D',
        fontWeight: 'bold',
      },
    },
  } as any, // any needed to override mui styles not included in Overrides interface
});
