import {
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { TableCustomButton } from '../../../../styles.global';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Pagination from '../../../../components/Pagination';
import { PaginationOptions } from '../../../../utils/hooks/types';

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    onAddNewClick: () => void;
    buttonText: string;
    paginationOptions: PaginationOptions;
    onUpdatePagination: (paginationOptions: PaginationOptions) => void;
  }
}

export const CustomToolbar = ({
  onAddNewClick,
  buttonText,
  paginationOptions,
  onUpdatePagination,
}: NonNullable<GridSlotsComponentsProps['toolbar']>) => (
  <GridToolbarContainer
    sx={{ display: 'flex', justifyContent: 'space-between', paddingInline: 2 }}
  >
    <GridToolbarColumnsButton />
    <div>
      {paginationOptions && onUpdatePagination && (
        <Pagination
          paginationOptions={paginationOptions}
          onUpdatePagination={onUpdatePagination}
        />
      )}
    </div>
    <div>
      <TableCustomButton
        onClick={onAddNewClick}
        startIcon={<ControlPointIcon />}
        style={{ marginLeft: 'auto' }}
        $primary
      >
        {buttonText}
      </TableCustomButton>
    </div>
  </GridToolbarContainer>
);
