import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PositionServices, TemplateService } from 'api';
import { ExistingTemplate } from 'api/types';
import { TEMPLATES } from 'router/url';
import { Loader, LoaderContainer } from 'styles.global';
import { NewTemplate } from 'types/template';
import { handleException } from 'utils/errorHandlingUtils';
import { RouteParams } from 'utils/types/RouteParams';
import { TemplateForm } from '../components/TemplateForm';

export const EditTemplate = () => {
  const navigate = useNavigate();
  const { id: pId } = useParams<RouteParams>();
  const tId: number = Number(pId);

  const { templateData, getTemplate, isLoadingTemplate } =
    TemplateService.useGetTemplate();
  const { putTemplate, isPuttingTemplate } = TemplateService.usePutTemplate(tId);

  const { positionsData, getPositions, isLoadingPositions } =
    PositionServices.useGetPositions();

  const handleSubmit = useCallback(
    async (template: ExistingTemplate | NewTemplate) => {
      if (template.positionCategory) {
        try {
          await putTemplate(
            template.name,
            template.positionCategory?.id,
            template.modules.map((mod) => {
              if (mod.isNew) {
                return {
                  name: mod.name,
                  weight: mod.weight,
                  requirements: mod.requirements,
                  moduleType: mod.moduleType,
                  sortOrder: mod.sortOrder
                };
              }
              return mod;
            }),
          );
          navigate(TEMPLATES);
        } catch (e) {
          handleException(e);
        }
      }
    },
    [templateData, tId, navigate],
  );

  const positions = useMemo(
    () =>
      positionsData.map(({ name, id }) => ({
        name,
        id,
      })),
    [positionsData],
  );

  const template: ExistingTemplate | undefined = useMemo(() => {
    if (templateData) {
      return {
        ...templateData,
        modules: templateData.modules.map((module) => ({
          ...module,
          id: module.id,
          isNew: false,
        })),
      };
    }
  }, [templateData]);

  const isLoading: boolean =
    isLoadingTemplate || isPuttingTemplate || isLoadingPositions || !templateData;

  useEffect(() => {
    getTemplate(tId);
  }, [getTemplate, tId]);

  useEffect(() => {
    getPositions();
  }, [getPositions]);

  return !isLoading && template ? (
    <TemplateForm
      templateData={template}
      positionsOptions={positions}
      formTitle="Edit template"
      onTemplateSubmit={handleSubmit}
    />
  ) : (
    <LoaderContainer data-testid="loaderContainer">
      <Loader size="100px" />
    </LoaderContainer>
  );
};
