import { useMemo, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import { TextField } from '@mui/material';
import { ExistingTemplate, ModuleType, TemplateModule } from 'api/types';
import { useUserContext } from 'context';
import { Button, InputLabel } from 'styles.global';
import { NewTemplate } from 'types/template';
import Container from 'components/Container';
import { SelectAutocomplete } from 'components/SelectAutocomplete';
import { SelectedOption } from 'components/SelectAutocomplete/SelectAutocomplete.types';
import { TemplateModuleForm } from './components/TemplateModuleForm';
import { TemplateModuleGroup } from './components/TemplateModuleGroup';
import { TemplateFormProps } from './TemplateForm.types';
import * as S from './TemplateForm.css';
import { sortModuleRequirementsBySeniorityLevel } from './utils/utils';
import { useSeniorityDictionaryValues } from 'context/DictionaryContext';
import { StyledHeader } from 'components/StyledHeader/StyledHeader.css';

export const TemplateForm = ({
  formTitle,
  onTemplateSubmit,
  templateData,
  positionsOptions,
}: TemplateFormProps) => {
  const { user } = useUserContext();

  const requirementsOrder = useSeniorityDictionaryValues().map((level) => level.name);

  const [templateForm, setTemplateForm] = useState<ExistingTemplate | NewTemplate>(
    templateData,
  );

  const [dialogForm, setDialogForm] = useState<TemplateModule | null>(null);

  const [isDialogEditing, setIsDialogEditing] = useState<boolean>(false);

  const isSubmitActive: boolean =
    !!templateForm?.name && !!templateForm?.positionCategory && !!user?.hasFullAccess;

  const nameIsNotValid: boolean = templateForm.name.length > 100;

  const dialogTitle: string = `${isDialogEditing ? 'Edit' : 'Add'} ${
    dialogForm?.moduleType === 'ENGINEERING' ? 'engineering' : 'technical'
  } module`;

  const handleOpenAddTechnicalModuleModal = (existingModules: TemplateModule[]) => {
    setDialogForm({
      name: '',
      weight: 100,
      requirements: [],
      moduleType: 'TECHNICAL',
      sortOrder: existingModules?.length
        ? Math.max(...existingModules.map((module) => module.sortOrder)) + 1
        : 0,
      isNew: true,
      newId: new Date().getTime(),
    });
  };

  const handleTemplateName = (templateName: string) => {
    setTemplateForm({ ...templateForm, name: templateName });
  };

  const handleEditPosition = (positionCategory: SelectedOption) => {
    if (positionCategory?.id) {
      setTemplateForm({
        ...templateForm,
        positionCategory: {
          id: Number(positionCategory.id),
          name: positionCategory.name,
        },
      });
    }
  };

  const handleEditModuleClick = (module: TemplateModule) => {
    setIsDialogEditing(true);
    setDialogForm(module);
  };

  const handleDeleteModule = (moduleId: number) => {
    setTemplateForm({
      ...templateForm,
      modules: templateForm.modules.filter((module) =>
        module.id ? module.id !== moduleId : module.newId !== moduleId,
      ),
    });
  };

  const handleCloseDialog = () => {
    setDialogForm(null);
    setIsDialogEditing(false);
  };

  const handleSubmitDialog = () => {
    if (isDialogEditing) {
      setTemplateForm({
        ...templateForm,
        modules: templateForm.modules.map((module) => {
          if (module.id) {
            return module.id === dialogForm?.id ? dialogForm : module;
          }
          if (module.newId) {
            return module.newId === dialogForm?.newId ? dialogForm : module;
          }
          return module;
        }),
      });
    } else if (!isDialogEditing && dialogForm) {
      setTemplateForm({
        ...templateForm,
        modules: [...templateForm.modules, dialogForm],
      });
    }

    setDialogForm(null);
    setIsDialogEditing(false);
  };

  const handleTemplateSubmit = () => {
    onTemplateSubmit(templateForm);
  };

  const filterByModuleTypeAndSortBySortOrder = (
    modules: TemplateModule[],
    type: ModuleType,
  ) =>
    modules
      .filter((module) => module.moduleType === type)
      .sort((a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity))
      .map((module) => ({
        ...module,
        requirements: sortModuleRequirementsBySeniorityLevel(
          module.requirements,
          requirementsOrder,
        ),
      }));

  const engModules = useMemo(
    () => filterByModuleTypeAndSortBySortOrder(templateForm.modules, 'ENGINEERING'),
    [templateForm.modules],
  );

  const techModules = useMemo(
    () => filterByModuleTypeAndSortBySortOrder(templateForm.modules, 'TECHNICAL'),
    [templateForm.modules],
  );

  return (
    <Container isLoading={!templateForm}>
      <S.TemplateFormContainer>
        <S.MainFormContent>
          <StyledHeader marginLeft="0px" marginBottom="0px">
            {formTitle}
          </StyledHeader>
          <div>
            <InputLabel htmlFor="template-name">Name:</InputLabel>
            <TextField
              id="template-name"
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Enter template name"
              value={templateForm.name}
              error={nameIsNotValid}
              helperText={nameIsNotValid && 'Characters limit is 100'}
              onChange={(e) => handleTemplateName(e.target.value)}
              disabled={!user?.hasFullAccess}
            />
          </div>
          <div>
            <InputLabel htmlFor="template-position">Position:</InputLabel>
            <SelectAutocomplete
              id="template-position"
              selectedOption={templateForm.positionCategory}
              options={positionsOptions}
              onOptionChange={handleEditPosition}
              isDisabled={!user?.hasFullAccess}
            />
          </div>
        </S.MainFormContent>

        {engModules.length > 0 && (
          <TemplateModuleGroup
            moduleReadonly
            icon={<GroupIcon />}
            title="Engineering modules"
            modules={engModules}
            onModuleEdit={handleEditModuleClick}
          />
        )}
        {techModules.length > 0 && (
          <TemplateModuleGroup
            moduleReadonly={false}
            icon={<BuildIcon />}
            title="Technical modules"
            modules={techModules}
            onModuleEdit={handleEditModuleClick}
            onModuleDelete={handleDeleteModule}
          />
        )}
        <S.Actionbar>
          {user?.hasFullAccess && (
            <Button
              $secondary
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => handleOpenAddTechnicalModuleModal(techModules)}
            >
              Add technical module
            </Button>
          )}
          <Button
            $primary
            onClick={handleTemplateSubmit}
            variant="contained"
            disabled={!isSubmitActive}
            style={{ marginLeft: 'auto' }}
          >
            Save
          </Button>
        </S.Actionbar>
        {dialogForm && (
          <TemplateModuleForm
            dialogTitle={dialogTitle}
            isOpened={Boolean(dialogForm)}
            onClose={handleCloseDialog}
            dialogForm={dialogForm}
            onDialogChange={setDialogForm}
            onSubmitDialog={handleSubmitDialog}
          />
        )}
      </S.TemplateFormContainer>
    </Container>
  );
};
