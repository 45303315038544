import { ChangeEvent } from 'react';
import { Box, InputLabel } from '@mui/material';
import { CustomFeedbackProps } from './CustomFeedback.types';
import { RequiredMark } from '../OverallFeedback/OverallFeedback.css';
import * as S from './CustomFeedback.css';

export const CustomFeedback = ({
  title,
  placeholder,
  feedbackField,
  onEditFeedback,
  readOnly,
  required,
}: CustomFeedbackProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onEditFeedback(e.target.value, feedbackField.fieldName);
  };

  return (
    <S.CustomFeedbackContainer>
      <Box display="flex" flexDirection="row" alignItems="center">
        <InputLabel htmlFor={feedbackField.fieldName}>{title}</InputLabel>
        {required && !readOnly && <RequiredMark>*</RequiredMark>}
      </Box>
      <S.TextField
        id={feedbackField.fieldName}
        size="small"
        multiline
        placeholder={placeholder}
        value={feedbackField.value}
        onChange={handleChange}
        disabled={readOnly}
        maxRows={10}
      />
    </S.CustomFeedbackContainer>
  );
};
