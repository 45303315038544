export const formatStatus = (status: string): string => {
    const formattedStatus = status.toLowerCase()
    .replace(/_/g, ' ')
    .replace(/^\w/, (char) => char.toUpperCase());
    return formattedStatus;
  };

  export const reverseFormatStatus = (status: string): string => {
    const formattedStatus = status.toUpperCase().replace(/ /g, '_');
    return formattedStatus;
  };
  