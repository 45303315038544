import WarningIcon from '@mui/icons-material/Warning';
import { Box } from '@mui/material';
import { ValidationErrorProps } from './ValidationError.types';
import * as S from './ValidationError.css';

export const ValidationError = ({ text, limit }: ValidationErrorProps) => (
  <S.ValidationError>
    <WarningIcon fontSize="small" />
    <Box marginLeft="5px">{text || `Limit of characters is ${limit}`}</Box>
  </S.ValidationError>
);
