import { Typography } from '@mui/material';
import React, { MouseEvent, useState } from 'react';
import Chips from './Chips';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { ObjectType } from './types';

interface FoldableChipsProps<T extends ObjectType> {
  objects?: T[];
  chipNameExtractor?: (value: T) => string;
  numberOfTagsDisplayed: number;
  commonCellStyles: React.CSSProperties | undefined;
}

const FoldableClickableChips = <T extends ObjectType>({
  objects,
  chipNameExtractor,
  numberOfTagsDisplayed,
  commonCellStyles,
}: FoldableChipsProps<T>) => {
  const [openDialog, setOpenDialog] = useState(false);

  function handlePositionCategoriesClick(event: MouseEvent) {
    event.stopPropagation();
    if (objects?.length) {
      setOpenDialog(true);
    }
  }

  const displayedTags = objects?.slice(0, numberOfTagsDisplayed);
  const hasMoreCategories = (objects?.length ?? 0) > numberOfTagsDisplayed;

  return (
    <>
      <div
        style={commonCellStyles}
        onClick={handlePositionCategoriesClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <Chips objects={displayedTags} chipNameExtractor={chipNameExtractor} color="primary" size='small'/>
        {hasMoreCategories && <Typography gutterBottom>...</Typography>}
      </div>
      <SimpleDialog open={openDialog} onClose={() => setOpenDialog(false)} title="Tags">
        <Chips objects={objects} chipNameExtractor={chipNameExtractor} color='primary' size='medium'/>
      </SimpleDialog>
    </>
  );
};

export default FoldableClickableChips;
