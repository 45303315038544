import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

interface MultiSelectAutocompleteProps {
  options: string[];
  selectedOptions: string[];
  onOptionsChange: (options: string[]) => void;
  isDisabled?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
}

const MultiSelectAutocomplete = ({
  options,
  selectedOptions,
  onOptionsChange,
  isDisabled,
  id,
  placeholder,
  label,
}: MultiSelectAutocompleteProps) => (
  <Autocomplete
    multiple
    fullWidth
    id={id}
    disabled={isDisabled}
    options={options}
    value={selectedOptions}
    onChange={(_, value) => onOptionsChange(value)}
    size="small"
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        placeholder={placeholder ?? 'Select levels'}
      />
    )}
  />
);

export default MultiSelectAutocomplete;
