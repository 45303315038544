import React, { useState } from 'react';
import { TableProps } from './RecruitersTable.types';
import Container from 'components/Container';
import * as S from 'components/Table/Table.css';
import { GridColDef } from '@mui/x-data-grid';
import RecruiterChips from '../RecruiterChips/RecruiterChips';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { formatStatus } from '../../utils/recruiterStatusMapper';
import {
  TagsResponse,
  TechnicalRecruiter,
  TechnicalRecruiterRecruitmentPositionCategoriesDto,
} from 'api/types';
import { Typography } from '@mui/material';
import RecruiterActiveIcon from '../RecruiterActiveIcon/RecruiterActiveIcon';
import EditRecruiterModal from '../EditRecruiterModal/EditRecruiterModal';
import SimpleDialog from '../../../../components/SimpleDialog/SimpleDialog';
import FoldableClickableChips from '../../../../components/Chips/FoldableClickableChips';
import { RecruitmentsTableContainer } from '../../../Recruitments/components/RecruitmentsTable/RecruitmentsTable.css';
import { EmailAddress } from './RecruitersTable.css';

interface Dialogs {
  note: boolean;
  edit: false | TechnicalRecruiter;
  positionCategories: boolean;
}

const RecruitersTable = ({
  technicalRecruiters,
  isLoading,
  fetchRecruiters,
}: TableProps) => {
  const [openDialog, setOpenDialog] = useState<Dialogs>({
    note: false,
    edit: false,
    positionCategories: false,
  });
  const [selectedNote, setSelectedNote] = useState('');

  const handleDialogToggle = (
    dialog: 'note' | 'positionCategories' | 'edit',
    open: boolean,
  ) => setOpenDialog((prev) => ({ ...prev, [dialog]: open }));

  const handleNoteClick = (note: string) => {
    setSelectedNote(note);
    handleDialogToggle('note', true);
  };

  const handleEditClick = (row: any) => {
    handleDialogToggle('edit', row);
  };

  const renderColumnCell = (params: any) => (
    <div style={commonCellStyles}>{params.value}</div>
  );

  const renderEmailColumnCell = (params: any) => (
    <EmailAddress href={`mailto:${params.value}`} target='_blank' rel='noreferrer'>
      {params.value}
    </EmailAddress>
  );

  const renderNoteCell = (params: any) => (
    <div
      style={{ ...commonCellStyles, cursor: 'pointer' }}
      title={params.value}
      onClick={() => handleNoteClick(params.value)}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
    >
      {params.value}
    </div>
  );

  const renderIsActiveCell = (params: any) => (
    <div style={commonCellStyles}>
      <RecruiterActiveIcon active={params.value} />
    </div>
  );

  const renderStatusCell = (params: any) => (
    <div style={commonCellStyles}>{formatStatus(params.value)}</div>
  );

  const renderCategoryCell = (params: any) => (
    <FoldableClickableChips
      objects={params.row.technicalRecruiterRecruitmentPositionCategoriesDtoList}
      chipNameExtractor={(category: TechnicalRecruiterRecruitmentPositionCategoriesDto) =>
        `${category.positionCategoryName} - ${Array.from(category.supportedLevels).join(', ')}`}
      commonCellStyles={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
      }}
      numberOfTagsDisplayed={2}
    />
  );

  const commonCellStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const defaultColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 110,
      flex: 0.7,
      renderCell: renderColumnCell,
    },
    {
      field: 'surname',
      headerName: 'Surname',
      minWidth: 110,
      flex: 0.7,
      renderCell: renderColumnCell,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      flex: 0.5,
      renderCell: renderEmailColumnCell,
    },
    {
      field: 'note',
      headerName: 'Note',
      minWidth: 200,
      flex: 2,
      renderCell: renderNoteCell,
    },
    {
      field: 'categories',
      headerName: 'Position Categories',
      minWidth: 300,
      flex: 2,
      renderCell: renderCategoryCell,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: renderStatusCell,
    },
    {
      field: 'active',
      headerName: 'Is Active',
      minWidth: 80,
      maxWidth: 80,
      flex: 0.25,
      renderCell: renderIsActiveCell,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 0.25,
      minWidth: 80,
      maxWidth: 80,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const gridRows = Array.isArray(technicalRecruiters)
    ? technicalRecruiters.map(
        ({
          id,
          name,
          surname,
          email,
          note,
          status,
          technicalRecruiterRecruitmentPositionCategoriesDtoList,
          active,
        }) => ({
          id,
          name,
          surname,
          email,
          note,
          technicalRecruiterRecruitmentPositionCategoriesDtoList,
          status,
          active,
        }),
      )
    : [];

  return (
    <Container isLoading={isLoading}>
      <RecruitmentsTableContainer>
        <S.DataTable
          rows={gridRows}
          columns={defaultColumns}
          density="standard"
          hideFooter
          disableColumnFilter
          disableColumnMenu
          showColumnVerticalBorder
          rowSelection={false}
          rowHeight={60}
          sx={{
            overflow: 'auto',
            '& .MuiDataGrid-cell': { display: 'flex', alignItems: 'center' },
          }}
        />
      </RecruitmentsTableContainer>

      <SimpleDialog open={openDialog.note}
                    onClose={() => handleDialogToggle('note', false)}
                    title="Note"
      >
        {selectedNote}
      </SimpleDialog>

      {!!openDialog.edit && (
        <EditRecruiterModal
          initialValues={openDialog.edit}
          onDialogToggle={handleDialogToggle}
          onRefresh={fetchRecruiters}
        />
      )}
    </Container>
  );
};

export default RecruitersTable;
