import {
  Box,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import { createStyledIcon } from 'pages/Recruitments/components/RecruitmentsTable/RecruitmentsTable.css';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const DialogTitle = styled(Typography)`
  &.MuiTypography-root {
    align-self: start;
    font-size: 25px;
    padding: 16px 32px 0px 32px;
    text-decoration: underline ${theme.palette.primary.main};
    text-underline-offset: 8px;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 32px 16px 32px;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    padding: 0;
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const ApproveButton = styled(Button)`
  &.MuiButton-root {
    width: 123px;
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButton-root {
    width: 123px;
  }
`;

export const AddButton = createStyledIcon(AddCircleOutlineIcon);

export const RequirementsList = styled(Box)`
  overflow: auto;
`;

export const RequirementsItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.23);
  &.MuiBox-root:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
  &.MuiBox-root:focus-within {
    border-color: ${theme.palette.primary.main};
    border-width: 2px;
  }
`;
