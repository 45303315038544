import { useMemo, useState } from 'react';
import { RecruitmentService } from 'api';
import { RecruitmentRecordResponse } from 'api/types';
import { PageInfo } from 'types/template';
import { SelectOption } from 'components/SelectAutocomplete/SelectAutocomplete.types';
import {
  useHRContactsDictionary,
  usePositionCategories,
  useSeniorityDictionaryValues,
  useTechnicalRecruitersOptionsDictionary,
  useTemplateDictionary,
} from '../../../context/DictionaryContext';
import { PROVINCES } from '../components/RecruitmentForm/utils/mapProvinceToFullName';

export interface Selectable {
  id: number;
  name: string;
}

export const useGetRecruitmentsPageData = () => {
  const { recruitmentsData, getRecruitments, isLoadingRecruitmentsData } =
    RecruitmentService.useGetRecruitments();

  const recruitmentPageInfo: PageInfo = useMemo(
    () => recruitmentsData.pageInfo,
    [recruitmentsData],
  );

  const technicalRecruiters: Selectable[] = useTechnicalRecruitersOptionsDictionary();
  const hrs: Selectable[] = useHRContactsDictionary();
  const templates: Selectable[] = useTemplateDictionary();
  const seniorityLevels: SelectOption[] = useSeniorityDictionaryValues();
  const positions: SelectOption[] = usePositionCategories().map(({ name }) => ({ name }));

  const locations: Selectable[] = Object.keys(PROVINCES).map((key, index) => ({
    id: index + 1,
    name: PROVINCES[key as keyof typeof PROVINCES],
  }));

  return {
    recruitmentsData,
    hrs,
    templates,
    technicalRecruiters,
    recruitmentPageInfo,
    isLoadingRecruitmentsData,
    seniorityLevels,
    positions,
    locations,
    getRecruitments,
  };
};
