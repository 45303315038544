import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { RecruitmentService } from 'api';
import { Base } from 'types/common';
import { RecruitmentState } from 'types/recruitment';
import Container from 'components/Container';
import { ActionDialog } from 'components/Table/ActionDialog';
import * as S from 'components/Table/Table.css';
import { handleException } from 'utils/errorHandlingUtils';
import { getDateTime } from 'utils/formatters';
import { exportFile, removeExtraSpaces } from '../../../../utils/helpers';
import { getCellValue } from './utils/tableHelpers';
import { TableProps } from './RecruitmentsTable.types';
import {
  CancelButton,
  DownloadButton,
  FavouriteButton,
  NotFavouriteButton,
  StartButton, RecruitmentsTableContainer,
} from './RecruitmentsTable.css';
import { CustomToolbar } from './CustomToolbar';
import { DownloadCvButton } from '../CvDownloadButton/DownloadCvButton';
import { mapProvinceToFullName } from '../RecruitmentForm/utils/mapProvinceToFullName';
import {
  useAddCandidateToFavorites,
  useRemoveCandidateFromFavorite,
} from 'api/recruitments';
import { mapNotScoredLevelValue } from '../../../Recruitment/components/Recruitment/utils/mapNotScoredLevel';
import RecommendedIcon from './RecommendedIcons/RecommendedIcons';
import FoldableClickableChips from '../../../../components/Chips/FoldableClickableChips';
import { TagsResponse } from '../../../../api/types';

export const RecruitmentsTable = ({
  recruitmentsRecords,
  paginationOptions,
  setPaginationOptions,
  isLoadingRecruitmentsData,
  onRowClick,
  onSort,
  sortModel,
  openAddRecruitmentDialog,
  onRefetch,
}: TableProps) => {
  const { patchRecruitment, isLoadingPatchRecruitment } =
    RecruitmentService.usePatchRecruitment();
  const { createCancellation, isCancellingRecruitment } =
    RecruitmentService.useCreateCancellation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { removeCandidateFromFavorites } = useRemoveCandidateFromFavorite();
  const { addCandidateToFavorite } = useAddCandidateToFavorites();

  const [recruitmentToCancel, setRecruitmentToCancel] = useState<Base | null>(null);
  const [recruitmentToStart, setRecruitmentToStart] = useState<Base | null>(null);

  const handleOpenCancelRecruitmentDialog = (recruitment: Base) => {
    setRecruitmentToCancel(recruitment);
  };

  const { getDocxDocument, isLoadingDocxDownload } =
    RecruitmentService.useGetDocxDocument();

  const handleDownloadSummaryDoc = async (
    recruitmentId: number,
    candidateName: string,
  ) => {
    if (isLoadingDocxDownload) {
      return;
    }
    toast.info(`Downloading ${candidateName}'s recruitment started`, { autoClose: 1500 });
    const response = await getDocxDocument(recruitmentId);
    exportFile(response);
  };

  const handleCloseCancellation = () => {
    setRecruitmentToCancel(null);
  };

  const handleCloseStarting = () => {
    setRecruitmentToStart(null);
  };

  const handleStartClick = async (recId: number, candidateName: string) => {
    setRecruitmentToStart({ id: recId, name: candidateName });
  };

  const handleSubmitStart = async () => {
    if (recruitmentToStart) {
      await patchRecruitment(recruitmentToStart?.id, {
        recruitmentState: RecruitmentState.InProgress,
      });
      navigate(`/recruitment/${recruitmentToStart?.id}`);
    }
  };

  const handleToggleFavourite = async (recruitmentId: number, isFavourite: boolean) => {
    if (isFavourite) {
      removeCandidateFromFavorites(recruitmentId);
    } else {
      await addCandidateToFavorite(recruitmentId);
    }
    onRefetch();
  };

  const cancelButton = (recruitmentId: number, candidateName: string) => (
    <CancelButton
      onClick={(e) => {
        e.stopPropagation();
        handleOpenCancelRecruitmentDialog({ id: recruitmentId, name: candidateName });
      }}
    />
  );

  const downloadCvButton = (recruitmentId: number) => (
    <DownloadCvButton recruitmentId={recruitmentId} />
  );

  const startButton = (recruitmentId: number, candidateName: string) => (
    <StartButton
      onClick={(e) => {
        e.stopPropagation();
        handleStartClick(recruitmentId, candidateName);
      }}
    />
  );

  const downloadReportButton = (recruitmentId: number, candidateName: string) => (
    <DownloadButton
      onClick={(e) => {
        e.stopPropagation();
        handleDownloadSummaryDoc(recruitmentId, candidateName);
      }}
    />
  );

  const toggleFavouriteButton = (recruitmentId: number, isFavourite: boolean) =>
    isFavourite ? (
      <FavouriteButton
        onClick={(e) => {
          e.stopPropagation();
          handleToggleFavourite(recruitmentId, isFavourite);
        }}
      />
    ) : (
      <NotFavouriteButton
        onClick={(e) => {
          e.stopPropagation();
          handleToggleFavourite(recruitmentId, isFavourite);
        }}
      />
    );

  const renderStateProgress = (state: RecruitmentState) => {
    const stateMap = {
      [RecruitmentState.Created]: {
        label: 'Created',
        bgColor: 'primary',
        color: 'black',
      },
      [RecruitmentState.InProgress]: {
        label: 'In Progress',
        bgColor: 'warning',
        color: 'white',
      },
      [RecruitmentState.Completed]: {
        label: 'Completed',
        bgColor: 'success',
        color: 'white',
      },
      [RecruitmentState.Cancelled]: {
        label: 'Cancelled',
        bgColor: 'error',
        color: 'white',
      },
    };

    const mappedState = stateMap[state as RecruitmentState];

    return (
      <Chip
        label={mappedState.label}
        color={mappedState.bgColor as any}
        sx={{
          color: mappedState.color,
        }}
        size="small"
      />
    );
  };

  function getRowActionButton(
    state: RecruitmentState,
    recruitmentId: number,
    candidateName: string,
    cvUploaded: boolean,
    favourite: boolean,
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '80%',
        }}
      >
        {getButtons(state, recruitmentId, candidateName, cvUploaded, favourite)}
      </Box>
    );
  }

  function getButtons(
    state: RecruitmentState,
    recruitmentId: number,
    candidateName: string,
    cvUploaded: boolean,
    favourite: boolean,
  ) {
    switch (state) {
      case RecruitmentState.Created:
        return (
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          >
            {cvUploaded && downloadCvButton(recruitmentId)}
            {startButton(recruitmentId, candidateName)}
            {cancelButton(recruitmentId, candidateName)}
            {toggleFavouriteButton(recruitmentId, favourite)}
          </Box>
        );
      case RecruitmentState.InProgress:
        return (
          <Box display="flex" alignItems="flex-end" gap={1}>
            {cancelButton(recruitmentId, candidateName)}
            {toggleFavouriteButton(recruitmentId, favourite)}
          </Box>
        );
      case RecruitmentState.Completed:
        return (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="flex-end"
            gap={1}
          >
            {downloadReportButton(recruitmentId, candidateName)}
            {toggleFavouriteButton(recruitmentId, favourite)}
          </Box>
        );

      default:
        return null;
    }
  }

  const renderColumnCell = (params: any) => (
    <div
      style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      title=""
    >
      {params.value}
    </div>
  );

  const renderButtonColumnCell = (params: any) => (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      <RecommendedIcon recommended={params.value} />
    </div>
  );

  const renderTagsCell = (params: any) => (
    <FoldableClickableChips
      objects={params.value}
      chipNameExtractor={(tag: TagsResponse) => tag.name}
      commonCellStyles={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
      }}
      numberOfTagsDisplayed={1}
    />
  );

  const defaultColumns: GridColDef[] = [
    {
      field: 'candidateName',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      renderCell: renderColumnCell,
    },
    {
      field: 'positionCategory',
      headerName: 'Position category',
      minWidth: 150,
      flex: 1,
      renderCell: renderColumnCell,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      renderCell: renderColumnCell,
    },
    {
      field: 'seniorityLevel',
      headerName: 'Seniority ',
      minWidth: 30,
      renderCell: renderColumnCell,
    },
    {
      field: 'score',
      headerName: 'Score ',
      minWidth: 30,
      renderCell: (params) =>
        renderColumnCell({ ...params, value: mapNotScoredLevelValue(params.value) }),
    },
  ];

  const moduleBasedColumns: GridColDef[] = Array.from(
    new Map(
      recruitmentsRecords.flatMap((rec) =>
        rec.modules.map((mod) => [
          mod.name,
          {
            field: `modules.${mod.name}`,
            headerName: mod.name,
            renderCell: (params: any) => renderColumnCell({ ...params, value: mapNotScoredLevelValue(params.value) }),
          },
        ]),
      ),
    ).values(),
  ).sort((a, b) => a.field.localeCompare(b.field));

  const defaultLastColumns: GridColDef[] = [
    {
      field: 'technicalRecruiterName',
      headerName: 'Recruiter',
      minWidth: 150,
      flex: 1,
      renderCell: renderColumnCell,
    },
    {
      field: 'hrContactName',
      headerName: 'HR contact',
      minWidth: 150,
      flex: 1,
      renderCell: renderColumnCell,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 150,
      renderCell: renderColumnCell,
    },
    {
      field: 'state',
      headerName: 'Status',
      width: 110,
      renderCell: (params) => renderStateProgress(params.value),
    },
    {
      field: 'recommended',
      headerName: 'Recommended',
      minWidth: 50,
      maxWidth: 150,
      renderCell: renderButtonColumnCell,
    },
    {
      field: 'tags',
      headerName: 'Tags ',
      minWidth: 50,
      maxWidth: 150,
      sortable: false,
      renderCell: renderTagsCell,
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 0.6,
      minWidth: 170,
      sortable: false,
      renderCell: (params) => params.value,
    },
  ];

  const gridCols: GridColDef[] = [
    ...defaultColumns,
    ...moduleBasedColumns,
    ...defaultLastColumns,
  ];

  const allDistinctModuleNamesOnPage: string[] = Array.from(
    new Set(
      recruitmentsRecords.flatMap((res) => res.modules.map((module) => module.name)),
    ),
  );

  const gridRows = recruitmentsRecords.map(
    ({
      id,
      candidateName,
      positionCategory,
      date,
      seniorityLevel,
      score: totalScore,
      technicalRecruiterName,
      hrContactName,
      location,
      state,
      modules,
      cvUploaded,
      favourite,
      recommended,
      tags,
    }) => {
      // map recruitment fields
      const tableCells: Record<string, any> = {
        id,
        candidateName,
        positionCategory,
        date: getDateTime(date as string, 'yyyy-MM-dd HH:mm'),
        seniorityLevel: getCellValue(seniorityLevel),
        score: getCellValue(totalScore),
        technicalRecruiterName,
        hrContactName,
        location: mapProvinceToFullName(location),
        state,
        favourite,
        recommended,
        tags,
      };
      // map module fields
      allDistinctModuleNamesOnPage.forEach((moduleName) => {
        const module = modules.find((it) => it.name === moduleName);
        tableCells[`modules.${moduleName}`] = module ? getCellValue(module.score) : 'N/A';
      });

      modules.forEach(({ name, score: moduleScore }) => {
        tableCells[`modules.${name}`] = getCellValue(moduleScore);
      });

      tableCells.action = getRowActionButton(
        state,
        id,
        candidateName,
        cvUploaded,
        favourite,
      );

      return tableCells;
    },
  );
  //  columns invisible by default
  const isPositionCategoryFilterSelected = searchParams.has('positionName');
  const columnVisibilityModel = [...moduleBasedColumns].reduce(
    (acc, col) => ({
      ...acc,
      [col.field]: isPositionCategoryFilterSelected,
    }),
    {} as Record<string, boolean>,
  );

  const handleSubmitCancellationDialog = async (reason?: string) => {
    if (recruitmentToCancel && reason) {
      try {
        await createCancellation(recruitmentToCancel.id, removeExtraSpaces(reason));
        onRefetch();
        handleCloseCancellation();
      } catch (e) {
        handleException(e);
      }
    }
  };

  const cancelDialogMessage = (
    <>
      You&apos;re trying to cancel recruitment:
      <span style={{ fontWeight: 'bold' }}>
        <br /> {recruitmentToCancel?.name}.
      </span>
    </>
  );

  const startDialogMessage = (
    <>
      You&apos;re trying to start recruitment:
      <span style={{ fontWeight: 'bold' }}>
        <br /> {recruitmentToStart?.name}.
      </span>
    </>
  );

  return (
    <Container isLoading={isLoadingRecruitmentsData}>
      <RecruitmentsTableContainer>
        <S.DataTable
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              onAddNewClick: openAddRecruitmentDialog,
              buttonText: 'ADD RECRUITMENT',
              paginationOptions,
              onUpdatePagination: setPaginationOptions,
            },
          }}
          rows={gridRows}
          columns={gridCols}
          onRowClick={(params) => onRowClick(params.row.id, params.row.state)}
          sortingMode="server"
          onSortModelChange={onSort}
          sortModel={sortModel}
          density="standard"
          hideFooter
          disableColumnFilter
          disableColumnMenu
          showColumnVerticalBorder
          rowSelection={false}
          rowHeight={58}
          sx={{
            overflow: 'auto',
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel,
            },
          }}
        />
      </RecruitmentsTableContainer>

      {recruitmentToCancel && (
        <ActionDialog
          dialogTitle="CANCEL RECRUITMENT"
          dialogMessage={cancelDialogMessage}
          onClose={handleCloseCancellation}
          isSubmitting={isCancellingRecruitment}
          onSubmit={handleSubmitCancellationDialog}
          reasonRequired
        />
      )}

      {recruitmentToStart && (
        <ActionDialog
          dialogTitle="START RECRUITMENT"
          dialogMessage={startDialogMessage}
          onClose={handleCloseStarting}
          isSubmitting={isLoadingPatchRecruitment}
          onSubmit={handleSubmitStart}
        />
      )}
    </Container>
  );
};
