import { Typography } from '@mui/material';
import NotFound from 'assets/not-found.svg';
import * as S from './Unauthorized.css';

const Unauthorized = () => (
  <S.UnauthorizedContainer>
    <Typography variant="h3">
      You do not have permission to access this application.
    </Typography>
    <Typography variant="h3">
      Please contact with helpdesk@jit.team to change it.
    </Typography>
    <S.UnauthorizedImage src={NotFound} alt="not-found" />
  </S.UnauthorizedContainer>
);

export default Unauthorized;
