import { useState } from 'react';
import { Errors, RecruitmentForm } from '../RecruitmentForm.types';
import { defaultRecruitmentFormErrors } from '../utils/defaultRecruitmentForm';

export const useRecruitmentForm = (recruitment: RecruitmentForm) => {
  const [form, setForm] = useState<RecruitmentForm>(recruitment);
  const [errors, setErrors] = useState<Errors>(defaultRecruitmentFormErrors);

  const handleUpdateFormField = (
    fieldName: keyof RecruitmentForm,
    value: RecruitmentForm[keyof RecruitmentForm],
    isFieldValid?: boolean,
  ) => {
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: isFieldValid,
    }));
  };

  return {
    form,
    setForm,
    handleUpdateFormField,
    errors,
  };
};
