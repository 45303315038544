import { useCallback } from 'react';
import { useAxios } from './axios';
import { TemplateModule } from './types';

export const useGetDefaultModules = () => {
  const [
    { data = { defaultTemplateModules: [] }, loading: isLoadingDefaultModules },
    getDefaultModulesData,
  ] = useAxios<{
    defaultTemplateModules: TemplateModule[];
  }>({ method: 'GET', url: '/template-modules/default' }, { manual: true });

  return {
    defaultModules: data.defaultTemplateModules,
    isLoadingDefaultModules,
    getDefaultModulesData,
  };
};

export const usePatchModule = () => {
  const [{ data, loading: isLoadingPatchModules }, patchRecruitmentModuleData] = useAxios(
    { method: 'PATCH' },
    { manual: true, autoCancel: false },
  );

  const patchRecruitmentModule = useCallback(
    (id: number, dataInc: any) =>
      patchRecruitmentModuleData({
        url: `/recruitments/${id}/modules/${dataInc.id}`,
        data: dataInc,
      }),
    [patchRecruitmentModuleData],
  );

  return {
    modules: data,
    isLoadingPatchModules,
    patchRecruitmentModule,
  };
};
