import { useState } from 'react';
import { Box, Dialog, TextField } from '@mui/material';
import { Button, InputLabel } from 'styles.global';
import { ValidationError } from 'components/ValidationError';
import { removeExtraSpaces } from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import { MAX_REASON_LENGTH } from './ActionDialog.const';
import { ActionDialogProps } from './ActionDialog.types';
import * as S from './ActionDialog.css';

export const ActionDialog = ({
  onClose,
  dialogTitle,
  dialogMessage,
  canceler,
  defaultReason,
  onSubmit,
  isSubmitting,
  reasonRequired,
}: ActionDialogProps) => {
  const handleClose = useCloseWithConfirm({ onClose });

  const [reason, setReason] = useState<string>(defaultReason || '');

  const isReasonValid =
    !reasonRequired || removeExtraSpaces(reason).length < MAX_REASON_LENGTH;

  const isButtonDisabled = isSubmitting || (reasonRequired && !removeExtraSpaces(reason));

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={() =>
        handleClose(reason.trim() === defaultReason?.trim() || !reason.trim())
      }
    >
      <S.StyledDialogTitle>{dialogTitle}</S.StyledDialogTitle>
      <S.DialogContent>
        <S.Label>
          {dialogMessage}
          <Box fontSize="15px" mt="10px">
            (This action is irreversible)
          </Box>
        </S.Label>
        <S.InputTitle>{canceler && `Cancelled by: ${canceler}`}</S.InputTitle>
        {reasonRequired && (
          <>
            <InputLabel htmlFor="reason">Reason:</InputLabel>
            <TextField
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              variant="outlined"
              placeholder="Enter reason"
              size="small"
              fullWidth
              error={!isReasonValid}
            />
          </>
        )}
        {!isReasonValid && <ValidationError limit={MAX_REASON_LENGTH} />}
      </S.DialogContent>
      <S.DialogActions>
        <Button
          variant="contained"
          onClick={() =>
            handleClose(reason === defaultReason || !removeExtraSpaces(reason))
          }
        >
          Cancel
        </Button>
        <Button
          $primary
          onClick={() => onSubmit(reason)}
          disabled={isButtonDisabled}
          variant="contained"
        >
          Submit
        </Button>
      </S.DialogActions>
    </Dialog>
  );
};
