import { RadioGroup as MuiRadioGroup, Typography } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const OverallFeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export const CriteriaDisplay = styled.div`
  width: 100%;
  @media screen and (min-width: 800px) {
    flex-direction: row;
    width: 50%;
  }
`;

export const CriteriaTitle = styled(Typography)`
  &.MuiTypography-root {
    font-size: 24px;
  }
`;

export const ApprovalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  @media screen and (min-width: 800px) {
    width: 40%;
  }
  @media screen and (min-width: 550px) {
    flex-direction: row;
  }
`;

export const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    flex-direction: row;
  }
`;

export const ReasonsForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media screen and (max-width: 549px) {
    width: 100%;
  }
`;

export const ReasonsLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  @media screen and (max-width: 549px) {
    margin-top: 16px;
  }
`;

export const RequiredMark = styled(Typography)`
  color: ${theme.palette.error.main};
  &.MuiTypography-root {
    font-size: 24px;
    margin-left: 8px;
  }
`;
