import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTimeInputProps } from './DateTimeInput.types';
import { CustomTextField } from './DateTimeInput.css';

const DateTimeInput = ({
  name,
  autoFocus,
  label,
  onChange,
  value,
}: DateTimeInputProps) => (


  <DateTimePicker
    name={name}
    autoFocus={autoFocus}
    label={label}
    views={['day', 'hours', 'minutes']}
    onChange={(date: any) => {
      if (onChange) {
        onChange(date);
      }
    }}
    ampm={false}
    value={ value ? new Date(value) : null}
    format="dd.MM.yyyy, HH:mm"
    slots={{ textField: CustomTextField }}
  />
);

export default DateTimeInput;