import { useEffect } from 'react';

export enum ScrollPosition {
  start = 'start',
  end = 'end',
  center = 'center',
  nearest = 'nearest',
}

export const useNavigateToSection = <T extends any[]>(
  dependencies: T,
  sectionId: string,
  scrollPosition: ScrollPosition,
) => {
  useEffect(() => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: scrollPosition });
    }
  }, [...dependencies]);
};
