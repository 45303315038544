import { Typography } from '@mui/material';
import { TemplateModule } from '../TemplateModule';
import { TemplateModulesGroupProps } from './TemplateModuleGroup.type';
import * as S from './TemplateModuleGroup.css';

export const TemplateModuleGroup = ({
  title,
  icon,
  modules,
  moduleReadonly,
  onModuleDelete,
  onModuleEdit,
}: TemplateModulesGroupProps) => (
  <S.ModuleGroupContainer>
    <S.ModuleGroupHeader>
      {icon}
      <Typography variant="body1">{title}</Typography>
    </S.ModuleGroupHeader>
    {modules.map((mod, index) => (
      <TemplateModule
        key={index}
        onModuleDelete={onModuleDelete}
        onModuleEdit={onModuleEdit}
        module={mod}
        moduleReadOnly={moduleReadonly}
      />
    ))}
  </S.ModuleGroupContainer>
);
