import { SelectAutocomplete } from 'components/SelectAutocomplete';
import {
  SelectedOption,
  SelectOption,
} from 'components/SelectAutocomplete/SelectAutocomplete.types';
import {
  usePositionCategories,
  useSeniorityDictionaryValues,
} from 'context/DictionaryContext';
import { FilterType } from 'pages/Recruiters/RecruitersPage';
import { useMemo, useState } from 'react';
import * as S from './recruitersFilters.css';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  TextField,
} from '@mui/material';
import { FiltersMenu } from './FiltersMenu/FiltersMenu';
import {
  formatStatus,
  reverseFormatStatus,
} from 'pages/Recruiters/utils/recruiterStatusMapper';
import { handleException } from 'utils/errorHandlingUtils';
import { RecruitersPageTableQuery } from 'pages/Recruiters/RecruitersPage.types';
import { debounce } from 'lodash';
import { SEARCHBAR_DEBOUNCE_DELAY } from 'components/SearchBar/SearchBar.const';

const debounceTextFilterUpdate = debounce((fn) => fn(), SEARCHBAR_DEBOUNCE_DELAY);

export type RecruitersFilterFilledAccessor =
  | 'positionCategoryId'
  | 'level'
  | 'active'
  | 'status'
  | 'note'
  | 'name'
  | 'surname';

export interface RecruitersFilter {
  accessor: RecruitersFilterFilledAccessor;
  name: string;
  filterType: FilterType;
}

export interface RecruitersFilterInUse {
  accessor: RecruitersFilterFilledAccessor;
  value: string;
}

interface RecruitersFiltersProps {
  query: RecruitersPageTableQuery;
  onUpdateFilters: (newFilters: Partial<RecruitersPageTableQuery['filters']>) => void;
  filtersOptions: RecruitersFilter[];
}

export const RecruitersFilters = ({
  query,
  onUpdateFilters,
  filtersOptions,
}: RecruitersFiltersProps) => {
  const positionCategoryOptions = usePositionCategories();
  const seniorityOptions = useSeniorityDictionaryValues().filter((value) =>
    /L\d*/.test(value.name),
  );
  const recruiterStatusesOptions: SelectOption[] = [
    { name: 'ZERO_INTERVIEWS_CONDUCTED' },
    { name: 'ONE_INTERVIEW_CONDUCTED' },
    { name: 'WAITING_FOR_MENTOR_INTERVIEW' },
    { name: 'FULLY_ONBOARDED_RECRUITER' },
  ];

  const [optionalFilterKeysInUse, setOptionalFilterKeysInUse] = useState<
    Set<RecruitersFilterFilledAccessor>
  >(
    new Set(
      filtersOptions
        .filter((filter) => filter.filterType === FilterType.optional)
        .map((filter) => filter.accessor)
        .filter((accessor) => query.filters[accessor]),
    ),
  );

  const handleSelectOptionalFilter = (filter: RecruitersFilterInUse) => {
    setOptionalFilterKeysInUse((prev) => {
      const newSet = new Set(prev);
      newSet.add(filter.accessor);
      return newSet;
    });
  };

  const renderFilter = (filter: RecruitersFilter) => {
    switch (filter.accessor) {
      case 'positionCategoryId':
        return renderSelectableFilter(
          filter,
          positionCategoryOptions,
          getFilterSelectedValue(filter.accessor, positionCategoryOptions, 'id'),
          'id',
        );
      case 'level':
        return renderMultiSelectableFilter(
          filter,
          seniorityOptions,
          getFilterSelectedValues(filter.accessor, seniorityOptions, 'name'),
          'name',
        );
      case 'active':
        return renderCheckboxFilter(filter, filter.name);
      case 'status':
        return renderSelectableFilter(
          filter,
          recruiterStatusesOptions,
          getFilterSelectedValue(filter.accessor, recruiterStatusesOptions, 'name'),
          'name',
        );
      case 'note':
        return renderTextFilter(filter);
      case 'name':
        return renderTextFilter(filter);

      default:
        handleException(`Unkown filter type: ${filter.accessor}`);
    }
  };

  const getFilterSelectedValue = (
    filterAccessor: RecruitersFilterFilledAccessor,
    options: SelectOption[],
    fieldToUse: 'id' | 'name',
  ) => {
    const filterValue = query.filters[filterAccessor];
    const selectedOption = options.find(
      (option) => String(option[fieldToUse]) === String(filterValue),
    );
    return selectedOption ? { name: selectedOption.name, id: selectedOption.id } : null;
  };

  const deleteFilter = (filterAccessor: RecruitersFilterFilledAccessor) => {
    setOptionalFilterKeysInUse((prev) => {
      const newSet = new Set(prev);
      newSet.delete(filterAccessor);
      return newSet;
    });
    onUpdateFilters({
      [filterAccessor]: undefined,
    });
  };

  const getFilterSelectedValues = (
    filterAccessor: RecruitersFilterFilledAccessor,
    options: SelectOption[],
    fieldToUse: 'id' | 'name',
  ) => {
    const filterValue = query.filters[filterAccessor];
    if (!filterValue) return [];

    const selectedValues = filterValue.split(',').map((value) => value.trim());

    return options
      .filter((option) => selectedValues.includes(`${option[fieldToUse]}`))
      .map((option) => ({
        name: option.name,
        id: option.id,
      }));
  };

  const renderSelectableFilter = (
    filter: RecruitersFilter,
    options: SelectOption[],
    value: SelectedOption,
    fieldToUse: 'id' | 'name',
    multiSelect?: boolean,
  ) => {
    const formattedOptions = options.map((option) => ({
      ...option,
      name: formatStatus(option.name),
    }));

    const selectedFormattedValue = value ? formatStatus(value.name) : '';

    return (
      <>
        <SelectAutocomplete
          multiSelect={multiSelect}
          label={filter.name}
          options={formattedOptions}
          onOptionChange={(option) => {
            const selectedValue = option ? option[fieldToUse] : '';

            if (fieldToUse === 'name') {
              onUpdateFilters({
                [filter.accessor]: selectedValue
                  ? reverseFormatStatus(selectedValue as string)
                  : '',
              });
            } else {
              onUpdateFilters({
                [filter.accessor]: selectedValue ? String(selectedValue) : '',
              });
            }
          }}
          selectedOption={{
            name: selectedFormattedValue,
            id: value ? value.id : 0,
          }}
        />
        {filter.filterType === FilterType.optional && (
          <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
        )}
      </>
    );
  };

  const renderMultiSelectableFilter = (
    filter: RecruitersFilter,
    options: SelectOption[],
    value: SelectedOption[],
    fieldToUse: 'id' | 'name',
  ) => (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={options}
        value={value}
        getOptionLabel={(option) => option?.name ?? ''}
        onChange={(_, newValue) => {
          const formattedValues = (newValue as SelectedOption[]).map(
            (option) => option?.[fieldToUse] ?? '',
          );

          onUpdateFilters({
            [filter.accessor]: formattedValues.length ? formattedValues.join(',') : '',
          });
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" size="small" label={filter.name} />
        )}
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const renderTextFilter = (filter: RecruitersFilter) => {
    const value = query.filters[filter.accessor];

    return (
      <>
        <TextField
          fullWidth
          label={filter.name}
          variant="outlined"
          size="small"
          defaultValue={value}
          onChange={(e) => {
            debounceTextFilterUpdate(() => {
              const newValue = e.target.value;

              onUpdateFilters({
                [filter.accessor]: newValue,
              });
            });
          }}
        />
        {filter.filterType === FilterType.optional && (
          <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
        )}
      </>
    );
  };

  const renderCheckboxFilter = (filter: RecruitersFilter, label: string) => (
    <>
      <FormControlLabel
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'start',
          margin: 0,
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '0px 14px',
          borderRadius: '4px',
        }}
        labelPlacement="start"
        label={label}
        control={
          <Checkbox
            checked={query.filters[filter.accessor] === 'true'}
            onClick={() => {
              const prevState = query.filters[filter.accessor];

              if (prevState === 'true') {
                onUpdateFilters({
                  [filter.accessor]: undefined,
                });
              } else {
                onUpdateFilters({
                  [filter.accessor]: 'true',
                });
              }
            }}
          />
        }
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const defaultFilters = useMemo(
    () => filtersOptions.filter((filter) => filter.filterType === FilterType.default),
    [filtersOptions],
  );
  const optionalFilters = useMemo(
    () => filtersOptions.filter((filter) => filter.filterType === FilterType.optional),
    [filtersOptions],
  );

  const availableOptionalFilters = useMemo(
    () =>
      optionalFilters.filter((filter) => !optionalFilterKeysInUse.has(filter.accessor)),
    [optionalFilterKeysInUse, optionalFilters],
  );

  const usedOptionalFilters = useMemo(
    () =>
      optionalFilters.filter((filter) => optionalFilterKeysInUse.has(filter.accessor)),
    [optionalFilterKeysInUse, optionalFilters],
  );

  return (
    <Box display="flex" flexDirection="row" gap={2} my="16px">
      <S.RecruitmentsFiltersContainer>
        <Grid2 container spacing={2}>
          {defaultFilters.map((filter) => (
            <Grid2 key={filter.accessor} size={{ xs: 4 }}>
              <S.FilterContainer>{renderFilter(filter)}</S.FilterContainer>
            </Grid2>
          ))}
        </Grid2>

        <Grid2 container spacing={2}>
          {usedOptionalFilters.map((filter) => (
            <Grid2 key={filter.accessor} size={{ xs: 4 }}>
              <S.FilterContainer>{renderFilter(filter)}</S.FilterContainer>
            </Grid2>
          ))}
        </Grid2>
      </S.RecruitmentsFiltersContainer>
      <Box width="fit-content">
        <FiltersMenu
          options={availableOptionalFilters}
          onOptionSelect={handleSelectOptionalFilter}
        />
      </Box>
    </Box>
  );
};
