import styled from 'styled-components';
import theme from 'theme';

export const TemplateFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: keep-all;
  background: transparent;
  gap: 32px;
  @media only screen and (min-width: 1100px) {
    width: 65%;
    margin: 32px auto;
  }
`;

export const MainFormContent = styled.div`
  background-color: white;
  padding: 32px;
  margin: 0 32px;
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.p`
  font-size: 24px;
  text-decoration: underline ${theme.palette.primary.main};
  text-underline-offset: 4px;
  margin-bottom: 50px;
`;

export const Actionbar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 0px;
  margin: 0 32px;
`;
