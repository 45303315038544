import { useEffect, useState } from 'react';
import { RecruitmentFeedback } from 'api/types';
import { RecruitmentModule, RecruitmentState } from 'types/recruitment';
import { defaultFeedback } from '../../components/RecruitmentFeedback/defaultFeedback';
import { RecruitmentInProgressDto } from './recruitmentInProgress.types';
import { mapNotScoredLevelValue } from '../../utils/mapNotScoredLevel';
import { Tag } from '../../../../../../api/tags';

export const useRecruitmentInProgressForm = (
  recruitmentData: RecruitmentInProgressDto | undefined,
) => {
  const [recruitmentForm, setRecruitmentForm] = useState<
    RecruitmentInProgressDto | undefined
  >(recruitmentData);

  const handleUpdateModuleFeedback = (
    module: RecruitmentModule,
    fieldName: keyof RecruitmentModule,
    value: string | null,
  ) => {
    if (recruitmentForm) {
      setRecruitmentForm((prevState) => ({
        ...prevState,
        modules: prevState?.modules.map((mod) =>
          mod.id === module.id
            ? {
                ...mod,
                [fieldName]: value,
              }
            : mod,
        ),
      } as RecruitmentInProgressDto));
    }
  };

  const handleUpdateFeedback = (
    value: string | boolean,
    fieldName: keyof RecruitmentFeedback,
  ): void => {
    if (recruitmentForm) {
      setRecruitmentForm({
        ...recruitmentForm,
        feedbackUpdate: { ...recruitmentForm.feedbackUpdate, [fieldName]: value },
      });
    }
  };

  const handleUpdateRecruitmentState = (recruitmentState: RecruitmentState) => {
    if (recruitmentForm) {
      setRecruitmentForm({ ...recruitmentForm, recruitmentState });
    }
  };

  const handleUpdateTags = (tags: number[]) => {
    if (recruitmentForm) {
      setRecruitmentForm({ ...recruitmentForm, tags});
    }
  };

  useEffect(() => {
    if (recruitmentData) {
      setRecruitmentForm({
        ...recruitmentData,
        modules: recruitmentData.modules.map((mod) => ({
          ...mod,
          score: mapNotScoredLevelValue(mod.score ?? ''),
        })),
        feedbackUpdate: recruitmentData.feedbackUpdate
          ? recruitmentData.feedbackUpdate
          : defaultFeedback,
      });
    }
  }, [recruitmentData]);

  return {
    recruitmentForm,
    handleUpdateFeedback,
    handleUpdateModuleFeedback,
    handleUpdateRecruitmentState,
    handleUpdateTags
  };
};
