import { useEffect, useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useGetLoggedUser } from 'api/users';
import { loginRequest } from 'authConfig';
import { DictionaryContextProvider, UserContextProvider } from 'context';
import { AppRouter } from 'router';
import { Loader, LoaderContainer } from 'styles.global';
import { User } from 'types/user';
import 'fonts/fonts.css';

export const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const { loggedUser, getLoggedUser } = useGetLoggedUser();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    localStorage.removeItem('params');
  }, []);

  useEffect(() => {
    if (loggedUser) {
      const hasFullAccess: boolean = loggedUser?.roles.includes('devrel') || false;
      setUser({ ...loggedUser, hasFullAccess });
    }
  }, [loggedUser]);

  useEffect(() => {
    const getToken = () => {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
      instance.acquireTokenSilent(request).then((response) => {
        localStorage.setItem('token', response.accessToken);
        getLoggedUser();
      });
    };

    if (accounts[0] && isAuthenticated) {
      getToken();
    } else if (inProgress === InteractionStatus.None && !isAuthenticating) {
      setIsAuthenticating(true);
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.error(e);
      });
    } else if (inProgress === InteractionStatus.None && isAuthenticating) {
      setIsAuthenticating(false);
    }
  }, [isAuthenticated, instance, accounts, getLoggedUser, inProgress, isAuthenticating]);

  return (
    <div>
      {!isAuthenticated || inProgress === InteractionStatus.HandleRedirect || !user ? (
        <LoaderContainer>
          <Loader size="100px" />
        </LoaderContainer>
      ) : (
        <UserContextProvider user={user} setUser={setUser}>
          <DictionaryContextProvider>
            <AppRouter user={user} />
          </DictionaryContextProvider>
        </UserContextProvider>
      )}
    </div>
  );
};
