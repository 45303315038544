import { ModuleRequirements } from 'api/types';

export const sortModuleRequirementsBySeniorityLevel = (
  requirements: ModuleRequirements[],
  sortOrder: string[],
) => requirements.sort((a, b) => sortOrder.indexOf(a.level) - sortOrder.indexOf(b.level));

export const groupModuleRequirementsBySeniorityLevel = (
  requirements: ModuleRequirements[],
) =>
  requirements.reduce(
    (acc: { level: string; requirements: string[] }[], item: ModuleRequirements) => {
      if (!acc.some((group) => group.level === item.level)) {
        acc.push({ level: item.level, requirements: [] });
      }
      acc
        .find((group) => group.level === item.level)
        ?.requirements.push(item.requirements);
      return acc;
    },
    [],
  );
