import { IconButton, Tooltip } from '@mui/material';
import { NotRecomendedButton, RecomendedButton, RecomendedNoInformationButton } from './RecommendedIcons.css';


interface RecommendedIconProps {
  recommended: boolean | null | undefined;
}

const getIcon = (recommended: boolean | null | undefined) => {
  if (recommended === true) return <RecomendedButton />;
  if (recommended === false) return <NotRecomendedButton />;
  return <RecomendedNoInformationButton />;
};

const getTooltipText = (recommended: boolean | null | undefined) => {
  if (recommended === true) return 'Recommended';
  if (recommended === false) return 'Not Recommended';
  return 'No information about Recommendation yet';
};

export const RecommendedIcon = ({ recommended }: RecommendedIconProps) => (
  <Tooltip title={getTooltipText(recommended)}>
    <span> {}
      <IconButton disabled>
        {getIcon(recommended)}
      </IconButton>
    </span>
  </Tooltip>
);

export default RecommendedIcon;
