import { SvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export const createStyledIconWithColor = <T extends typeof SvgIcon>(
  IconComponent: T,
  color: any
) =>
  styled(IconComponent)<SvgIconProps>(() => ({
    color,
  }));

export const RecomendedButton = createStyledIconWithColor(CheckIcon, 'green');
export const NotRecomendedButton = createStyledIconWithColor(CloseIcon, 'red');
export const RecomendedNoInformationButton = createStyledIconWithColor(QuestionMarkIcon, 'black');
