import styled from 'styled-components';
import { TextField } from '@mui/material';

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    height: "40px",
  },
  "& .MuiInputLabel-root": {
    top: -8
  },
  "& .MuiInputLabel-shrink": {
    top: 0,
  }
});