import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const StyledDialogTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    align-self: center;
    margin-top: 40px;
    font-size: 25px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: ${theme.palette.primary.main};
  }
`;

export const Label = styled(Typography)`
  &.MuiTypography-root {
    align-self: center;
    font-size: 20px;
    margin: 20px 0;
    text-align: center;
  }
`;

export const DialogContent = styled(MuiDialogContent)`
  margin-left: 76px;
  margin-right: 76px; ;
`;

export const InputTitle = styled.p`
  width: 100%;
  text-align: left;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 32px;
`;

export const InputSubTitle = styled.p`
  width: 100%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 12px;
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }
  .MuiButton-root {
    width: 170px;
    font-size: large;
    height: 50px;
  }
`;
