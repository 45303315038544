import { useCallback } from 'react';
import { useAxios } from './axios';
import {
  TechnicalRecruiter,
  TechnicalRecruiterRecruitmentPositionCategoriesDto,
} from './types';
import { TechnicalRecruitersFilteringRequest } from 'utils/hooks/types';
import { RecruitersPageTableQuery } from 'pages/Recruiters/RecruitersPage.types';

export const mapFilteringQueryParamsToRequest = (
  queryParams: RecruitersPageTableQuery,
): TechnicalRecruitersFilteringRequest => ({
  positionCategoryId: queryParams.filters.positionCategoryId,
  level: queryParams.filters.level,
  active: queryParams.filters.active,
  status: queryParams.filters.status,
  note: queryParams.filters.note,
  name: queryParams.filters.name,
  surname: queryParams.filters.surname,
});

export const useGetTechnicalRecruiters = () => {
  const [
    { data, loading: isLoadingTechnicalRecruitersData },
    getTechnicalRecruitersData,
  ] = useAxios<{ technicalRecruiters: TechnicalRecruiter[] }>(
    { method: 'GET' },
    { manual: true },
  );

  const getTechnicalRecruiters = useCallback(
    (filters: TechnicalRecruitersFilteringRequest) =>
      getTechnicalRecruitersData({
        url: '/technical-recruiters',
        params: filters,
      }),
    [getTechnicalRecruitersData],
  );

  return {
    technicalRecruitersData: data,
    isLoadingTechnicalRecruitersData,
    getTechnicalRecruiters,
  };
};

export const usePutRecruiter = (rId: number) => {
  const [{ data, loading: isPuttingRecruiter }, putRecruiterData] =
    useAxios<TechnicalRecruiter>(
      { method: 'PUT', url: `/technical-recruiters/${rId}` },
      { manual: true },
    );

  const putRecruiter = async (
    positionCategoriesDtoList: TechnicalRecruiterRecruitmentPositionCategoriesDto[],
    active: boolean,
    status: string,
    note: string,
  ) => {
    await putRecruiterData({
      data: {
        positionCategoriesDtoList,
        active,
        status,
        note,
      },
    });
  };

  return {
    recruiterData: data,
    isPuttingRecruiter,
    putRecruiter,
  };
};
