import MuiCheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip as MuiTooltip } from '@mui/material';
import styled, { css } from 'styled-components';
import theme from 'theme';

export const RecruitmentContentContainer = styled.div<{ $isNarrow: boolean }>`
  ${(props) =>
    props.$isNarrow &&
    css`
      margin: auto;
    `}
`;

export const RecruitmentContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 100px;
`;

export const CheckIcon = styled(MuiCheckCircleIcon)<{ $disabled: boolean }>`
  &.MuiSvgIcon-root {
    color: ${({ $disabled }) => ($disabled ? 'grey' : theme.palette.success.main)};
  }
`;

export const Tooltip = styled(MuiTooltip)(() => ({
  [`& .MuiTooltip-tooltip`]: {
    color: '#4A4A4A',
    backgroundColor: '#EDEDED',
    maxWidth: '220px',
    fontSize: '12px',
  },
}));
