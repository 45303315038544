import { useCallback } from 'react';
import { RecruitmentsFilteringRequest } from '../utils/hooks/types';
import { useAxios } from './axios';
import { RecruitmentResponse, RecruitmentsPageResponse, RecruitmentUpsertResponse, SuggestedRecruitersResponse } from './types';
import { RecruitmentsPageTableQuery } from 'pages/Recruitments/RecruitmentsPage.types';

export const mapFilteringQueryParamsToRequest = (
  query: RecruitmentsPageTableQuery,
): RecruitmentsFilteringRequest => ({
  freeText: query.filters.freeText,
  templateName: query.filters.templateName,
  positionCategoryName: query.filters.positionName,
  hrId: query.filters.hrId,
  technicalRecruiterId: query.filters.technicalRecruiterId,
  seniorityFrom: query.filters.seniorityLevelFrom,
  seniorityTo: query.filters.seniorityLevelTo,
  scoredSeniorityFrom: query.filters.scoreFrom,
  scoredSeniorityTo: query.filters.scoreTo,
  dateFrom: query.filters.dateFrom,
  dateTo: query.filters.dateTo,
  recruitmentStates: query.filters.statuses,
  location: query.filters.location,
  favourite: query.filters.favourite,
  recommended: query.filters.recommended,
  tagIds: query.filters.tagIds,
  page: query.page,
  size: query.perPage,
  sort: query.sortField && `${query.sortField},${query.sortDirection}`,
});

export const useCreateRecruitment = () => {
  const [{ loading: isLoadingCreateRecruitment }, createRecruitment] =
    useAxios<RecruitmentUpsertResponse>(
      {
        url: `/recruitments`,
        method: 'POST',
      },
      { manual: true },
    );

  return { createRecruitment, isLoadingCreateRecruitment };
};

export const usePatchRecruitment = () => {
  const [{ loading: isLoadingPatchRecruitment }, patchRecruitmentData] =
    useAxios<RecruitmentUpsertResponse>({ method: 'PATCH' }, { manual: true });

  const patchRecruitment = useCallback(
    (id: number, data: any) => patchRecruitmentData({ url: `/recruitments/${id}`, data }),
    [patchRecruitmentData],
  );

  return { patchRecruitment, isLoadingPatchRecruitment };
};

export const useGetRecruitments = () => {
  const [
    {
      data = {
        recruitments: [],
        pageInfo: { totalElements: 0, totalPages: 1, pageNumber: 1, numberOfElements: 0 },
      },
      loading: isLoadingRecruitmentsData,
    },
    getRecruitmentsData,
  ] = useAxios<RecruitmentsPageResponse>({ method: 'GET' }, { manual: true });

  const getRecruitments = useCallback(
    (pageRequest: RecruitmentsFilteringRequest) =>
      getRecruitmentsData({
        url: '/recruitments',
        params: pageRequest,
      }),
    [getRecruitmentsData],
  );

  return {
    recruitmentsData: data,
    isLoadingRecruitmentsData,
    getRecruitments,
  };
};

export const useGetRecruitment = () => {
  const [{ data, loading: isLoadingRecruitment }, getRecruitmentAsync] =
    useAxios<RecruitmentResponse>({ method: 'GET' }, { manual: true });

  const getRecruitment = useCallback(
    (id: number) => getRecruitmentAsync({ url: `/recruitments/${id}` }),
    [getRecruitmentAsync],
  );
      return {
        recruitmentData: data,
        isLoadingRecruitment,
        getRecruitment,
      };
    };

  export const useCreateCancellation = () => {
    const [{ loading: isCancellingRecruitment }, createCancellationData] = useAxios(
      { method: 'POST' },
      { manual: true },
    );
    const createCancellation = useCallback(
      (id: number, reason: string) =>
        createCancellationData({
          url: `/recruitments/${id}/cancellations`,
          data: { reason },
        }),
      [createCancellationData],
    );
    return { createCancellation, isCancellingRecruitment };
  };

  export const useGetDocxDocument = () => {
    const [{ loading: isLoadingDocxDownload }, getDocx] = useAxios(
      {
        method: 'GET',
        responseType: 'blob',
      },
      { manual: true },
    );

    const getDocxDocument = useCallback(
      async (id: number) => {
        const url = `/documents/summary/${id}`;
        return getDocx({ url });
      },
      [getDocx],
    );

    return { isLoadingDocxDownload, getDocxDocument };
  };

  export const useGetRecruitmentCvDocument = (id: number) => {
    const [{ loading: isLoadingCv }, getCvUrl] = useAxios<string>(
      {
        method: 'GET',
        url: `/recruitments/${id}/files`,
      },
      { manual: true },
    );

    return { isLoadingCv, getCvUrl };
  };

  export const useUpdateRecruitmentCvDocument = () => {
    const [, sendCv] = useAxios({ method: 'POST' }, { manual: true });

    return useCallback(
      (id: number, data: FormData) => sendCv({ url: `/recruitments/${id}/files`, data }),
      [sendCv],
    );
  };

  export const useDeleteRecruitmentCvDocument = () => {
    const [, deleteCv] = useAxios({ method: 'DELETE' }, { manual: true });

    return (recruitmentId: number) =>
      deleteCv({
        url: `/recruitments/${recruitmentId}/files`,
      });
  };

  export const useGetSuggestedRecruiters = () => {
    const [{ data, loading: isLoadingSuggestedRecruiters }, getSuggestedRecruitersData] =
      useAxios<SuggestedRecruitersResponse>({ method: 'GET' }, { manual: true });

    const getSuggestedRecruiters = useCallback(
      (templateId: number, level: string) =>
        getSuggestedRecruitersData({
          url: '/technical-recruiters/suggested',
          params: {
            templateId,
            level,
          },
        }),
      [getSuggestedRecruitersData],
    );

    return { isLoadingSuggestedRecruiters, getSuggestedRecruiters, data };
  };

  export const useAddCandidateToFavorites = () => {
    const [{ loading: isLoadingAddToFavorites }, addCandidateToFavoritesDb] = useAxios(
      { method: 'POST' },
      { manual: true },
    );

    const addCandidateToFavorite = useCallback(
      (id: number) =>
        addCandidateToFavoritesDb({
          url: `/recruitments/${id}/favourites`,
        }),
      [addCandidateToFavoritesDb],
    );

    return { addCandidateToFavorite, isLoadingAddToFavorites };
  };

  export const useRemoveCandidateFromFavorite = () => {
    const [{ loading: isLoadingAddToFavorites }, removeCandidateFromFavoritesDb] = useAxios(
      { method: 'DELETE' },
      { manual: true },
    );

    const removeCandidateFromFavorites = useCallback(
      (id: number) =>
        removeCandidateFromFavoritesDb({
          url: `/recruitments/${id}/favourites`,
        }),
      [removeCandidateFromFavoritesDb],
    );

    return { removeCandidateFromFavorites, isLoadingAddToFavorites };
  };
