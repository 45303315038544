import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RecruitmentState } from 'types/recruitment';
import { handleException } from 'utils/errorHandlingUtils';
import { mapFilteringQueryParamsToRequest } from '../../api/recruitments';
import { RecruitmentForm as CRecruitmentForm } from './components/RecruitmentForm';
import { RecruitmentsTable } from './components/RecruitmentsTable';
import { useGetRecruitmentFormData } from './hooks/useGetRecruitmentData';
import { useGetRecruitmentsPageData } from './hooks/useGetRecruitmentsData';
import * as S from './RecruitmentsPage.css';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { RecruitmentsActionBar } from './components/RecruitmentsActionBar';
import { useRecruitmentsTableQuery } from './hooks/useRecruitmentsTableQuery';
import { PaginationOptions } from 'utils/hooks/types';

const Recruitments = () => {
  const { isLoadingRecruitment, recruitmentFormData, setRecId, getRecruitment } =
    useGetRecruitmentFormData();

  const navigate = useNavigate();

  const { tableQuery, onUpdateTableFilters, onUpdateTableQuery } =
    useRecruitmentsTableQuery();

  const {
    recruitmentsData,
    hrs,
    templates,
    technicalRecruiters,
    locations,
    isLoadingRecruitmentsData,
    getRecruitments,
  } = useGetRecruitmentsPageData();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchRecruitments = useCallback(async () => {
    const requestParams = mapFilteringQueryParamsToRequest(tableQuery);
    try {
      await getRecruitments(requestParams);
    } catch (e) {
      handleException(e);
    }
  }, [getRecruitments, tableQuery]);

  useEffect(() => {
    fetchRecruitments();
    window.scrollTo(0, 0);
  }, [fetchRecruitments]);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setRecId(undefined);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    onUpdateTableQuery({
      sortField: model[0]?.field,
      sortDirection: model[0]?.sort ?? '',
    });
  };

  const handleRecruitmentEdit = (recruitmentId: number) => {
    if (recruitmentId) {
      setRecId(recruitmentId);
      getRecruitment(recruitmentId);
      setIsDialogOpen(true);
    }
  };

  const handleRowClick = (recruitmentId: number, recruitmentState: RecruitmentState) => {
    if (recruitmentState === RecruitmentState.Created) {
      setRecId(recruitmentId);
      setIsDialogOpen(true);
      getRecruitment(recruitmentId);
    } else {
      navigate(`/recruitment/${recruitmentId}`);
    }
  };

  const handlePaginationChange = (paginationOptions: PaginationOptions) => {
    onUpdateTableQuery({
      page: paginationOptions.page,
      perPage: paginationOptions.perPage,
    });
  };

  const isEditMode: boolean = useMemo(
    () => !!recruitmentFormData?.id,
    [recruitmentFormData],
  );

  const sortModel = useMemo(
    () =>
      tableQuery.sortField
        ? [
            {
              field: tableQuery.sortField,
              sort: tableQuery.sortDirection as GridSortDirection,
            },
          ]
        : [],
    [tableQuery.sortField, tableQuery.sortDirection],
  );

  return (
    <S.RecruitmentsPageContainer>
        <RecruitmentsActionBar
          query={tableQuery}
          onUpdateFilters={onUpdateTableFilters}
        />
          <RecruitmentsTable
            isLoadingRecruitmentsData={isLoadingRecruitmentsData}
            recruitmentsRecords={recruitmentsData.recruitments}
            paginationOptions={{
              page: tableQuery.page,
              perPage: tableQuery.perPage,
              count: recruitmentsData.pageInfo.totalElements,
            }}
            setPaginationOptions={handlePaginationChange}
            hrsOptions={hrs}
            templatesOptions={templates}
            technicalRecruitersOptions={technicalRecruiters}
            onRecruitmentEdit={handleRecruitmentEdit}
            onRowClick={handleRowClick}
            onSort={handleSortModelChange}
            sortModel={sortModel}
            openAddRecruitmentDialog={handleOpenDialog}
            onRefetch={fetchRecruitments}
          />
        {isDialogOpen && !isLoadingRecruitment && (
          <CRecruitmentForm
            editMode={isEditMode}
            isOpen={isDialogOpen}
            recruitment={recruitmentFormData}
            hrsOptions={hrs}
            templatesOptions={templates}
            locationsOptions={locations}
            technicalRecruitersOptions={technicalRecruiters}
            onClose={handleCloseDialog}
            onRefetch={fetchRecruitments}
          />
        )}
    </S.RecruitmentsPageContainer>
  );
};

export default Recruitments;
