import { AxiosResponse } from 'axios';
import { handleException } from 'utils/errorHandlingUtils';

const getFilenameFromHeaders = (response: AxiosResponse<any>): string => {
  const contentDisposition = response.headers['content-disposition'];
  
  if (!contentDisposition || !contentDisposition.includes('filename=')) {
    handleException('Filename not found in Content-Disposition header');
    return 'recruitment_summary.docx';
  }
  return contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
};

export const exportFile = (response: AxiosResponse<any>, fileTitle?: string) => {
  const fileName = fileTitle || getFilenameFromHeaders(response);
  const url = window.URL.createObjectURL(new Blob([response.request.response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};


export const handleShowCv = async (response: AxiosResponse<string>) => {
  window.open(response.data, '_blank', 'noopener,noreferrer');
};