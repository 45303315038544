import { toast } from 'react-toastify';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from 'authConfig';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { makeUseAxios, Options, UseAxiosResult } from 'axios-hooks';
import { msalInstance } from 'index';
import ErrorToast from 'components/Toasts/ErrorToast';

interface SphinxErrorResponse {
  message: string;
}

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: '/api',
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
        config.headers.set('Accept', "application/json");
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response) => ({
      ...response,
      data: response.data,
    }),
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        const originalRequest = error.config;
        const account = msalInstance.getActiveAccount();
        if (account) {
          const request = {
            ...loginRequest,
            account,
            forceRefresh: false,
          };
          msalInstance
            .acquireTokenSilent(request)
            .then((response) => {
              localStorage.setItem('token', response.accessToken);
            })
            .catch(async (msalError) => {
              await msalInstance.acquireTokenRedirect(request);

              if (msalError instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                await msalInstance.acquireTokenRedirect(request);
              }
            })
            .finally(() =>
              axiosInstance.request({
                ...originalRequest,
                data: JSON.parse(originalRequest?.data),
              }),
            );
        } else {
          localStorage.removeItem('token');
          window.location.reload();
        }
        return Promise.reject(error);
      }

      if (!axios.isCancel(error) && (error as AxiosError)?.response) {
        const serverMessage = ((error as AxiosError)?.response?.data as SphinxErrorResponse).message;
        const message = serverMessage ??
          'Unknown error has occurred. Please contact Service Desk).';

        toast.error(<ErrorToast message={message} />);
      }
      if (axios.isCancel(error)) {
        return Promise
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance;
};

export const useAxios = <Data = any, >(
  config: string | AxiosRequestConfig,
  options?: Options,
): UseAxiosResult<Data, any, any> => {
  const axiosInstance = createAxiosInstance();
  const useAxiosHook = makeUseAxios({
    axios: axiosInstance,
  });
  return useAxiosHook(config, options);
};
