import { Person } from 'types/common';
import { User } from 'types/user';
import { useAxios } from './axios';
import { TechnicalRecruiter } from './types';

export const useGetTechnicalRecruiters = () => {
  const [, getTechnicalRecruitersData] = useAxios<{
    technicalRecruiters: TechnicalRecruiter[];
  }>({ url: '/technical-recruiters', method: 'GET' }, { manual: true });

  return {
    getTechnicalRecruitersData,
  };
};

export const useGetHrs = () => {
  const [, getHrsData] = useAxios<{ hrs: Person[] }>(
    { url: '/hrs' },
    { manual: true },
  );
  return { getHrsData };
};

export const useGetLoggedUser = () => {
  const [{ data = null }, getLoggedUser] = useAxios<User>(
    { url: '/user/me' },
    { manual: true },
  );

  return { loggedUser: data, getLoggedUser };
};
