import { ModuleResponse, ModuleType } from 'api/types';
import { RecruitmentInProgressDto } from '../hooks/useRecruitmentInProgressForm/recruitmentInProgress.types';

export const getRecruitmentModulesSortedBySortOrder = (
  moduleType: ModuleType,
  modules: ModuleResponse[],
): ModuleResponse[] =>
  modules
    .filter((m) => m.moduleType === moduleType)
    .sort((a, b) => (a.sortOrder ?? Infinity) - (b.sortOrder ?? Infinity)) || [];

export const checkReasonErr = (
  relatedFieldVal: boolean | null,
  reasonVal?: string | null,
): boolean => {
  if (relatedFieldVal === null) return false;
  if (relatedFieldVal === true) return true;
  if (relatedFieldVal === false && reasonVal !== undefined) {
    return !!reasonVal;
  }
  return false;
};

export const isRecFeedbackValid = (recruitment?: RecruitmentInProgressDto): boolean => {
  if (!recruitment?.feedbackUpdate) return false;

  const { feedbackUpdate: feedback } = recruitment;

  return (
    checkReasonErr(feedback.personality, feedback.personalityReason) &&
    checkReasonErr(feedback.highPersonalCulture, feedback.highPersonalCultureReason) &&
    checkReasonErr(feedback.professionalism, feedback.professionalismReason) &&
    checkReasonErr(
      feedback.willingnessToGrowAndLearn,
      feedback.willingnessToGrowAndLearnReason,
    ) &&
    checkReasonErr(
      feedback.buildingRelationships,
      feedback.buildingRelationshipsReason,
    ) &&
    checkReasonErr(feedback.recommendation, feedback.recommendationReason)
  );
};
