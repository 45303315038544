import { Box, TextField } from '@mui/material';
import { InputLabel } from 'styles.global';
import { EvaluationFormProps } from './EvaluationForm.types';
import { RequiredMark } from '../../../RecruitmentFeedback/components/OverallFeedback/OverallFeedback.css';

export const EvaluationForm = ({
  formTitle,
  formValue,
  onChange,
  formInstructions,
  readOnly,
  inputId,
  isRequiredMark = true
}: EvaluationFormProps) => (
  <Box width="100%">
    <Box display="flex" flexDirection="row" alignItems="center">
      <InputLabel htmlFor={inputId + formTitle}>{formTitle}</InputLabel>
      {(!readOnly && isRequiredMark) && <RequiredMark>*</RequiredMark>}
    </Box>
    <TextField
      id={inputId + formTitle}
      fullWidth
      multiline
      size="small"
      variant="outlined"
      placeholder={formInstructions}
      value={formValue}
      onChange={(e) => onChange(e.target.value)}
      maxRows={10}
      disabled={readOnly}
    />
  </Box>
);
