import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  TextField,
} from '@mui/material';
import {
  SelectedOption,
  SelectOption,
} from 'components/SelectAutocomplete/SelectAutocomplete.types';
import * as S from './recruitmentFilters.css';
import React, { useMemo, useState } from 'react';
import { RecruitmentState } from 'types/recruitment';
import { SelectAutocomplete } from 'components/SelectAutocomplete';
import DateInput from 'components/DateInput';
import { isValid } from 'date-fns';
import { getDate } from 'utils/formatters';
import {
  useHRContactsDictionary,
  usePositionCategories,
  useSeniorityDictionaryValues,
  useTechnicalRecruitersOptionsDictionary,
} from 'context/DictionaryContext';
import { FiltersMenu } from './FiltersMenu';
import { NotScoredLevel } from 'pages/Recruitment/components/Recruitment/utils/mapNotScoredLevel';
import { PROVINCES } from '../RecruitmentForm/utils/mapProvinceToFullName';
import { RecruitmentsPageTableQuery } from 'pages/Recruitments/RecruitmentsPage.types';
import { debounce } from 'lodash';
import { SEARCHBAR_DEBOUNCE_DELAY } from 'components/SearchBar/SearchBar.const';
import MultiSelectTagsAutoCompleteWithDynamicData from '../../../../components/MultiSelectAutoComplete/MultiSelectTagsAutoCompleteWithDynamicData';

export type RecruitmentFilterFiledAccessor =
  | 'freeText'
  | 'positionName'
  | 'technicalRecruiterId'
  | 'seniorityLevelFrom'
  | 'seniorityLevelTo'
  | 'hrId'
  | 'dateFrom'
  | 'dateTo'
  | 'statuses'
  | 'location'
  | 'favourite'
  | 'tagIds'
  | 'templateName'
  | 'recommended'
  | 'scoreFrom'
  | 'scoreTo';

const debounceTextFilterUpdate = debounce((fn) => fn(), SEARCHBAR_DEBOUNCE_DELAY);

export enum FilterType {
  optional = 'optional',
  default = 'default',
}

export interface RecruitmentFilter {
  accessor: RecruitmentFilterFiledAccessor;
  name: string;
  filterType: FilterType;
}

export interface RecruitmentFilterInUse {
  accessor: RecruitmentFilterFiledAccessor;
  value: string;
}

interface RecruitmentsFiltersProps {
  query: RecruitmentsPageTableQuery;
  onUpdateFilters: (newFilters: Partial<RecruitmentsPageTableQuery['filters']>) => void;
  filtersOptions: RecruitmentFilter[];
}

export const RecruitmentsFilters = ({
  query,
  onUpdateFilters,
  filtersOptions,
}: RecruitmentsFiltersProps) => {
  const positionNameOptions = usePositionCategories();
  const hrContactOptions = useHRContactsDictionary();
  const recruiterOptions = useTechnicalRecruitersOptionsDictionary();
  const seniorityOptions = useSeniorityDictionaryValues();
  const locationOption = Object.keys(PROVINCES).map((key) => ({
    id: key,
    name: PROVINCES[key as keyof typeof PROVINCES],
  }));

  const [optionalFilterKeysInUse, setOptionalFilterKeysInUse] = useState<
    Set<RecruitmentFilterFiledAccessor>
  >(
    new Set(
      filtersOptions
        .filter((filter) => filter.filterType === FilterType.optional)
        .map((filter) => filter.accessor)
        .filter((accessor) => query.filters[accessor]),
    ),
  );

  const handleSelectOptionalFilter = (filter: RecruitmentFilterInUse) => {
    setOptionalFilterKeysInUse((prev) => {
      const newSet = new Set(prev);
      newSet.add(filter.accessor);
      return newSet;
    });
  };

  const positiveLevelsOption = useMemo(
    () =>
      seniorityOptions.filter((level) => level.name !== NotScoredLevel.selectableValue),
    [seniorityOptions],
  );

  const renderFilter = (filter: RecruitmentFilter) => {
    switch (filter.accessor) {
      case 'freeText':
        return renderTextFilter(filter);

      case 'positionName':
        return renderSelectableFilter(
          filter,
          positionNameOptions,
          getFilterSelectedValue(filter.accessor, positionNameOptions, 'name'),
          'name',
        );

      case 'technicalRecruiterId':
        return renderSelectableFilter(
          filter,
          recruiterOptions,
          getFilterSelectedValue(filter.accessor, recruiterOptions, 'id'),
          'id',
        );

      case 'hrId':
        return renderSelectableFilter(
          filter,
          hrContactOptions,
          getFilterSelectedValue(filter.accessor, hrContactOptions, 'id'),
          'id',
        );

      case 'seniorityLevelFrom':
        return renderSelectableFilter(
          filter,
          positiveLevelsOption,
          getFilterSelectedValue(filter.accessor, positiveLevelsOption, 'name'),
          'name',
        );

      case 'seniorityLevelTo':
        return renderSelectableFilter(
          filter,
          positiveLevelsOption,
          getFilterSelectedValue(filter.accessor, positiveLevelsOption, 'name'),
          'name',
        );

      case 'dateFrom':
        return renderDateFilter(filter);

      case 'dateTo':
        return renderDateFilter(filter);

      case 'statuses':
        return renderMultiSelectableFilter(filter);

      case 'location':
        return renderSelectableFilter(
          filter,
          locationOption,
          getFilterSelectedValue(filter.accessor, locationOption, 'id'),
          'id',
        );

      case 'favourite':
        return renderCheckboxFilter(filter, 'Favourite candidate');
      case 'recommended':
        return renderCheckboxFilter(filter, 'Recommended candidate');
      case 'tagIds':
        return renderTagsFilter(filter);

      default:
        return null;
    }
  };

  const getFilterSelectedValue = (
    filterAccessor: RecruitmentFilterFiledAccessor,
    options: SelectOption[],
    fieldToUse: 'id' | 'name',
  ) => {
    const filterValue = query.filters[filterAccessor];
    const selectedOption = options.find(
      (option) => String(option[fieldToUse]) === String(filterValue),
    );
    return selectedOption ? { name: selectedOption.name, id: selectedOption.id } : null;
  };

  const getFormattedDate = (e: Date) => {
    if (isValid(e) && e !== null) {
      const dateTime = getDate(e, 'yyyy-MM-dd');
      const formattedDate = dateTime.split(' ').join('T');
      return formattedDate || '';
    }
    return '';
  };

  const deleteFilter = (filterAccessor: RecruitmentFilterFiledAccessor) => {
    setOptionalFilterKeysInUse((prev) => {
      const newSet = new Set(prev);
      newSet.delete(filterAccessor);
      return newSet;
    });
    onUpdateFilters({
      [filterAccessor]: undefined,
    });
  };

  const renderSelectableFilter = (
    filter: RecruitmentFilter,
    options: SelectOption[],
    value: SelectedOption,
    fieldToUse: 'id' | 'name',
    multiSelect?: boolean,
  ) => (
    <>
      <SelectAutocomplete
        multiSelect={multiSelect}
        label={filter.name}
        options={options}
        onOptionChange={(option) => {
          onUpdateFilters({
            [filter.accessor]: option ? option[fieldToUse] : '',
          });
        }}
        selectedOption={value}
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const renderTagsFilter = (
    filter: RecruitmentFilter,
  ) => (
    <>
      <MultiSelectTagsAutoCompleteWithDynamicData
        defaultValue={[]}
        onChange={(_, option : SelectedOption[]) => {
          onUpdateFilters({
            [filter.accessor]: option?.map(value => value?.id) ?? [],
          });
        }}
        id="select-tags"
        label="Tags"
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const renderMultiSelectableFilter = (filter: RecruitmentFilter) => {
    const selectedOptions = Object.values(RecruitmentState).filter((state) =>
      query.filters[filter.accessor]?.split(',').includes(state),
    );
    return (
      <>
        <Autocomplete
          fullWidth
          multiple
          options={Object.values(RecruitmentState)}
          value={selectedOptions}
          getOptionLabel={(option) => option as string}
          onChange={(_, newValue) => {
            onUpdateFilters({
              [filter.accessor]: (newValue as RecruitmentState[]).length
                ? (newValue as RecruitmentState[]).join(',')
                : '',
            });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" label={filter.name} />
          )}
        />
        {filter.filterType === FilterType.optional && (
          <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
        )}
      </>
    );
  };

  const renderTextFilter = (filter: RecruitmentFilter) => (
    <>
      <TextField
        fullWidth
        label={filter.name}
        variant="outlined"
        size="small"
        defaultValue={query.filters[filter.accessor]}
        onChange={(e) => {
          debounceTextFilterUpdate(() => {
            const newValue = e.target.value;

            onUpdateFilters({
              [filter.accessor]: newValue,
            });
          });
        }}
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const renderDateFilter = (filter: RecruitmentFilter) => (
    <>
      <DateInput
        label={filter.name}
        value={query.filters[filter.accessor] ?? null}
        onChange={(e: Date) => {
          const formattedDate = getFormattedDate(e);
          onUpdateFilters({
            [filter.accessor]: formattedDate,
          });
        }}
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const renderCheckboxFilter = (filter: RecruitmentFilter, label: string) => (
    <>
      <FormControlLabel
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'start',
          margin: 0,
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '0px 14px',
          borderRadius: '4px',
        }}
        labelPlacement="start"
        label={label}
        control={
          <Checkbox
            checked={query.filters[filter.accessor] === 'true'}
            onClick={() => {
              const prevState = query.filters[filter.accessor];

              if (prevState === 'true') {
                onUpdateFilters({
                  [filter.accessor]: undefined,
                });
              } else {
                onUpdateFilters({
                  [filter.accessor]: 'true',
                });
              }
            }}
          />
        }
      />
      {filter.filterType === FilterType.optional && (
        <S.DeleteFilterButton onClick={() => deleteFilter(filter.accessor)} />
      )}
    </>
  );

  const defaultFilters = useMemo(
    () => filtersOptions.filter((filter) => filter.filterType === FilterType.default),
    [filtersOptions],
  );
  const optionalFilters = useMemo(
    () => filtersOptions.filter((filter) => filter.filterType === FilterType.optional),
    [filtersOptions],
  );

  const availableOptionalFilters = useMemo(
    () =>
      optionalFilters.filter((filter) => !optionalFilterKeysInUse.has(filter.accessor)),
    [optionalFilterKeysInUse, optionalFilters],
  );

  const usedOptionalFilters = useMemo(
    () =>
      optionalFilters.filter((filter) => optionalFilterKeysInUse.has(filter.accessor)),
    [optionalFilterKeysInUse, optionalFilters],
  );

  return (
    <Box display="flex" flexDirection="row" gap={2} my="16px">
      <S.RecruitmentsFiltersContainer>
        <Grid2 container spacing={2}>
          {defaultFilters.map((filter) => (
            <Grid2 key={filter.accessor} size={{ xs: 4 }}>
              <S.FilterContainer>{renderFilter(filter)}</S.FilterContainer>
            </Grid2>
          ))}
        </Grid2>

        <Grid2 container spacing={2}>
          {usedOptionalFilters.map((filter) => (
            <Grid2 key={filter.accessor} size={{ xs: 4 }}>
              <S.FilterContainer>{renderFilter(filter)}</S.FilterContainer>
            </Grid2>
          ))}
        </Grid2>
      </S.RecruitmentsFiltersContainer>
      <Box width="fit-content">
        <FiltersMenu
          options={availableOptionalFilters}
          onOptionSelect={handleSelectOptionalFilter}
        />
      </Box>
    </Box>
  );
};
