import styled from 'styled-components';

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
  background-color: white;
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  margin: 0 32px;
`;

export const FeedbackSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 32px;
`;
