import styled from 'styled-components';

export const TemplatePageContainer = styled.div`
  height: calc(100vh - 68px);
  background-color: #ffffff;
  padding: 8px 32px;
`;

export const TemplateTableContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 68px);
  display: flex;
  flex-direction: column;
`