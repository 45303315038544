import { Actionbar } from 'pages/Recruitments/RecruitmentsPage.css';
import {
  RecruitersFilter,
  RecruitersFilters,
} from '../RecruitersFilters/RecruitersFilters';
import { FilterType } from 'pages/Recruiters/RecruitersPage';
import { RecruitersPageTableQuery } from 'pages/Recruiters/RecruitersPage.types';

interface RecruitersActionBarProps {
  query: RecruitersPageTableQuery;
  onUpdateFilters: (newFilters: Partial<RecruitersPageTableQuery['filters']>) => void;
}

export const RecruitersActionBar = ({
  query,
  onUpdateFilters,
}: RecruitersActionBarProps) => {
  const filtersOptions: RecruitersFilter[] = [
    {
      accessor: 'name',
      name: 'Name',
      filterType: FilterType.default,
    },
    {
      accessor: 'note',
      name: 'Note',
      filterType: FilterType.optional,
    },
    {
      accessor: 'positionCategoryId',
      name: 'Position category',
      filterType: FilterType.default,
    },
    {
      accessor: 'level',
      name: 'Level',
      filterType: FilterType.default,
    },
    {
      accessor: 'status',
      name: 'Status',
      filterType: FilterType.optional,
    },
    {
      accessor: 'active',
      name: 'Active',
      filterType: FilterType.optional,
    },
  ];
  return (
    <Actionbar>
      <div>
        <RecruitersFilters
          query={query}
          onUpdateFilters={onUpdateFilters}
          filtersOptions={filtersOptions}
        />
      </div>
    </Actionbar>
  );
};

export default RecruitersActionBar;
