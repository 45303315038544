import { DateInputProps } from './DateInput.types';
import * as S from './DateInput.css';

const DateInput = ({
  name,
  autoFocus,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  maxWidth,
}: DateInputProps) => (
  <S.KeyboardDatePicker
    name={name}
    autoFocus={autoFocus}
    label={label}
    onChange={(date: any) => {
      if (onChange) {
        onChange(date);
      }
    }}
    value={value ? new Date(value) : null}
    format="dd.MM.yyyy"
    sx={{
      textTransform: 'capitalize',
      width: '100%',
      maxWidth: maxWidth ? `${maxWidth}px` : 'auto',
    }}
    minDate={minDate}
    maxDate={maxDate}
    slotProps={{ textField: { size: 'small' } }}
  />
);

export default DateInput;
