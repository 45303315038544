import { Box, Dialog, TextField, Typography } from '@mui/material';
import { useUserContext } from 'context';
import { InputLabel } from 'styles.global';
import { TemplateModuleFormProps } from './TemplateModuleForm.type';
import * as S from './TemplateModuleForm.css';
import { useSeniorityDictionaryValues } from 'context/DictionaryContext';
import { CancelButton } from 'pages/Recruitments/components/RecruitmentsTable/RecruitmentsTable.css';
import { useMemo, useRef } from 'react';
import { RequirementsItem } from './components/RequirementsItem';
import { NotScoredLevel } from 'pages/Recruitment/components/Recruitment/utils/mapNotScoredLevel';

export const TemplateModuleForm = ({
  dialogTitle,
  isOpened,
  onClose,
  onSubmitDialog,
  dialogForm,
  onDialogChange,
}: TemplateModuleFormProps) => {
  const { user } = useUserContext();
  const requirementsContainerRef = useRef<HTMLDivElement>(null);

  const weightFormat = (value: string): number => {
    if (/^\d+$/.test(value) || value === '0') {
      return parseInt(value, 10);
    }
    return 0;
  };

  const handleEditModuleName = (name: string) => {
    onDialogChange({ ...dialogForm, name });
  };

  const handleEditModuleWeight = (weight: any) => {
    onDialogChange({ ...dialogForm, weight });
  };

  const handleEditRequirementSeniority = (level: string, requirementIndex: number) => {
    onDialogChange({
      ...dialogForm,
      requirements: dialogForm.requirements.map((req, index) =>
        index === requirementIndex ? { ...req, level } : req,
      ),
    });
  };

  const handleEditRequirementsValue = (
    requirements: string,
    requirementIndex: number,
  ) => {
    onDialogChange({
      ...dialogForm,
      requirements: dialogForm.requirements.map((req, index) =>
        index === requirementIndex ? { ...req, requirements } : req,
      ),
    });
  };

  const handleAddRequirements = () => {
    onDialogChange({
      ...dialogForm,
      requirements: [{ level: 'L1', requirements: '' }, ...dialogForm.requirements],
    });
    requirementsContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDeleteRequirements = (indexToRemove: number) => {
    onDialogChange({
      ...dialogForm,
      requirements: dialogForm.requirements.filter((_, index) => index !== indexToRemove),
    });
  };

  const areRequirementsValid: boolean = dialogForm.requirements.every(
    (req) => req.requirements.trim() !== '',
  );

  const seniorityLevelOptions = useSeniorityDictionaryValues();

  const positiveLevelsOption = useMemo(
    () =>
      seniorityLevelOptions.filter(
        (level) => level.name !== NotScoredLevel.selectableValue,
      ),
    [seniorityLevelOptions],
  );

  const nameIsNotValid: boolean = dialogForm.name.length > 100;
  const weightIsNotValid: boolean = dialogForm.weight <= 0 || !dialogForm.weight;

  const isEngineeringDisable: boolean =
    weightIsNotValid || !areRequirementsValid || !user?.hasFullAccess;

  const isTechnicalDisable: boolean =
    !dialogForm.name ||
    !areRequirementsValid ||
    !dialogForm.weight ||
    weightIsNotValid ||
    !user?.hasFullAccess;

  return (
    <Dialog open={isOpened} fullWidth maxWidth="md">
      <S.DialogTitle>{dialogTitle}</S.DialogTitle>
      <S.DialogContent>
        <div>
          <InputLabel htmlFor="template-module-name">Name:</InputLabel>
          <TextField
            id="template-module-name"
            fullWidth
            size="small"
            variant="outlined"
            placeholder="Enter module name"
            value={dialogForm.name}
            error={nameIsNotValid}
            helperText={nameIsNotValid && 'Characters limit is 100'}
            onChange={(e) => handleEditModuleName(e.target.value)}
            disabled={dialogForm.moduleType === 'ENGINEERING' || !user?.hasFullAccess}
          />
        </div>
        <div>
          <InputLabel htmlFor="template-module-weight">Module weight:</InputLabel>
          <TextField
            id="template-module-weight"
            fullWidth
            size="small"
            variant="outlined"
            value={dialogForm.weight}
            error={weightIsNotValid}
            helperText={weightIsNotValid && 'Weight has to be greater than 0'}
            onChange={(e) => handleEditModuleWeight(weightFormat(e.target.value))}
            disabled={!user?.hasFullAccess}
          />
        </div>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            alignItems: 'center',
            marginBottom: '-16px',
          }}
        >
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            Level:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            Requirements:
          </Typography>
          <S.AddButton onClick={handleAddRequirements} sx={{ marginLeft: 'auto' }} />
        </Box>

        <S.RequirementsList ref={requirementsContainerRef}>
          {dialogForm.requirements.map((req, index) => (
            <RequirementsItem
              key={index}
              selectedLevel={req.level}
              requirementsValue={req.requirements}
              index={index}
              levelsOptions={positiveLevelsOption}
              onLevelChange={handleEditRequirementSeniority}
              onRequirementsChange={handleEditRequirementsValue}
            >
              {user?.hasFullAccess && (
                <CancelButton onClick={() => handleDeleteRequirements(index)} />
              )}
            </RequirementsItem>
          ))}
        </S.RequirementsList>
        <S.DialogActions>
          <S.CancelButton onClick={onClose}>Cancel</S.CancelButton>
          <S.ApproveButton
            $primary
            disabled={
              dialogForm.moduleType === 'TECHNICAL'
                ? isTechnicalDisable
                : isEngineeringDisable
            }
            variant="contained"
            onClick={onSubmitDialog}
          >
            Save
          </S.ApproveButton>
        </S.DialogActions>
      </S.DialogContent>
    </Dialog>
  );
};
