import React from 'react';
import { createRoot } from 'react-dom/client';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { config } from 'authConfig';
import { ConfirmProvider } from 'material-ui-confirm';
import theme from 'theme';
import { App } from './App';

export const msalInstance = new PublicClientApplication(config);


async function initializeApp() {
  await msalInstance.initialize();
}

initializeApp().then(
  () => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const { account } = event.payload;
        msalInstance.setActiveAccount(account);
      }
    });

    msalInstance
      .handleRedirectPromise()
      .then(() => {
        const account = msalInstance.getActiveAccount();
        if (!account) {
          msalInstance.loginRedirect();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
)


const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <MsalProvider instance={msalInstance}>
    <ConfirmProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfirmProvider>
  </MsalProvider>,
);
