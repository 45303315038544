import React, { ChangeEvent, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { CircularProgress, Dialog, DialogContent, TextField } from '@mui/material';
import { RecruitmentService , TagsService } from 'api';
import { useGetSuggestedRecruiters } from 'api/recruitments';
import { useSeniorityDictionaryValues } from 'context/DictionaryContext';
import { isValid } from 'date-fns';
import { NotScoredLevel } from 'pages/Recruitment/components/Recruitment/utils/mapNotScoredLevel';
import { Button } from 'styles.global';
import DateTimeInput from 'components/DateTimeInput';
import { SelectAutocomplete } from 'components/SelectAutocomplete';
import ErrorToast from 'components/Toasts/ErrorToast';
import { ValidationError } from 'components/ValidationError';
import { handleException } from 'utils/errorHandlingUtils';
import { getDateTime } from 'utils/formatters';
import { removeExtraSpaces } from 'utils/helpers';
import { useCloseWithConfirm } from 'utils/hooks';
import { useRecruitmentForm } from './hooks/useRecruitmentForm';
import { defaultRecruitmentForm } from './utils/defaultRecruitmentForm';
import { mapFullNameToProvince } from './utils/mapProvinceToFullName';
import { mapRecruitersOptions } from './utils/mapRecruitersSuggestions';
import { CANDIDATE_MAX_LENGTH, CV_NAME_MAX_LENGTH } from './RecruitmentForm.const';
import { RecruitmentFormProps } from './RecruitmentForm.types';
import * as S from './RecruitmentForm.css';
import { TwoColumnTextInput } from './RecruitmentForm.css';
import { SelectedOption, SelectOption } from '../../../../components/SelectAutocomplete/SelectAutocomplete.types';
import MultiSelectTagsAutoCompleteWithDynamicData from '../../../../components/MultiSelectAutoComplete/MultiSelectTagsAutoCompleteWithDynamicData';

export const RecruitmentForm = ({
  editMode,
  onClose,
  isOpen,
  recruitment,
  hrsOptions,
  templatesOptions,
  technicalRecruitersOptions,
  locationsOptions,
  onRefetch,
}: RecruitmentFormProps) => {
  const deleteCvDocument = RecruitmentService.useDeleteRecruitmentCvDocument();
  const { createRecruitment, isLoadingCreateRecruitment } =
    RecruitmentService.useCreateRecruitment();
  const { patchRecruitment, isLoadingPatchRecruitment } =
    RecruitmentService.usePatchRecruitment();
  const sendCv = RecruitmentService.useUpdateRecruitmentCvDocument();
  const { getSuggestedRecruiters, data: suggestedRecruitersData } =
    useGetSuggestedRecruiters();

  const { form, errors, handleUpdateFormField } = useRecruitmentForm(
    recruitment ?? defaultRecruitmentForm,
  );

  const seniorityLevelsOptions = useSeniorityDictionaryValues();

  const positiveLevelsOption = useMemo(
    () =>
      seniorityLevelsOptions.filter(
        (level) => level.name !== NotScoredLevel.selectableValue,
      ),
    [seniorityLevelsOptions],
  );
  const [cv, setCv] = useState<File | null>(null);
  const handleClose = useCloseWithConfirm({ onClose });
  const ref = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    try {
      let response = null;

      const reqData = {
        candidateName: removeExtraSpaces(form.candidateName),
        noteFromHrToRecruiter: form.noteFromHrToRecruiter
          ? removeExtraSpaces(form.noteFromHrToRecruiter)
          : null,
        technicalRecruiterId: form.technicalRecruiter?.id,
        remuneratedTechRecruiterId: form.remuneratedTechRecruiter?.id || null,
        templateId: form.template?.id,
        seniorityLevel: form.seniorityLevel?.name,
        hrId: form.hr?.id,
        recruitmentDate: form.recruitmentDate,
        location: mapFullNameToProvince(form.location),
        tagIds: form.tags?.map(value => value?.id),
      };

      if (form.id) {
        await patchRecruitment(form.id, reqData);
      } else {
        response = await createRecruitment({ data: reqData });
      }

      if (recruitment?.cvFile && !form.cvFile && !cv && form.id) {
        await deleteCvDocument(form.id);
      }

      const id = form.id ?? response?.data.recruitment.id;
      if (cv && id) {
        const formData = new FormData();
        formData.append('cv_file', cv);
        await sendCv(id, formData);
      }

      onRefetch();
      onClose();
    } catch (e) {
      handleException(e);
    }
  };

  const handleDateChange = (e: Date) => {
    if (isValid(e) && e !== null) {
      const dateTime = getDateTime(e, 'yyyy-MM-dd HH:mm');
      const formattedDate = dateTime.split(' ').join('T');
      handleUpdateFormField('recruitmentDate', formattedDate);
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const file = Array.from(e.target.files ?? [])[0];
    if (file.type === 'application/pdf') {
      setCv(file);

      handleUpdateFormField('cvFile', file.name, file.name.length > CV_NAME_MAX_LENGTH);
    } else {
      toast.error(<ErrorToast message="Invalid file type" />);
    }
  };

  const handleGetSuggestedRecruiters = (
    templateId: number | undefined,
    level: string | undefined,
  ) => {
    if (templateId && level) {
      getSuggestedRecruiters(templateId, level);
    }
  };

  const recruitersOptions = useMemo(() => {
    if (!suggestedRecruitersData) return technicalRecruitersOptions;

    if (suggestedRecruitersData.suggestedRecruiters.length === 0) {
      return technicalRecruitersOptions;
    }

    const mappedRecruiters = [
      ...mapRecruitersOptions(
        suggestedRecruitersData.suggestedRecruiters,
        'Suggested recruiters',
      ),
      ...mapRecruitersOptions(suggestedRecruitersData.restOfRecruiters, 'Rest'),
    ];

    return mappedRecruiters.length ? mappedRecruiters : technicalRecruitersOptions;
  }, [suggestedRecruitersData, technicalRecruitersOptions]);

  const isFormFilled: boolean =
    !!form.candidateName &&
    !!form.hr &&
    !!form.recruitmentDate &&
    !!form.seniorityLevel &&
    !!form.template &&
    !!form.technicalRecruiter &&
    !!form.location;

  const isFormTouched: boolean =
    !!form.candidateName ||
    !!form.noteFromHrToRecruiter ||
    !!form.hr ||
    !!form.recruitmentDate ||
    !!form.seniorityLevel ||
    !!form.template ||
    !!form.technicalRecruiter ||
    !!form.location ||
    !!form.tags;

  const isMakingRequest: boolean =
    isLoadingCreateRecruitment || isLoadingPatchRecruitment;

  const isSubmitDisabled: boolean =
    isMakingRequest || !isFormFilled || Object.values(errors).some(Boolean);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={() => onClose}>
      <S.StyledDialogTitle>
        {editMode ? 'EDIT RECRUITMENT' : 'ADD RECRUITMENT'}
      </S.StyledDialogTitle>
      <DialogContent>
        <S.StyledFormControl>
          <TextField
            id="a"
            label="Candidate name"
            onChange={(e) =>
              handleUpdateFormField(
                'candidateName',
                e.target.value,
                form.candidateName.length > CANDIDATE_MAX_LENGTH,
              )
            }
            variant="outlined"
            size="small"
            defaultValue={form.candidateName}
            placeholder="Enter candidate"
            style={{ color: 'red' }}
            error={errors.candidateName}
          />
          <SelectAutocomplete
            label="Location"
            options={locationsOptions}
            selectedOption={
              locationsOptions.find((option) => option.name === form.location) || null
            }
            onOptionChange={(location) =>
              handleUpdateFormField('location', location?.name || null)
            }
            isDisabled={!locationsOptions.length}
            placeholder="Select location"
          />
          {errors.cvFile && form.cvFile && <ValidationError limit={CV_NAME_MAX_LENGTH} />}
          <SelectAutocomplete
            label="Template"
            options={templatesOptions}
            selectedOption={form.template}
            onOptionChange={(template) => {
              handleUpdateFormField('template', template);
              handleGetSuggestedRecruiters(
                Number(template?.id),
                form.seniorityLevel?.name,
              );
            }}
            isDisabled={!templatesOptions.length}
            placeholder="Select template"
          />

          <SelectAutocomplete
            label="Level"
            options={positiveLevelsOption}
            selectedOption={form.seniorityLevel}
            onOptionChange={(level) => {
              handleUpdateFormField('seniorityLevel', level);
              handleGetSuggestedRecruiters(Number(form.template?.id), level?.name);
            }}
            isDisabled={!positiveLevelsOption.length}
            placeholder="Select seniority level"
          />

          <DateTimeInput
            label="Recruitment date and time"
            name="recruitmentDate"
            onChange={handleDateChange}
            value={form.recruitmentDate}
          />

          <SelectAutocomplete
            label="HR contact"
            options={hrsOptions}
            selectedOption={form.hr}
            onOptionChange={(hr) => handleUpdateFormField('hr', hr)}
            isDisabled={!hrsOptions.length}
            placeholder="Select hr contact"
          />

          <SelectAutocomplete
            label="Technical recruiter"
            options={recruitersOptions}
            selectedOption={form.technicalRecruiter}
            onOptionChange={(rec) => handleUpdateFormField('technicalRecruiter', rec)}
            isDisabled={!recruitersOptions.length}
            placeholder="Select technical recruiter"
          />

          <SelectAutocomplete
            label="Remunerated recruiter"
            options={technicalRecruitersOptions}
            selectedOption={form.remuneratedTechRecruiter}
            onOptionChange={(rec) =>
              handleUpdateFormField('remuneratedTechRecruiter', rec)
            }
            isDisabled={!technicalRecruitersOptions.length}
            placeholder="Select remunerated recruiter"
          />

          <TwoColumnTextInput
            label="Note"
            id="note"
            multiline
            minRows={2}
            onChange={(e) =>
              handleUpdateFormField('noteFromHrToRecruiter', e.target.value)
            }
            variant="outlined"
            size="small"
            defaultValue={form.noteFromHrToRecruiter}
            placeholder="Note about recruitment"
            style={{ color: 'red' }}
            error={errors.noteFromHrToRecruiter}
          />
          <MultiSelectTagsAutoCompleteWithDynamicData
            enableAddingNewTags
            defaultValue={form.tags}
            onChange={(e, value: SelectedOption[]) => handleUpdateFormField('tags', value)}
            id="select-tags"
            label="Tags"
          />
          {errors.candidateName && <ValidationError limit={CANDIDATE_MAX_LENGTH} />}
          <S.CVContainer>
            {!form.cvFile ? (
              <>
                <input
                  type="file"
                  accept=".pdf"
                  ref={ref}
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
                <Button
                  variant="contained"
                  startIcon={<AttachFileIcon />}
                  onClick={() => ref.current?.click()}
                >
                  Attach CV file (.pdf)
                </Button>
              </>
            ) : (
              <S.CVName>
                <>
                  <AttachFileIcon /> {form.cvFile}
                </>
              </S.CVName>
            )}
            <S.CVTextContainer>
              {form.cvFile ? (
                <S.CloseIcon
                  onClick={() => handleUpdateFormField('cvFile', null, false)}
                />
              ) : null}
            </S.CVTextContainer>
          </S.CVContainer>
        </S.StyledFormControl>
      </DialogContent>
      <S.StyledDialogActions>
        <Button onClick={() => handleClose(!isFormTouched)} disabled={isMakingRequest}>
          Cancel
        </Button>
        <Button
          $primary
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          endIcon={
            isMakingRequest ? (
              <CircularProgress style={{ color: 'black' }} size="16px" />
            ) : (
              ''
            )
          }
          variant="contained"
        >
          {editMode ? 'Save' : 'Create'}
        </Button>
      </S.StyledDialogActions>
    </Dialog>
  );
};
