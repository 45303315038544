import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { useSeniorityDictionaryValues } from 'context/DictionaryContext';
import { InputLabel } from 'styles.global';
import { SelectAutocomplete } from 'components/SelectAutocomplete';
import { SelectedOption } from 'components/SelectAutocomplete/SelectAutocomplete.types';
import { StyledHeader } from 'components/StyledHeader/StyledHeader.css';
import { EvaluationForm } from './components/EvaluationForm';
import { RecruitmentModuleProps } from './RecruitmentModule.type';
import * as A from '../../../../../../components/Accordion/Accordion.css';
import { RequiredMark } from '../RecruitmentFeedback/components/OverallFeedback/OverallFeedback.css';
import * as S from './RecruitmentModule.css';
import {
  groupModuleRequirementsBySeniorityLevel,
  sortModuleRequirementsBySeniorityLevel,
} from 'pages/Templates/components/TemplateForm/utils/utils';
import { NotScoredLevel } from '../../utils/mapNotScoredLevel';

export const RecruitmentModule = ({
  recruitmentModule,
  readOnly,
  handleUpdateModuleFeedback,
}: RecruitmentModuleProps) => {
  const seniorityLevels = useSeniorityDictionaryValues();

  const handleEditWeakness = (value: string): void => {
    handleUpdateModuleFeedback(recruitmentModule, 'candidateWeaknesses', value);
  };

  const handleEditStrength = (value: string): void => {
    handleUpdateModuleFeedback(recruitmentModule, 'candidateStrengths', value);
  };

  const handleEditComment = (value: string): void => {
    handleUpdateModuleFeedback(recruitmentModule, 'comment', value);
  };

  const handleEditSeniority = (value: SelectedOption): void => {
    if (value) {
      handleUpdateModuleFeedback(recruitmentModule, 'score', value.name);
    }
  };

  const sortedRequirements = sortModuleRequirementsBySeniorityLevel(
    recruitmentModule.requirements,
    seniorityLevels.map((level) => level.name),
  );

  const groupedRequirements = groupModuleRequirementsBySeniorityLevel(sortedRequirements);
  return (
    <A.Accordion defaultExpanded>
      <A.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <A.AccordionHeader>
          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: 'large' }}>
            {recruitmentModule.name}
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 'lighter' }}>
            weight: {recruitmentModule.weight}
          </Typography>
        </A.AccordionHeader>
        {recruitmentModule.score && (
          <S.ModuleScore variant="body1">
            Module score:{' '}
            <span style={{ fontWeight: 'bold' }}>{recruitmentModule.score}</span>
          </S.ModuleScore>
        )}
      </A.AccordionSummary>
      <A.AccordionBodyContent>
        <Box display="flex" flexDirection="column" flex={2}>
          <StyledHeader
            marginLeft="0"
            fontSize="20px"
            marginTop="32px"
            marginBottom="16px"
            variant="body1"
          >
            Requirements
          </StyledHeader>
          {groupedRequirements.map((reqGroup, index) => (
            <Box key={index}>
              <Typography variant="body1">{reqGroup.level}</Typography>
              <ul>
                {reqGroup.requirements.map((req, reqIndex) => (
                  <li key={reqIndex}>
                    <S.StyledPreview>{req}</S.StyledPreview>
                  </li>
                ))}
              </ul>
            </Box>
          ))}
        </Box>

        <S.FeedbackContainer>
          <S.FeedbackContent>
            <StyledHeader
              marginLeft="0"
              fontSize="20px"
              marginTop="32px"
              marginBottom="-8px"
              variant="body1"
            >
              Feedback
            </StyledHeader>
            <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <InputLabel htmlFor={`${recruitmentModule.id}-score-select`}>
                  Score module
                </InputLabel>
                {!readOnly && <RequiredMark>*</RequiredMark>}
              </Box>
              <SelectAutocomplete
                id={`${recruitmentModule.id}-score-select`}
                fullWidth
                selectedOption={{
                  name: recruitmentModule.score ?? '',
                }}
                options={seniorityLevels || []}
                onOptionChange={handleEditSeniority}
                isDisabled={readOnly}
              />
            </Box>
          </S.FeedbackContent>
          <S.FeedbackContent>
            {recruitmentModule.score === NotScoredLevel.selectableValue ? (
              <EvaluationForm
                isRequiredMark={false}
                inputId={`${recruitmentModule.id}`}
                readOnly={readOnly}
                formTitle="Reason"
                formValue={recruitmentModule.comment ? recruitmentModule.comment : ''}
                onChange={handleEditComment}
                formInstructions="Fill in with reason"
              />
            ) : (
              <>
                <EvaluationForm
                  inputId={`${recruitmentModule.id}`}
                  readOnly={readOnly}
                  formTitle="+ Candidate strengths"
                  formValue={
                    recruitmentModule.candidateStrengths
                      ? recruitmentModule.candidateStrengths
                      : ''
                  }
                  onChange={handleEditStrength}
                  formInstructions="Fill in with candidate's strengths"
                />
                <EvaluationForm
                  inputId={`${recruitmentModule.id}`}
                  readOnly={readOnly}
                  formTitle="- Candidate weaknesses"
                  formValue={
                    recruitmentModule.candidateWeaknesses
                      ? recruitmentModule.candidateWeaknesses
                      : ''
                  }
                  onChange={handleEditWeakness}
                  formInstructions="Fill in with candidate's weaknesses"
                />
              </>
            )}
          </S.FeedbackContent>
        </S.FeedbackContainer>
      </A.AccordionBodyContent>
    </A.Accordion>
  );
};
