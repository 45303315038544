import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { TemplateService } from 'api';
import { Base } from 'types/common';
import Container from 'components/Container';
import { ActionDialog } from 'components/Table/ActionDialog';
import { handleException } from 'utils/errorHandlingUtils';
import { TemplatesTableProps } from './TemplatesTable.types';
import * as S from '../../../../components/Table/Table.css';
import {
  CancelButton,
  DownloadButton,
} from 'pages/Recruitments/components/RecruitmentsTable/RecruitmentsTable.css';
import { CustomToolbar } from '../../../Recruitments/components/RecruitmentsTable/CustomToolbar';
import { toast } from 'react-toastify';
import { exportFile } from 'utils/helpers';
import { TemplateTableContainer } from '../../TemplatesPage.css';

export const TemplatesTable = ({
  isLoadingTemplatesData,
  templates,
  onAddNewTemplateClick,
  onRefetch,
}: TemplatesTableProps) => {
  const navigate = useNavigate();

  const { deleteTemplate, isDeletingTemplate } = TemplateService.useDeleteTemplate();
  const { isLoadingDocxDownload, getDocxDocument } = TemplateService.useGetTemplateDocx();
  const [templateToRemove, setTemplateToRemove] = useState<Base | null>(null);

  const handleEitClick = (templateId: number) => {
    navigate(`/edit-template/${templateId}`);
  };

  const handleDialogOpen = (tempToRemove: Base) => {
    setTemplateToRemove(tempToRemove);
  };

  const handleDialogClose = () => {
    setTemplateToRemove(null);
  };

  const handleDeleteTemplate = async () => {
    if (templateToRemove) {
      try {
        await deleteTemplate(templateToRemove.id);
        setTemplateToRemove(null);
        onRefetch();
      } catch (e) {
        handleException(e);
      }
    }
  };

  const handleDownloadTemplateDoc = async (templateId: number, templateName: string) => {
    if (isLoadingDocxDownload) {
      return;
    }
    toast.info(`Downloading ${templateName}'s template started`, { autoClose: 1500 });
    const response = await getDocxDocument(templateId);
    exportFile(response);
  };

  const getRowActionButton = (templateId: number, templateName: string) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '80%',
      }}
      gap={1}
    >
      <CancelButton
        onClick={(e) => {
          e.stopPropagation();
          handleDialogOpen({ id: templateId, name: templateName });
        }}
      />
      <DownloadButton
        onClick={(e) => {
          e.stopPropagation();
          handleDownloadTemplateDoc(templateId, templateName);
        }}
      />
    </Box>
  );
  const gridCols: GridColDef[] = [
    { field: 'name', headerName: 'Template name', flex: 1 },
    { field: 'positionCategory', headerName: 'Position', flex: 1 },
    { field: 'createdAt', headerName: 'Added date', flex: 1 },
    { field: 'updatedAt', headerName: 'Last update', flex: 1 },
    { field: 'createdBy', headerName: 'Author', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      minWidth: 50,
      renderCell: (params) => params.value,
    },
  ];

  const gridRows = templates.map(
    ({ id, name, positionCategory, createdAt, createdBy, updatedAt }) => {
      const tableCells: Record<string, any> = {
        id,
        name,
        positionCategory: positionCategory.name,
        createdAt,
        updatedAt,
        createdBy,
      };

      tableCells.action = getRowActionButton(id, name);

      return tableCells;
    },
  );

  const cancelDialogMessage = (
    <>
      You&apos;re trying to delete template:
      <span style={{ fontWeight: 'bold' }}>
        <br />
        {templateToRemove?.name}.
      </span>
    </>
  );

  return (
    <Container isLoading={isLoadingTemplatesData}>
      <TemplateTableContainer>
        <S.DataTable
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              onAddNewClick: onAddNewTemplateClick,
              buttonText: 'ADD TEMPLATE',
            },
          }}
          rows={gridRows}
          columns={gridCols}
          onRowClick={(params) => handleEitClick(params.row.id)}
          sx={{
            overflow: 'auto',
            '& .MuiDataGrid-cell': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          density="standard"
          hideFooter
          disableColumnSorting
          disableColumnFilter
          disableColumnMenu
          showColumnVerticalBorder
        />
      </TemplateTableContainer>

      {templateToRemove && (
        <ActionDialog
          dialogTitle="DELETE TEMPLATE"
          dialogMessage={cancelDialogMessage}
          onClose={handleDialogClose}
          isSubmitting={isDeletingTemplate}
          onSubmit={handleDeleteTemplate}
        />
      )}
    </Container>
  );
};
