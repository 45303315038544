import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    padding: 0px 0;
    /* margin-top:0; prevents moving down on expend accordion */
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
  }
  & .MuiAccordionSummary-root {
    padding: 0;
    padding-right: 24px;
  }

  & .MuiAccordion-heading {
  }
`;

export const AccordionBodyContent = styled.div`
  padding: 0 32px 32px 32px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  & .MuiAccordionSummary-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
    margin: 0 !important;
  }

  &.MuiButtonBase-root {
    min-height: auto !important;
  }

  @media screen and (min-width: 560px) {
    & .MuiAccordionSummary-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }
  }
`;

interface AccordionHeaderProps {
  $secondary?: boolean;
}

export const AccordionHeader = styled.div<AccordionHeaderProps>`
  font-size: large;
  overflow-wrap: break-word;
  margin: 0;
  font-weight: bold;
  padding: 16px 32px;
  background-color: ${({ $secondary }) =>
    $secondary ? 'black' : theme.palette.primary.main};
  color: ${({ $secondary }) => ($secondary ? 'white' : 'black')};

  clip-path: polygon(0 0, 93% 0%, 90% 120%, 0% 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  max-width: 250px;
`;
