import { Box } from '@mui/material';
import styled from 'styled-components';
import { createStyledIcon } from '../RecruitmentsTable/RecruitmentsTable.css';
import ClearIcon from '@mui/icons-material/Clear';

export const RecruitmentsFiltersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const FilterContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;
`;

export const DeleteFilterButton = createStyledIcon(ClearIcon);
