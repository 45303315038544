import { ActiveIcon, InActiveIcon } from './RecruiterActiveAction.css';
import { IconButton } from '@mui/material';

interface RecruiterActiveIconProps {
  active: boolean;
}

export const RecruiterActiveIcon = ({ active }: RecruiterActiveIconProps) => (
  <IconButton disabled>
    {active ? <ActiveIcon /> : <InActiveIcon />}
  </IconButton>
);

export default RecruiterActiveIcon;
