import { useCallback } from 'react';
import { useAxios } from './axios';
import { Position, PositionsFilter } from './types';

export const useGetPositions = () => {
  const [
    { data = { positionCategories: [] }, loading: isLoadingPositions },
    getPositionData,
  ] = useAxios<{
    positionCategories: Position[];
  }>({ method: 'GET' }, { manual: true });

  const getPositions = useCallback(
    ({ sortOrder = 'asc', sortByName = '' }: PositionsFilter = {}) =>
      getPositionData({
        url: '/position-categories',
        params: {
          ...(sortOrder && { sortOrder }),
          ...(sortByName && { sortByName }),
        },
      }),
    [getPositionData],
  );

  return { positionsData: data.positionCategories, isLoadingPositions, getPositions };
};
