import styled from 'styled-components';

export const RecruitmentsPageContainer = styled.div`
  background-color: #ffffff;
  height: calc(100vh - 68px);
  padding: 8px 32px;
`;

export const Actionbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
