import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { SelectAutocomplete } from 'components/SelectAutocomplete';
import { SelectOption } from 'components/SelectAutocomplete/SelectAutocomplete.types';
import {
  formatStatus,
  reverseFormatStatus,
} from 'pages/Recruiters/utils/recruiterStatusMapper';
import {
  TechnicalRecruiter,
  TechnicalRecruiterRecruitmentPositionCategoriesDto,
} from 'api/types';
import { useState } from 'react';
import { RecruiterService } from 'api';
import EditRecruiterModalCategories from './EditRecruiterModalCategories';

interface EditState {
  id: number;
  note: string;
  status: string | null;
  categories: TechnicalRecruiterRecruitmentPositionCategoriesDto[];
  active: boolean;
}

interface EditRecruiterModalProps {
  initialValues?: TechnicalRecruiter;
  onDialogToggle: (dialog: 'note' | 'positionCategories' | 'edit', open: boolean) => void;
  onRefresh: () => void;
}

const EditRecruiterModal = ({
  onDialogToggle,
  onRefresh,
  initialValues,
}: EditRecruiterModalProps) => {
  const [editState, setEditState] = useState<EditState>({
    id: initialValues?.id || 0,
    note: initialValues?.note || '',
    status: initialValues?.status || null,
    categories:
      initialValues?.technicalRecruiterRecruitmentPositionCategoriesDtoList || [],
    active: initialValues?.active || false,
  });
  const [editFormTouched, setEditFormTouched] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { putRecruiter } = RecruiterService.usePutRecruiter(editState.id);

  const handleEditChange = (field: string, value: any) =>
    setEditState((prev) => ({ ...prev, [field]: value }));

  const handleSaveClick = async () => {
    if (editState.status) {
      await putRecruiter(
        editState.categories,
        editState.active,
        reverseFormatStatus(editState.status),
        editState.note,
      );
    }

    onRefresh();
    onDialogToggle('edit', false);
  };

  const handleUnsavedChangesConfirmation = async (shouldClose: boolean) => {
    setUnsavedChanges(false);
    if (shouldClose) {
      onDialogToggle('edit', false);
    }
  };

  const recruiterStatuses: SelectOption[] = [
    { name: formatStatus('ZERO_INTERVIEWS_CONDUCTED') },
    { name: formatStatus('ONE_INTERVIEW_CONDUCTED') },
    { name: formatStatus('WAITING_FOR_MENTOR_INTERVIEW') },
    { name: formatStatus('FULLY_ONBOARDED_RECRUITER') },
  ];

  return (
    <>
      <Dialog open onClose={() => onDialogToggle('edit', false)} fullWidth>
        <DialogTitle>Edit recruiter</DialogTitle>
        <DialogContent>
          <Box marginBottom={2}>
            <TextField
              autoFocus
              margin="dense"
              label="Note"
              type="text"
              fullWidth
              multiline
              size="small"
              minRows={2}
              value={editState.note}
              onChange={(e) => {
                setEditFormTouched(true);
                handleEditChange('note', e.target.value);
              }}
            />
          </Box>
          <Box marginBottom={2}>
            <SelectAutocomplete
              fullWidth
              options={recruiterStatuses}
              selectedOption={
                editState.status ? { name: formatStatus(editState.status) } : null
              }
              onOptionChange={(option) => {
                setEditFormTouched(true);
                handleEditChange('status', option?.name);
              }}
              id="recruiter-status"
              label="Status"
            />
          </Box>
          <Box marginBottom={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editState.active}
                  onChange={(e) => {
                    setEditFormTouched(true);
                    handleEditChange('active', e.target.checked);
                  }}
                />
              }
              label="Active"
            />
          </Box>
          <EditRecruiterModalCategories
            categories={editState.categories}
            onUpdate={(categories) => {
              setEditFormTouched(true);
              handleEditChange('categories', categories);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            marginTop={5}
            paddingLeft={2}
            paddingRight={2}
            paddingBottom={2}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (editFormTouched) {
                  setUnsavedChanges(true);
                  return;
                }
                onDialogToggle('edit', false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSaveClick} color="primary">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={unsavedChanges} onClose={() => setUnsavedChanges(false)}>
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Do you want to continue without saving them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleUnsavedChangesConfirmation(false)}
            color="secondary"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => handleUnsavedChangesConfirmation(true)}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditRecruiterModal;
