import { Autocomplete, TextField } from '@mui/material';
import { SelectAutocompleteProps } from './SelectAutocomplete.types';

export const SelectAutocomplete = ({
  options,
  selectedOption,
  onOptionChange,
  isDisabled,
  id,
  placeholder,
  maxWidth,
  label,
}: SelectAutocompleteProps) => (
  <Autocomplete
    fullWidth
    style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'auto' }}
    id={id}
    disabled={isDisabled}
    getOptionLabel={(option) => option.name}
    onChange={(_, value) => onOptionChange(value)}
    value={selectedOption}
    options={options}
    filterSelectedOptions
    noOptionsText="No options"
    groupBy={(option) => option.group ?? ''}
    renderInput={(params) => (
      <TextField
        label={label}
        {...params}
        variant="outlined"
        placeholder={placeholder ?? 'Start typing or select'}
        size="small"
        style={{ whiteSpace: 'nowrap' }}
        disabled={isDisabled}
      />
    )}
  />
);
