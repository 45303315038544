import { ChangeEvent, MouseEvent } from 'react';
import TablePagination from '@mui/material/TablePagination';
import { PaginationProps } from './Pagination.types';

const Pagination = ({ paginationOptions, onUpdatePagination }: PaginationProps) => {
  const handleChangePage = (_: MouseEvent<HTMLButtonElement> | null, page: number) => {
    onUpdatePagination({
      ...paginationOptions,
      page,
    });
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const perPage = parseInt(event.target.value, 10);

    onUpdatePagination({
      ...paginationOptions,
      perPage,
    });
  };

  return (
    <TablePagination
      component="div"
      count={paginationOptions.count}
      page={paginationOptions.page}
      onPageChange={handleChangePage}
      rowsPerPage={paginationOptions.perPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[25, 50, 75, 100]}
    />
  );
};

export default Pagination;
