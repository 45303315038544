import { ChangeEvent, useMemo } from 'react';
import {
  Box,
  FormControlLabel,
  InputLabel,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { OverallFeedbackProps, OverallFeedbackSelect } from './OverallFeedback.types';
import * as S from './OverallFeedback.css';

export const OverallFeedback = ({
  title,
  description,
  criteria,
  approved,
  notApprovedReasons,
  onEditFeedback,
  readOnly,
}: OverallFeedbackProps) => {
  const handleEditSelect = (e: ChangeEvent<HTMLInputElement>): void => {
    const feedbackApproved: boolean = e.target.value === OverallFeedbackSelect.Approved;
    onEditFeedback(feedbackApproved, approved.fieldName);
  };

  const handleEditReason = (e: ChangeEvent<HTMLInputElement>): void => {
    onEditFeedback(e.target.value, notApprovedReasons.fieldName);
  };

  const radioValue: OverallFeedbackSelect | null = useMemo(() => {
    if (approved.value === null) return null;
    return approved.value
      ? OverallFeedbackSelect.Approved
      : OverallFeedbackSelect.NotApproved;
  }, [approved.value]);

  return (
    <S.OverallFeedbackContainer>
      <S.CriteriaDisplay>
        <Box display="flex" flexDirection="row" alignItems="end">
          <S.CriteriaTitle variant="body1">{title}</S.CriteriaTitle>
          {!readOnly && <S.RequiredMark>*</S.RequiredMark>}
        </Box>
        <Typography variant="body1">{description}</Typography>
        <ul>
          {criteria.map((crit, index) => (
            <li key={index}>{crit}</li>
          ))}
        </ul>
      </S.CriteriaDisplay>
      <S.ApprovalContainer>
        <S.RadioGroup value={radioValue} onChange={handleEditSelect}>
          <FormControlLabel
            value={OverallFeedbackSelect.Approved}
            control={<Radio size="small" color="primary" />}
            label="Yes"
            labelPlacement="bottom"
            disabled={readOnly}
          />
          <FormControlLabel
            value={OverallFeedbackSelect.NotApproved}
            control={<Radio size="small" color="primary" />}
            label="No"
            labelPlacement="bottom"
            disabled={readOnly}
          />
        </S.RadioGroup>
        {approved.value === false && (
          <S.ReasonsForm>
            <S.ReasonsLabel>
              <InputLabel
                style={{ color: 'black' }}
                htmlFor={notApprovedReasons.fieldName}
              >
                Reason
              </InputLabel>
              {!readOnly && <S.RequiredMark>*</S.RequiredMark>}
            </S.ReasonsLabel>
            <TextField
              id={notApprovedReasons.fieldName}
              size="small"
              fullWidth
              multiline
              onChange={handleEditReason}
              value={notApprovedReasons.value}
              variant="outlined"
              placeholder="My reason"
              disabled={readOnly}
            />
          </S.ReasonsForm>
        )}
      </S.ApprovalContainer>
    </S.OverallFeedbackContainer>
  );
};
