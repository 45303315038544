import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { SvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../theme';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

export const createStyledIcon = <T extends typeof SvgIcon>(IconComponent: T) =>
  styled(IconComponent)<SvgIconProps>(() => ({
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }));

// Export styled icons
export const StartButton = createStyledIcon(PlayCircleOutlineIcon);
export const CancelButton = createStyledIcon(DeleteIcon);
export const DownloadButton = createStyledIcon(DownloadIcon);
export const FavouriteButton = createStyledIcon(FavoriteIcon);
export const NotFavouriteButton = createStyledIcon(FavoriteBorderIcon);

export const RecruitmentsTableContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 149px);
  display: flex;
  flex-direction: column;
`
