import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RecruitersPageTableQuery } from '../RecruitersPage.types';

export function useRecruitersTableQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableQuery, setTableQuery] = useState<RecruitersPageTableQuery>(
    getInitialState(searchParams),
  );

  const handleUpdateTableFilters = (
    newFiltersQuery: Partial<RecruitersPageTableQuery['filters']>,
  ) => {
    const newQueryObject: RecruitersPageTableQuery = {
      ...tableQuery,
      filters: {
        ...tableQuery.filters,
        ...newFiltersQuery,
      },
    };

    setTableQuery(newQueryObject);
    setSearchParams(injectedQueryParams(newQueryObject));
  };

  return {
    tableQuery,
    onUpdateTableFilters: handleUpdateTableFilters,
  };
}

const injectedQueryParams = (newQuery: RecruitersPageTableQuery) => {
  const newSearchParams = new URLSearchParams();

  const { filters } = newQuery;
  Object.entries(filters).forEach(([key, value]) => {
    if (!value) return;
    newSearchParams.set(key, String(value));
  });

  return newSearchParams;
};

const getInitialState = (queryParams: URLSearchParams): RecruitersPageTableQuery => ({
  filters: {
    surname: queryParams.get('surname') ?? undefined,
    positionCategoryId: queryParams.get('positionCategoryId') ?? undefined,
    level: queryParams.get('level') ?? undefined,
    active: queryParams.get('active') ?? undefined,
    status: queryParams.get('status') ?? undefined,
    note: queryParams.get('note') ?? undefined,
    name: queryParams.get('name') ?? undefined,
  },
});
