import { useCallback } from 'react';
import { Seniority } from 'types/recruitment';
import { useAxios } from './axios';
import { DistinctModuleNames } from './types';


export const useGetTemplateModules = () => {
  const [, getModuleNames] = useAxios<DistinctModuleNames>({ url: '/dictionary/modules', method: 'GET' }, { manual: true });
  return { getModuleNames };
};


export const useGetSeniorityLevels = () => {
  const [{ data = { scores: [] }, loading: isLoadingScores }, getSeniorityLevelsData] =
    useAxios<{ scores: Seniority[] }>(
      { url: '/dictionary/scores', method: 'GET' },
      { manual: true },
    );
  const getSeniorityLevels = useCallback(
    () => getSeniorityLevelsData(),
    [getSeniorityLevelsData],
  );

  return {
    seniorityLevelsData: data.scores,
    isLoadingScores,
    getSeniorityLevelsData,
    getSeniorityLevels,
  };
};