import { RecruitmentFeedback } from 'api/types';

export const defaultFeedbackCriteria = {
  personality: {
    title: 'Personality',
    description:
      'The candidate can answer questions clearly and convey their thoughts effectively',
    criteria: [
      'Ability to convey information effectively',
      'Can answer in a clear and understandable way',
    ],
  },
  culture: {
    title: 'High personal culture',
    description:
      'Refers to behaviors and interpersonal skills that reflect respect, professionalism, and ethics of the candidate.',
    criteria: [
      'Punctuality',
      'Treating the recruiter with respect',
      'Ensuring a pleasant atmosphere in the interview',
      'Appropriate clothing and conditions in which the candidate conducts the interview',
    ],
  },
  professionalism: {
    title: 'Professionalism',
    description:
      'The candidate is characterized by a high standard of professional ethics, a sens of responsibility.',
    criteria: [],
  },
  learn: {
    title: 'Willingness to grow and learn',
    description:
      'Openness to constructive criticism, not only accepting it, but also identifying areas of improvement.',
    criteria: [],
  },
  relationships: {
    title: 'Building relationships',
    description: `The candidate inspires the recruiter's/team trust through his authenticity and sincerity. Is transparent and has skills related to maintaining positive interpersonal relationships, actively listens`,
    criteria: [],
  },
  recommendation: {
    title: 'Recommendation',
    description: 'I would like to work with such a person',
    criteria: [],
  },
};
export const defaultFeedback: RecruitmentFeedback = {
  additionalHrFeedbackFromClient: null,
  buildingRelationships: null,
  buildingRelationshipsReason: null,
  feedbackForCandidate: null,
  feedbackForHr: null,
  highPersonalCulture: null,
  highPersonalCultureReason: null,
  personality: null,
  personalityReason: null,
  professionalism: null,
  professionalismReason: null,
  recommendation: null,
  recommendationReason: null,
  willingnessToGrowAndLearn: null,
  willingnessToGrowAndLearnReason: null,
  additionalHrFeedbackFromCandidate: null,
};
