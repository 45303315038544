import { useMemo, useState } from 'react';
import { RecruitmentService } from 'api';
import { RecruitmentForm } from '../components/RecruitmentForm/RecruitmentForm.types';
import { defaultRecruitmentForm } from '../components/RecruitmentForm/utils/defaultRecruitmentForm';
import { mapProvinceToFullName } from '../components/RecruitmentForm/utils/mapProvinceToFullName';

export const useGetRecruitmentFormData = () => {
  const { recruitmentData, getRecruitment, isLoadingRecruitment } =
    RecruitmentService.useGetRecruitment();

  const [recId, setRecId] = useState<number | undefined>(undefined);

  const recruitmentFormData: RecruitmentForm | undefined = useMemo(() => {
    if (recruitmentData && recId) {
      return {
        id: recruitmentData.id,
        candidateName: recruitmentData.candidateName,
        noteFromHrToRecruiter: recruitmentData.noteFromHrToRecruiter,
        hr: {
          id: recruitmentData.hr.id,
          name: `${recruitmentData.hr.name} ${recruitmentData.hr.surname}`,
        },
        recruitmentDate: recruitmentData.date,
        seniorityLevel: { name: recruitmentData.seniorityLevel },
        template: {
          id: recruitmentData.template.id,
          name: recruitmentData.template.name,
        },
        technicalRecruiter: {
          id: recruitmentData.technicalRecruiter.id,
          name: `${recruitmentData.technicalRecruiter.name} ${recruitmentData.technicalRecruiter.surname}`,
        },
        remuneratedTechRecruiter: recruitmentData.remuneratedTechnicalRecruiter
          ? {
              id: recruitmentData.remuneratedTechnicalRecruiter.id,
              name: `${recruitmentData.remuneratedTechnicalRecruiter.name} ${recruitmentData.remuneratedTechnicalRecruiter.surname}`,
            }
          : null,
        cvFile: recruitmentData.cvFile,
        location:  mapProvinceToFullName(recruitmentData.location),
        tags: recruitmentData.tags
      };
    }
    return defaultRecruitmentForm;
  }, [recruitmentData, recId]);

  return { recId, recruitmentFormData, isLoadingRecruitment, setRecId, getRecruitment };
};
