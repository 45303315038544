import {
  FilterType,
  RecruitmentFilter,
  RecruitmentsFilters,
} from '../RecruitmentsFilters/RecruitmentsFilters';
import { Actionbar } from 'pages/Recruitments/RecruitmentsPage.css';
import { RecruitmentsPageTableQuery } from 'pages/Recruitments/RecruitmentsPage.types';

interface RecruitmentsActionBarProps {
  query: RecruitmentsPageTableQuery;
  onUpdateFilters: (newFilters: Partial<RecruitmentsPageTableQuery['filters']>) => void;
}

export const RecruitmentsActionBar = ({
  query,
  onUpdateFilters,
}: RecruitmentsActionBarProps) => {
  const filtersOptions: RecruitmentFilter[] = [
    {
      accessor: 'freeText',
      name: 'Search',
      filterType: FilterType.default,
    },
    {
      accessor: 'positionName',
      name: 'Position category',
      filterType: FilterType.default,
    },
    {
      accessor: 'technicalRecruiterId',
      name: 'Technical recruiter',
      filterType: FilterType.default,
    },
    {
      accessor: 'hrId',
      name: 'HR contact',
      filterType: FilterType.optional,
    },
    {
      accessor: 'seniorityLevelFrom',
      name: 'Seniority from',
      filterType: FilterType.optional,
    },
    {
      accessor: 'seniorityLevelTo',
      name: 'Seniority to',
      filterType: FilterType.optional,
    },
    { accessor: 'dateFrom', name: 'From date', filterType: FilterType.optional },
    { accessor: 'dateTo', name: 'To date', filterType: FilterType.optional },
    { accessor: 'statuses', name: 'Statuses', filterType: FilterType.optional },
    { accessor: 'location', name: 'Location', filterType: FilterType.optional },
    { accessor: 'favourite', name: 'Favourite', filterType: FilterType.optional },
    {
      accessor: 'recommended',
      name: 'Recommended Candidate',
      filterType: FilterType.optional,
    },
    {
      accessor: 'tagIds',
      name: 'Tags',
      filterType: FilterType.optional,
    },
  ];
  return (
    <Actionbar>
      <div>
        <RecruitmentsFilters
          query={query}
          onUpdateFilters={onUpdateFilters}
          filtersOptions={filtersOptions}
        />
      </div>
    </Actionbar>
  );
};
