import { Typography } from '@mui/material';
import { AccordionHeader } from 'components/Accordion/Accordion.css';
import { RecruitmentResultProps } from './RecruitmentResult.types';
import * as S from './RecruitmentResult.css';
import { mapNotScoredLevelValue } from '../../utils/mapNotScoredLevel';

export const RecruitmentResult = ({ matchResult }: RecruitmentResultProps) => (
  <S.ResultContainer id="recruitment-result">
    <AccordionHeader $secondary>Summary</AccordionHeader>
    <S.ResultContent>
      <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '24px' }}>
        Results:
      </Typography>
      <S.ResultDataContainer>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Overall candidate calculated level:
          <Typography component="span"> {matchResult.comparisonDescription}</Typography>
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Diff:{' '}
          <Typography component="span">
            {matchResult.differenceInPercents > 0 ? ' +' : ''}
            {matchResult.differenceInPercents}%
          </Typography>
        </Typography>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Level match:
          <Typography component="span"> {mapNotScoredLevelValue(matchResult.matchedLevel)}</Typography>
        </Typography>
      </S.ResultDataContainer>
    </S.ResultContent>
  </S.ResultContainer>
);
