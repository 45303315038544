import { SvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const createStyledIcon = <T extends typeof SvgIcon>(IconComponent: T) =>
  styled(IconComponent)<SvgIconProps>(() => ({
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }));


export const EmailAddress = styled.a`
  color: ${theme.palette.primary.main};
  &:hover {
    color: ${theme.palette.primary.dark}
  }
;
`