import { SvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import DoNotDisturbAltTwoToneIcon from '@mui/icons-material/DoNotDisturbAltTwoTone';

export const createStyledIcon = <T extends typeof SvgIcon>(
  IconComponent: T,
  color: any,
) =>
  styled(IconComponent)<SvgIconProps>(() => ({
    color,
  }));

// Export styled icons
export const ActiveIcon = createStyledIcon(CheckCircleOutlineTwoToneIcon, 'green');
export const InActiveIcon = createStyledIcon(DoNotDisturbAltTwoToneIcon, 'orange');
