import { FilterList } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { RecruitmentFilter, RecruitmentFilterInUse } from '../RecruitmentsFilters';

interface FiltersMenuProps {
  options: RecruitmentFilter[];
  onOptionSelect: (option: RecruitmentFilterInUse) => void;
}

export const FiltersMenu = ({ options, onOptionSelect }: FiltersMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (selectedOption: RecruitmentFilterInUse) => {
    handleClose();
    onOptionSelect(selectedOption);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <FilterList />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((opt) => (
          <MenuItem
            key={opt.accessor}
            onClick={() => onSelect({ accessor: opt.accessor, value: '' })}
          >
            {opt.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
