import { Box, Chip } from '@mui/material';
import React from 'react';
import { ObjectType } from './types';

interface ChipsProps<T extends ObjectType> {
  objects?: T[];
  chipNameExtractor?: (value: T) => string;
  size?: 'small' | 'medium';
  color?: 'secondary' | 'default' | 'primary' | 'error' | 'info' | 'success' | 'warning';
}

const Chips = <T extends ObjectType>({
  objects,
  chipNameExtractor,
  size = 'medium',
  color = 'secondary',
}: ChipsProps<T>) => {
  function generateChipsFromObjects() {
    return objects?.map((value, index) => (
      <Chip
        key={index}
        label={typeof value === 'string' ? value : (chipNameExtractor?.(value) ?? 'Unknown')}
        color={color}
        size={size}
      />
    ));
  }

  return !objects || objects.length === 0 ? (
    <Box display="flex" flexWrap="wrap" gap={1}>
      None
    </Box>
  ) : (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {generateChipsFromObjects()}
    </Box>
  );
};

export default Chips;