import styled from 'styled-components';

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
  margin: 0 32px;  
`;

export const ResultContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  padding: 32px;
  @media screen and (min-width: 460px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ResultDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
