import { useState } from 'react';
import { RecruitmentsPageTableQuery } from '../RecruitmentsPage.types';
import { useSearchParams } from 'react-router-dom';

const DEFAULT_PAGE_SIZE = 100;

export function useRecruitmentsTableQuery() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableQuery, setTableQuery] = useState<RecruitmentsPageTableQuery>(
    getInitialState(searchParams),
  );

  const handleUpdateTableQuery = (newQuery: Partial<RecruitmentsPageTableQuery>) => {
    const newQueryObject: RecruitmentsPageTableQuery = {
      ...tableQuery,
      ...newQuery,
    };

    setTableQuery(newQueryObject);
    setSearchParams(injectedQueryParams(newQueryObject));
  };

  const handleUpdateTableFilters = (
    newFiltersQuery: Partial<RecruitmentsPageTableQuery['filters']>,
  ) => {
    const newQueryObject: RecruitmentsPageTableQuery = {
      ...tableQuery,
      filters: {
        ...tableQuery.filters,
        ...newFiltersQuery,
      },
    };

    setTableQuery(newQueryObject);
    setSearchParams(injectedQueryParams(newQueryObject));
  };

  return {
    tableQuery,
    onUpdateTableQuery: handleUpdateTableQuery,
    onUpdateTableFilters: handleUpdateTableFilters,
  };
}

const injectedQueryParams = (newQuery: RecruitmentsPageTableQuery) => {
  const newSearchParams = new URLSearchParams();

  const { filters, ...rest } = newQuery;

  Object.entries(rest).forEach(([key, value]) => {
    if (!value) return;
    newSearchParams.set(key, String(value));
  });
  Object.entries(filters).forEach(([key, value]) => {
    if (!value) return;
    newSearchParams.set(key, String(value));
  });

  return newSearchParams;
};

const getInitialState = (queryParams: URLSearchParams): RecruitmentsPageTableQuery => ({
  page: (Number(queryParams.get('page')) || 1) - 1,
  perPage: Number(queryParams.get('perPage')) || DEFAULT_PAGE_SIZE,
  sortField: queryParams.get('sortField') ?? undefined,
  sortDirection: queryParams.get('sortDirection') ?? undefined,
  filters: {
    freeText: queryParams.get('freeText') ?? undefined,
    positionName: queryParams.get('positionName') ?? undefined,
    technicalRecruiterId: queryParams.get('technicalRecruiterId') ?? undefined,
    hrId: queryParams.get('hrId') ?? undefined,
    templateName: queryParams.get('templateName') ?? undefined,
    seniorityLevelFrom: queryParams.get('seniorityLevelFrom') ?? undefined,
    seniorityLevelTo: queryParams.get('seniorityLevelTo') ?? undefined,
    dateFrom: queryParams.get('dateFrom') ?? undefined,
    dateTo: queryParams.get('dateTo') ?? undefined,
    statuses: queryParams.get('statuses') ?? undefined,
    location: queryParams.get('location') ?? undefined,
    favourite: queryParams.get('favourite') ?? undefined,
    recommended: queryParams.get('recommended') ?? undefined,
    scoreFrom: queryParams.get('scoreFrom') ?? undefined,
    scoreTo: queryParams.get('scoreTo') ?? undefined,
    tagIds: queryParams.get('tagIds') ?? undefined,
  },
});
