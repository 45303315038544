import { RecruitmentService } from 'api';
import { handleShowCv } from '../../../../utils/helpers/exportFile';
import { DownloadButton } from './DownloadCvButton.css';

export interface DownloadCvButtonProps {
  recruitmentId: number
}

export const DownloadCvButton = ({recruitmentId}: DownloadCvButtonProps) => {

  const { getCvUrl } = RecruitmentService.useGetRecruitmentCvDocument(recruitmentId);

  return (
    <DownloadButton
      onClick={(e: any) => {
        e.stopPropagation();
        getCvUrl().then((response) => handleShowCv(response));
      }}
    />
  )
};
