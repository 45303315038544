import { Autocomplete, Box, TextField } from '@mui/material';
import * as S from '../TemplateModuleForm.css';
import { useUserContext } from 'context';
import { ReactNode } from 'react';

interface RequirementsItemProps {
  children: ReactNode;
  selectedLevel: string;
  onLevelChange: (option: string, index: number) => void;
  levelsOptions: { name: string }[];
  requirementsValue: string;
  onRequirementsChange: (value: string, index: number) => void;
  index: number;
}

export const RequirementsItem = ({
  children,
  onLevelChange,
  selectedLevel,
  levelsOptions,
  onRequirementsChange,
  requirementsValue,
  index,
}: RequirementsItemProps) => {
  const { user } = useUserContext();

  return (
    <S.RequirementsItem tabIndex={0}>
      <Autocomplete
        size="small"
        disableClearable
        disabled={!user?.hasFullAccess}
        onChange={(_, option) => {
          if (option) {
            onLevelChange(option.name, index);
          }
        }}
        getOptionLabel={(option) => option.name}
        options={levelsOptions}
        value={{ name: selectedLevel }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '& .MuiInputBase-root': { border: 'none' },
            }}
          />
        )}
        sx={{
          padding: 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          gap: '8px',
        }}
      >
        <TextField
          multiline
          disabled={!user?.hasFullAccess}
          sx={{
            flex: 1,
            flexWrap: 'wrap',
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
            '& .MuiInputBase-root': { border: 'none' },
          }}
          size="small"
          value={requirementsValue}
          onChange={(e) => {
            onRequirementsChange(e.target.value, index);
          }}
        />
        {children}
      </Box>
    </S.RequirementsItem>
  );
};
