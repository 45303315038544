import { InputLabel, TextField as MuiTextField } from '@mui/material';
import styled from 'styled-components';

export const CustomFeedbackContainer = styled.div``;

export const CustomFeedbackLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CustomFeedbackLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    color: black;
    font-size: 24px;
  }
`;

export const TextField = styled(MuiTextField)`
  width: 100%;

  @media screen and (min-width: 800px) {
    width: 50%;
  }
`;
