import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TemplateService } from 'api';
import { ADD_TEMPLATE } from 'router/url';
import { handleException } from 'utils/errorHandlingUtils';
import { TemplatesTable } from './components/TemplateTable';
import * as S from './TemplatesPage.css';

export const TemplatesPage = () => {
  const navigate = useNavigate();

  const { getTemplates, templatesData, isLoadingTemplates } =
    TemplateService.useGetTemplates();

  const fetchTemplates = useCallback(async () => {
    try {
      await getTemplates();
    } catch (e) {
      handleException(e);
    }
  }, [getTemplates]);

  useEffect(() => {
    fetchTemplates();
    window.scrollTo(0, 0);
  }, [fetchTemplates]);

  return (
    <S.TemplatePageContainer>
      <TemplatesTable
        isLoadingTemplatesData={isLoadingTemplates}
        templates={templatesData?.templates || []}
        onRefetch={fetchTemplates}
        onAddNewTemplateClick={() => navigate(ADD_TEMPLATE)}
      />
    </S.TemplatePageContainer>
  );
};
