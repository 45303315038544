import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { CircularProgress as MuiCircularProgress } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

export const Info = styled.div`
  display: inline-flex;
`;

export const Bolded = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export const InfoContent = styled.div`
  word-break: break-word;
  display: flex;
`;

export const ClickableInfoContent = styled.div`
  word-break: break-word;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

export const Container = styled.div<{ height?: string }>`
  background-color: white;
  padding: 32px;
  margin: 32px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-shadow:
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 4px rgba(0, 0, 0, 0.08);
  border: 0.5px #ededed solid;
  height: ${(props) => props.height || 'auto'};
`;

export const DownloadIcon = styled(SaveAltOutlinedIcon)`
  &.MuiSvgIcon-root {
    background-color: transparent;
    font-size: 18px;
    margin-left: 14px;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 2px;
    margin-left: 12px;
    color: ${theme.palette.secondary.dark};
  }
`;
