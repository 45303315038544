import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import MuiLockIcon from '@mui/icons-material/Lock';
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export {
  PopoverOptions,
  PopoverOption,
  OptionsIcon,
} from 'components/Popover/Popover.css';

export { EmptyListImage } from 'styles.global';

export const Table = styled(MuiTable)`
  &.MuiTable-root {
    word-break: break-word;
    min-width: 1200px;
  }
`;

export const BoldTableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    border: none;
    font-weight: bold;
    width: 200px;
  }
`;

export const TableCell = styled(MuiTableCell)<{ $cellWidth?: string }>`
  &.MuiTableCell-root {
    max-width: 200px;
    /* max-width: ${({ $cellWidth }) => $cellWidth || '300px'}; */
  }
`;

export const TableHead = styled(MuiTableHead)`
  border-bottom: 1.5px black solid;
`;

export const ViewButton = styled(Button).attrs({ $secondary: true })`
  &.MuiButton-root {
    margin-right: 10px;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const LockIcon = styled(MuiLockIcon)`
  &.MuiSvgIcon-root {
    margin-right: 10px;
    font-size: 20px;
  }
`;

export const NotFoundContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const TableRow = styled(MuiTableRow)`
  &:hover {
    background-color: ${theme.palette.secondary.main};
  }
`;

export const ArrowUp = styled(ArrowUpward)`
  && {
    margin-top: 5px;
    :hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const ArrowDown = styled(ArrowDownward)`
  && {
    margin-top: 5px;
    :hover {
      cursor: pointer;
      color: ${theme.palette.secondary.dark}80;
    }
  }
`;

export const DataTable = styled(DataGrid)`
  * {
    outline: none !important;
  }
  /* comments only for future changes */
  & .MuiDataGrid-filler {
    /* display: none !important; */
    /* min-width: 0px !important; */
  }

  & .MuiDataGrid-cellEmpty {
    /* display: none !important; */
    /* min-width: 0px !important; */
  }

  & .MuiDataGrid-cell {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    text-overflow: ellipsis;
    overflow: hidden;
    /* width: 12% !important; */
    /* flex: 1 !important; */
  }

  & .MuiDataGrid-columnHeader {
    /* width: 20% !important; */
    /* flex: 1 !important; */
  }

  & .MuiDataGrid-columnHeaders {
    /* border-bottom: solid black 1px !important; */
    border-top: none !important;
    box-sizing: border-box;
  }

  & .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
  }
`;
