import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'theme';

export const NavbarContainer = styled.div`
  background-color: black;
  height: 3rem;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 999;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Navbar = styled.div`
  width: min(100%, 1580px);
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
`;

export const PauseButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  display: flex;
  height: 100%;
  &:hover {
    color: rgb(240, 240, 240);
    cursor: pointer;
  }
`;

export const ExitIcon = styled.img`
  margin-left: 10px;
  font-size: 30px;
`;

export const LogoutButton = styled.button`
    text-decoration: None;
    background-color: transparent;
    border: none;
    padding-left: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.4rem;
    text-transform: uppercase;

    &:hover {
        color: ${theme.palette.primary.main};
        cursor: pointer;
    }
`;

export const StyledLink = styled(Link)<{ $isActive: boolean }>`
  text-decoration: None;
  padding: 1rem;  
  color: ${(props) => (props.$isActive ? theme.palette.primary.main : 'white')};
  font-size: 1.4rem;
  text-transform: uppercase;
  &:hover {
    color: ${theme.palette.primary.main};
  }
  &[data-device='pc'] {
    @media (max-width: 645px) {
      display: none;
    }
  }
  &[data-device='mobile'] {
    @media (min-width: 645px) {
      display: none;
    }
  }
`;

export const NavbarTitle = styled.p`
  font-size: 20px;
  font-weight: lighter;
  line-height: 24px;
`;

export const Logo = styled.img<{ $clickable?: boolean }>`
  height: 1.4rem;
  width: auto;
  margin-right: 30px;
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`;
