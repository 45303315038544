import styled from 'styled-components';
import { Button } from 'styles.global';
import theme from 'theme';

export const DeleteButton = styled(Button)`
  &.MuiButton-root {
    color: ${theme.palette.error.main};
  }
`;

export const StyledPreview = styled.pre`
  text-align: left;
  white-space: pre-wrap;
`;
