import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components';

export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorIconStyle = styled(ErrorIcon)`
  margin: 5px;
`;

export const InfoIconStyle = styled(InfoIcon)`
  margin: 5px;
`;

export const ToastStyle = styled.div`
  margin: 5px;
`;
