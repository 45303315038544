import ContactPageIcon from '@mui/icons-material/ContactPage';
import { SvgIcon, SvgIconProps } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../theme';

const createStyledIcon = <T extends typeof SvgIcon>(IconComponent: T) =>
  styled(IconComponent)<SvgIconProps>(() => ({
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }));

// Export styled icons
export const DownloadButton = createStyledIcon(ContactPageIcon);
