import { Autocomplete, CircularProgress, FilterOptionsState, TextField } from '@mui/material';
import React, { useState } from 'react';
import { SelectedOption, SelectOption } from '../SelectAutocomplete/SelectAutocomplete.types';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete/useAutocomplete';
import { SearchTagsResponse, Tag, useCreateTag, useGetTags } from '../../api/tags';
import debounce from 'lodash.debounce';

interface MultiSelectAutoCompleteWithDynamicDataProps {
  defaultValue: SelectedOption[];
  onChange: (e: React.SyntheticEvent, options: SelectedOption[]) => void;
  enableAddingNewTags?: boolean;
  isDisabled?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
}

const debounceFn = debounce((fn: Function) => fn(), 800);

const mapResponseToSelectableOptions = (tags: SearchTagsResponse): SelectOption[] =>
  tags?.map(
    (value: Tag): SelectOption => ({
      id: value.id,
      name: value.name,
    }),
  ) || [];

const MultiSelectTagsAutoCompleteWithDynamicData = ({
  defaultValue,
  onChange,
  enableAddingNewTags = false,
  isDisabled,
  id,
  placeholder,
  label,
}: MultiSelectAutoCompleteWithDynamicDataProps) => {
  const { searchTags } = useGetTags();
  const { createTag } = useCreateTag();

  const [valuesSelected, setValuesSelected] = useState<SelectedOption[]>(defaultValue || []);
  const [availableOptions, setAvailableOptions] = useState<SelectedOption[]>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);

  const debouncedFetchTags = (searchValue: string) => {
      setLoading(true);
      debounceFn(async () => {
        try {
          const axiosResponse = await searchTags(searchValue);
          setAvailableOptions(mapResponseToSelectableOptions(axiosResponse.data));
        } finally {
          setLoading(false);
        }
      });
    };

  const openTagsMultiSelect = () => {
    setTagsOpen(true);
    debouncedFetchTags('');
  };

  const handleCloseTagsMultiselect = () => {
    setTagsOpen(false);
    setAvailableOptions([]);
  };

  const handleInputChangeInTagsMultiselect = (
    _: any,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) => {
    if ((reason === 'input' || reason === 'clear')) {
      debouncedFetchTags(value);
    }
  };

  const onChangeWithAddingNewElementRequestWhenDoesNotExist = async (
    event: React.SyntheticEvent,
    newValue: SelectedOption[],
  ) => {
    const valueToCreate = newValue.filter(
      (element) =>
        element !== undefined && element?.name !== undefined && element?.id === undefined,
    );

    if (newValue && valueToCreate.length > 0) {
      const firstNullIdTagName = valueToCreate.pop()!.name;
      newValue.filter((currentValue, arrayIndex, array) => {
        if (currentValue?.name === firstNullIdTagName) {
          array.splice(arrayIndex, 1);
          return true;
        }
        return false;
      });

      const nameWithoutDecorations = firstNullIdTagName
        .replace(/"$/, '')
        .replace(/^Add New Tag "/, '');
      setLoading(true);
      const newlyCreatedTag = await createTag(nameWithoutDecorations);
      setLoading(false);
      newValue.push({ id: newlyCreatedTag.data.id, name: newlyCreatedTag.data.name });
    }
  };

  const filterOptionsWithPushNewElementWhenDoesNotExist = (
    selectedOptions: SelectedOption[],
    state: FilterOptionsState<SelectedOption>,
  ) => {
    const { inputValue } = state;
    const doesNotExist = !selectedOptions.some((option) => inputValue === option?.name);
    if (inputValue !== '' && doesNotExist) {
      selectedOptions.push({
        name: `Add New Tag "${inputValue}"`,
        id: undefined,
      });
    }

    return selectedOptions;
  };

  const handleValueChange = (event: React.SyntheticEvent, newValue: SelectedOption[]) => {
    if (enableAddingNewTags) {
      onChangeWithAddingNewElementRequestWhenDoesNotExist(event, newValue);
    } 
    setValuesSelected(newValue);
    onChange(event, newValue);
  }

  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      value={valuesSelected}
      id={id}
      disabled={isDisabled}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      options={availableOptions}
      getOptionLabel={(option) => option?.name ?? 'Undefined'}
      open={tagsOpen}
      onOpen={openTagsMultiSelect}
      onClose={handleCloseTagsMultiselect}
      loading={loading}
      onChange={handleValueChange}
      onInputChange={handleInputChangeInTagsMultiselect}
      filterOptions={
        enableAddingNewTags ? filterOptionsWithPushNewElementWhenDoesNotExist : (x) => x
      }
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
    />
  );
};

export default MultiSelectTagsAutoCompleteWithDynamicData;
