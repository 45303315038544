import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RecruitmentService } from 'api';
import { MatchResult } from 'api/types';
import { RecruitmentInfoDto } from 'pages/Recruitment/components/RecruitmentInfo/RecruitmentInfo.types';
import { RouteParams } from 'utils/types/RouteParams';
import { RecruitmentState } from '../../../../../../types/recruitment';
import { RecruitmentInProgressDto } from '../useRecruitmentInProgressForm/recruitmentInProgress.types';

export const useGetRecruitmentInProgressData = () => {
  const { getRecruitment } = RecruitmentService.useGetRecruitment();
  const { id } = useParams<RouteParams>();
  const recId: number = Number(id);

  const [recruitmentInfo, setRecruitmentInfo] = useState<RecruitmentInfoDto | undefined>(
    undefined,
  );

  const [recruitmentFormData, setRecruitmentFormData] = useState<
    RecruitmentInProgressDto | undefined
  >(undefined);

  const [recruitmentResult, setRecruitmentResult] = useState<MatchResult | undefined>(
    undefined,
  );

  const formatRecruitmentState = (state: RecruitmentState): string => {
    const parsed = state.replace('_', ' ').toLowerCase();
    return parsed.charAt(0).toUpperCase() + parsed.slice(1).toLowerCase();
  };

  useEffect(() => {
    const fetchRecruitmentData = async () => {
      const response = await getRecruitment(recId);
      setRecruitmentInfo({
        state: formatRecruitmentState(response.data.state),
        candidateName: response.data.candidateName,
        recruitmentId: response.data.id,
        position: response.data.positionCategory,
        seniorityLevel: response.data.seniorityLevel,
        technicalRecruiterName: `${response.data.technicalRecruiter.name} ${response.data.technicalRecruiter.surname}`,
        remuneratedRecruiterName: response.data.remuneratedTechnicalRecruiter
          ? `${response.data.remuneratedTechnicalRecruiter.name} ${response.data.remuneratedTechnicalRecruiter.surname}`
          : null,
        hrContactName: `${response.data.hr.name} ${response.data.hr.surname}`,
        cvFileName: response.data.cvFile,
        noteFromHrToRecruiter: response.data.noteFromHrToRecruiter,
        location: response.data.location,
        tags: response.data.tags
      });
      if (response.data.matchResult) {
        setRecruitmentResult({
          matchedLevel: response.data.matchResult.matchedLevel,
          differenceInPercents: response.data.matchResult.differenceInPercents,
          comparisonDescription: response.data.matchResult.comparisonDescription,
        });
      }
      setRecruitmentFormData({
        candidateName: response.data.candidateName,
        hrId: response.data.hr.id,
        recruitmentDate: response.data.date,
        seniorityLevel: response.data.seniorityLevel,
        technicalRecruiterId: response.data.technicalRecruiter.id,
        remuneratedTechRecruiterId:
          response.data.remuneratedTechnicalRecruiter?.id ?? null,
        recruitmentState: response.data.state,
        templateId: response.data.template.id,
        modules: response.data.modules.sort((a, b) => a.name.localeCompare(b.name)),
        feedbackUpdate: response.data.feedback,
        tags: response.data.tags.map(value => value.id)
      });
    };

    fetchRecruitmentData();
  }, [getRecruitment, recId]);

  return {
    recId,
    recruitmentFormData,
    recruitmentInfo,
    recruitmentResult,
    getRecruitment,
    setRecruitmentResult,
  };
};
