import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { pl } from 'date-fns/locale';
import { Recruitment } from 'pages/Recruitment/components/Recruitment';
import Recruitments from 'pages/Recruitments';
import Reports from 'pages/Reports';
import { TemplatesPage as Templates } from 'pages/Templates';
import { AddTemplate } from 'pages/Templates/AddTemplate';
import { EditTemplate } from 'pages/Templates/EditTemplate';
import Unauthorized from 'pages/Unauthorized';
import { GlobalStyle } from 'styles.global';
import AppContainer from 'components/AppContainer';
import Navbar from 'components/Navbar';
import ToastProvider from 'components/ToastProvider';
import { useUserIsAuthorized } from 'utils/hooks';
import { AppRouterProps } from './router.types';
import * as URL from './url';
import Recruiters from 'pages/Recruiters/RecruitersPage';

export const AppRouter = ({ user }: AppRouterProps) => {
  const userIsAuthorized = useUserIsAuthorized();
  const isAuthenticated = useIsAuthenticated();
  const getRoutes = () => (
    <Routes>
      <Route
        index
        element={
          <Navigate
            to={{
              pathname: URL.RECRUITMENTS,
              search: `${new URLSearchParams({
                ...(user?.technicalRecruiterId && {
                  technicalRecruiterId: user.technicalRecruiterId,
                }),
              })}`,
            }}
          />
        }
      />
      <Route path={URL.RECRUITERS} element={<Recruiters />} />
      <Route path={URL.RECRUITMENTS} element={<Recruitments />} />
      <Route path={URL.REPORTS} element={<Reports />} />
      <Route path={URL.RECRUITMENT_ID} element={<Recruitment />} />
      <Route path={URL.TEMPLATES} element={<Templates />} />
      <Route path={URL.ADD_TEMPLATE} element={<AddTemplate />} />
      <Route path={URL.EDIT_TEMPLATE_ID} element={<EditTemplate />} />
      <Route path="*" element={<Navigate to={URL.RECRUITMENTS} replace />} />
    </Routes>
  );

  return (
    <Router>
      <GlobalStyle />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
        <Navbar />
        <ToastProvider />
        {userIsAuthorized && !!user && <AppContainer routes={getRoutes} />}
        {!userIsAuthorized && isAuthenticated && (
          <Routes>
            <Route path={URL.UNAUTHORIZED} element={<Unauthorized />} />
            <Navigate to={URL.UNAUTHORIZED} />
          </Routes>
        )}
      </LocalizationProvider>
    </Router>
  );
};
