import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModuleService, PositionServices, TemplateService } from 'api';
import { TEMPLATES } from 'router/url';
import { NewTemplate } from 'types/template';
import Container from 'components/Container';
import { handleException } from 'utils/errorHandlingUtils';
import { removeExtraSpaces } from 'utils/helpers';
import { TemplateForm } from '../components/TemplateForm';
import { useDictionaryContext } from 'context';
import { usePositionCategories } from 'context/DictionaryContext';

export const AddTemplate = () => {
  const navigate = useNavigate();

  const { createTemplate } = TemplateService.useCreateTemplate();
  const { isLoadingDefaultModules, getDefaultModulesData, defaultModules } =
    ModuleService.useGetDefaultModules();
  const { reFetchTemplates } = useDictionaryContext();
  const [isLoadingState, setIsLoadingState] = useState<boolean>(false);

  const handleSubmit = async (template: NewTemplate) => {
    if (template.positionCategory) {
      try {
        setIsLoadingState(true);
        await createTemplate(
          removeExtraSpaces(template.name),
          template.positionCategory?.id,
          template.modules.map((mod) => ({
            ...mod,
            name: removeExtraSpaces(mod.name),
          })),
        );
        await reFetchTemplates();
        navigate(TEMPLATES);
      } catch (e) {
        handleException(e);
      }
    }
  };

  const defaultTemplate: NewTemplate = {
    name: '',
    positionCategory: null,
    modules: [...defaultModules],
  };

  const isLoading = isLoadingDefaultModules || isLoadingState;

  useEffect(() => {
    getDefaultModulesData();
  }, [getDefaultModulesData]);

  return (
    <Container isLoading={isLoading}>
      <TemplateForm
        templateData={defaultTemplate}
        positionsOptions={usePositionCategories()}
        formTitle="Add template"
        onTemplateSubmit={handleSubmit}
      />
    </Container>
  );
};
