import { RecruitmentFeedback } from 'api/types';
import { FeedbackField } from '../../RecruitmentFeedback.types';

export interface OverallFeedbackProps {
  title: string;
  description: string;
  criteria: string[];
  notApprovedReasons: FeedbackField;
  approved: FeedbackField;
  onEditFeedback: (value: string | boolean, field: keyof RecruitmentFeedback) => void;
  readOnly: boolean;
}

export enum OverallFeedbackSelect {
  Approved = 'yes',
  NotApproved = 'no',
}
