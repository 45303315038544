import { toast } from 'react-toastify';
import { SortOrder } from 'types/common';
import { handleException } from 'utils/errorHandlingUtils';

export const reverseDate = (date: string) => date.split('-').reverse().join('-');

export const isNumber = (value: string) => /^\d+$/.test(value);

export const removeExtraSpaces = (s: string) => s.replace(/\s+/g, ' ').trim();

export const stringIsEmpty = (s: string) => /^\s*$/.test(s);

export const copyToClipboard = async (text: string) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      toast.info('Copied to clipboard', { autoClose: 1500 });
    }
  } catch (e) {
    handleException(e);
  }
};

export const getSortOrder = (order: string | undefined, initialValue: SortOrder) => {
  if (!order) return initialValue;
  return order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
};
