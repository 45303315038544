import { RecruitmentService } from 'api';
import { StyledHeader } from 'components/StyledHeader/StyledHeader.css';
import { handleException } from 'utils/errorHandlingUtils';
import { RecruitmentInfoDto } from './RecruitmentInfo.types';
import * as S from './RecruitmentInfo.css';
import { exportFile, handleShowCv } from '../../../../utils/helpers/exportFile';
import { mapProvinceToFullName } from 'pages/Recruitments/components/RecruitmentForm/utils/mapProvinceToFullName';
import React from 'react';
import { SelectedOption } from '../../../../components/SelectAutocomplete/SelectAutocomplete.types';
import MultiSelectTagsAutoCompleteWithDynamicData from '../../../../components/MultiSelectAutoComplete/MultiSelectTagsAutoCompleteWithDynamicData';
import { RecruitmentInProgressDto } from '../Recruitment/hooks/useRecruitmentInProgressForm/recruitmentInProgress.types';

interface RecruitmentInfoProps {
  recruitment: RecruitmentInfoDto;
  recruitmentForm: RecruitmentInProgressDto;
  updateTagsInFormCallback: (tags : number[]) => void
}

export const RecruitmentInfo = ({ recruitment, recruitmentForm, updateTagsInFormCallback}: RecruitmentInfoProps) => {
  const { getCvUrl, isLoadingCv } = RecruitmentService.useGetRecruitmentCvDocument(
    recruitment.recruitmentId,
  );

  const handleDownloadCv = async () => {
    try {
      await getCvUrl().then((response) => handleShowCv(response));
    } catch (e) {
      handleException(e);
    }
  };

  const { getDocxDocument, isLoadingDocxDownload } = RecruitmentService.useGetDocxDocument();

  const handleDownloadSummary = async () => {
    try {
      const response = await getDocxDocument(recruitment.recruitmentId);
      exportFile(response);
    } catch (e) {
      handleException(e);
    }
  };

  const currentTagsFromRecruitment = recruitment.tags?.map(value => ({ id: value.id, name : value.name} as SelectedOption)) ?? [];
  return (
    <S.Container>
      <StyledHeader marginLeft="0" marginTop="0px" marginBottom="0px">
        {recruitment.state} recruitment information:{' '}
      </StyledHeader>
      <S.InfoContainer>
        <S.Info>
          <S.Bolded>Recruitment ID: </S.Bolded>
          <S.InfoContent>{recruitment.recruitmentId}</S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Candidate: </S.Bolded>
          <S.InfoContent>{recruitment.candidateName}</S.InfoContent>
        </S.Info>
        {!!recruitment.cvFileName && (
          <S.Info onClick={handleDownloadCv}>
            <S.Bolded>CV: </S.Bolded>
            <S.ClickableInfoContent>
              <div>{recruitment.cvFileName}</div>
              {isLoadingCv ? <S.CircularProgress size="16px" /> : <S.DownloadIcon />}
            </S.ClickableInfoContent>
          </S.Info>
        )}
        {recruitment.state === 'Completed' && (
          <S.Info onClick={handleDownloadSummary}>
            <S.Bolded>Download Summary Info</S.Bolded>
            <S.ClickableInfoContent>
              {isLoadingDocxDownload ? <S.CircularProgress size="16px" /> : <S.DownloadIcon />}
            </S.ClickableInfoContent>
          </S.Info>
        )}
        <S.Info>
          <S.Bolded>Position: </S.Bolded>
          <S.InfoContent>{recruitment.position}</S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Level: </S.Bolded>
          <S.InfoContent>{recruitment.seniorityLevel}</S.InfoContent>
        </S.Info>
        <S.Info>
          <S.Bolded>Recruiter: </S.Bolded>
          <S.InfoContent>{recruitment.technicalRecruiterName}</S.InfoContent>
        </S.Info>
        {recruitment.remuneratedRecruiterName && (
          <S.Info className="remuneratedRecruiterInfo">
            <S.Bolded>Remunerated recruiter: </S.Bolded>
            <S.InfoContent>{recruitment.remuneratedRecruiterName}</S.InfoContent>
          </S.Info>
        )}
        <S.Info>
          <S.Bolded>HR Contact: </S.Bolded>
          <S.InfoContent>{recruitment.hrContactName}</S.InfoContent>
        </S.Info>
        {recruitment.location && (
          <S.Info>
            <S.Bolded>Location: </S.Bolded>
            <S.InfoContent>{mapProvinceToFullName(recruitment.location)}</S.InfoContent>
          </S.Info>
        )}
          <S.Info>
            <S.Bolded>Tags: </S.Bolded>
            <S.InfoContent>
              <MultiSelectTagsAutoCompleteWithDynamicData
                enableAddingNewTags
                defaultValue={currentTagsFromRecruitment}
                onChange={(_, value: SelectedOption[]) => {
                  const tagsAfterUpdate = value.map(val => val?.id as number);
                  updateTagsInFormCallback(tagsAfterUpdate)
                }}
                id="select-tags"
              />
            </S.InfoContent>
          </S.Info>
      </S.InfoContainer>
      {recruitment.noteFromHrToRecruiter && (
        <S.Info>
          <S.Bolded>Note: </S.Bolded>
          <S.InfoContent>{recruitment.noteFromHrToRecruiter}</S.InfoContent>
        </S.Info>
      )}
    </S.Container>
  );
};
