import { DatePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';
import theme from 'theme';

export const KeyboardDatePicker = styled(DatePicker)`
  &.MuiPickersToolbar-toolbar {
    height: 100px;
    display: flex;
    align-items: center;
    background-color: ${theme.palette.secondary.dark};
  }
`;
