import MuiCloseIcon from '@mui/icons-material/Close';
import { DialogActions, FormControl, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const StyledDialogTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 600;
    align-self: center;
    margin-top: 40px;
    font-size: 25px;
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: ${theme.palette.primary.main};
  }
`;

export const StyledFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-top: 5px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;  
  }
`;

export const TwoColumnTextInput = styled(TextField)`
    grid-column: span 2;
`

export const CVContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  .MuiButton-root {
    width: 220px;
    font-size: medium;
    height: 40px;
    margin-top: 8px;  
  }
`;

export const CVTextContainer = styled.div`
  margin-left: 16px;
  align-items: center;
`;

export const CVName = styled.div`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const StyledDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
  }
  .MuiButton-root {
    width: 170px;
    font-size: large;
    height: 50px;
  }
`;

export const CloseIcon = styled(MuiCloseIcon)`
  &.MuiSvgIcon-root {
    color: #000000;
    &:hover {
      color: #00000040;
      cursor: pointer;
    }
  }
`;
